import { range } from './utils'

export function getCurrentDate() {
  const now = new Date()
  return new Date(now.getFullYear(), now.getMonth(), now.getDate())
}

function getDaysInMonth(year: number, month: number) {
  return new Date(year, month + 1, 0).getDate()
}

/**
 * Возвращет страницу календаря
 * @param year - Год
 * @param month - месяц начиная с 0
 * @returns {Date[]} - Страница календаря в виде массива дат
 * */
export function getCalendarPage(year: number, month: number): Date[] {
  /**
   * дни недели: 1 - пн, 7 - вс
   * (по умолчанию getDay возвращает 0 для воскресенья)
   * */
  const firstDayOfMonth = new Date(year, month).getDay() || 7

  /**
   * трюк для того чтобы получить число дней в месяце
   * https://stackoverflow.com/a/1184359
   * */
  const daysInMonth = getDaysInMonth(year, month)

  const currentMonthDays = Array.from(
    range(1, daysInMonth),
    day => new Date(year, month, day)
  )

  const daysInPrevMonth = getDaysInMonth(year, month - 1)
  const prevMonthDays =
    firstDayOfMonth !== 1
      ? Array.from(
          range(daysInPrevMonth - firstDayOfMonth + 2, daysInPrevMonth),
          day => new Date(year, month - 1, day)
        )
      : []

  const calendarPage = prevMonthDays.concat(currentMonthDays)
  const weeksInCalendarPage = 6
  const daysInCalendarPage = 7 * weeksInCalendarPage

  const nextMonthDays =
    calendarPage.length < daysInCalendarPage
      ? Array.from(
          range(1, daysInCalendarPage - calendarPage.length),
          day => new Date(year, month + 1, day)
        )
      : []

  return calendarPage.concat(nextMonthDays)
}

export function getTimeStampInSecs(datetime: Date) {
  return Math.floor(datetime.getTime() / 1000)
}

export function getDateFrom(timestampInSecs: number) {
  return new Date(timestampInSecs * 1000)
}

export function isSameMonth(dateA: Date, dateB: Date) {
  return (
    dateA.getMonth() === dateB.getMonth() &&
    dateA.getFullYear() === dateB.getFullYear()
  )
}

export function isSameDay(firstDate: Date, secondDate: Date) {
  if (!firstDate || !secondDate) return false
  return (
    firstDate.getFullYear() === secondDate.getFullYear() &&
    firstDate.getMonth() === secondDate.getMonth() &&
    firstDate.getDate() === secondDate.getDate()
  )
}

export function isWeekend(day: Date) {
  const weekDay = day.getDay()
  return weekDay === 0 || weekDay === 6
}

export function isDayWithinRange(
  dateToCheck: Date,
  startDate?: Date,
  endDate?: Date
) {
  let isOverStartDate = true
  if (startDate) {
    startDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate()
    )
    isOverStartDate = dateToCheck >= startDate
  }
  let isLessThanEndDate = true
  if (endDate) {
    endDate = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate(),
      23,
      59,
      59
    )
    isLessThanEndDate = dateToCheck <= endDate
  }
  return isOverStartDate && isLessThanEndDate
}

export function formatDate(date: Date) {
  const formatDigits = (digits: number) => digits.toString().padStart(2, '0')
  const day = formatDigits(date.getDate())
  const month = formatDigits(date.getMonth() + 1)
  const year = date.getFullYear().toString().slice(-2)
  return `${day}.${month}.${year}`
}

export function formatDateRelative(date: Date, contractedForm = false) {
  const now = new Date()
  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric'
  }
  if (!isSameDay(now, date)) {
    options.day = 'numeric'
    options.month = 'long'
  }
  if (now.getFullYear() !== date.getFullYear()) {
    options.year = 'numeric'
    if (contractedForm) {
      delete options.hour
      delete options.minute
    }
  }

  const dateFormatter = new Intl.DateTimeFormat('ru', options)
  return dateFormatter.format(date).replace(',', '')
}

export function isTodayOrFuture(date?: Date) {
  if (!date) return false
  const now = new Date()
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
  return date >= today
}

export const monthNames = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь'
]

export const dayNames = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс']
