import React from 'react'
import styles from './Chronometer.module.css'

const symbols = new Map([
  ['+', 'плюс'],
  ['$', 'доллар'],
  ['%', 'процент'],
  ['@', 'собака'],
  ['<', 'меньше'],
  ['>', 'больше'],
  ['=', 'равно']
])
const hundreds = new Map([
  ['1', 'сто'],
  ['2', 'двести'],
  ['3', 'триста'],
  ['4', 'четыреста'],
  ['5', 'пятьсот'],
  ['6', 'шестьсот'],
  ['7', 'семьсот'],
  ['8', 'восемьсот'],
  ['9', 'девятьсот']
])
const tens = new Map([
  ['1', 'десять'],
  ['2', 'двадцать'],
  ['3', 'тридцать'],
  ['4', 'сорок'],
  ['5', 'пятьдесят'],
  ['6', 'шестьдесят'],
  ['7', 'семьдесят'],
  ['8', 'восемьдесят'],
  ['9', 'девяносто']
])
const nums = new Map([
  ['1', 'один'],
  ['2', 'два'],
  ['3', 'три'],
  ['4', 'четыре'],
  ['5', 'пять'],
  ['6', 'шесть'],
  ['7', 'семь'],
  ['8', 'восемь'],
  ['9', 'девять'],
  ['10', 'десять'],
  ['11', 'одиннадцать'],
  ['12', 'двенадцать'],
  ['13', 'тринадцать'],
  ['14', 'четырнадцать'],
  ['15', 'пятнадцать'],
  ['16', 'шестнадцать'],
  ['17', 'семнадцать'],
  ['18', 'восемнадцать'],
  ['19', 'девятнадцать']
])

function numberToWords(number: number) {
  let word = ''
  if (number < 1000) {
    const h = Math.floor(number / 100)
    if (h) word += hundreds.get(h.toString()) + ' '
    number = number % 100
    if (nums.has(number.toString())) {
      word += nums.get(number.toString())
      return word
    } else {
      const t = Math.floor(number / 10)
      if (t) word += tens.get(t.toString()) + ' '
    }
    number = number % 10
    if (nums.get(number.toString())) {
      word += nums.get(number.toString())
    }
  } else {
    word += numberToWords(Math.floor(number / 1000)) + ' тысяч '
    word += numberToWords(number % 1000)
  }
  return word
}

function countWords(text: string) {
  for (const [symbol, word] of symbols.entries()) {
    text = text.replace(new RegExp(`\\${symbol}`, 'g'), ` ${word} `)
  }
  const numbers = text.match(/\d+/g)
  if (numbers) {
    for (const number of numbers) {
      text = text.replace(number, numberToWords(+number))
    }
  }
  text = text
    .toLowerCase()
    .replace(/[^a-z а-я]/g, ' ')
    .replace(/\s{2,}/g, ' ')
    .trim()

  const words = text.split(/\s/)
  const smallWords = words.filter(word => word.length < 3)
  const normalWords = words.filter(word => !smallWords.includes(word))
  const smallWordsExtra = Math.floor(
    smallWords.reduce((acc, word) => acc + word.length, 0) / 4
  )

  return normalWords.length + smallWordsExtra
}

function calcChronometry(text: string) {
  if (!text) return 0
  return Math.ceil(countWords(text) / 2)
}

interface ChronometerProps {
  text: string
}

export default function Chronometer(props: ChronometerProps) {
  const chronometry = calcChronometry(props.text)
  return (
    <div className={styles.chronometer} data-testid='chronometer'>
      Хронометраж: {chronometry} сек.
    </div>
  )
}
