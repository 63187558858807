export function* range(start: number, end: number) {
  let counter = start
  while (counter <= end) yield counter++
}

export function capitalize(word: string) {
  return word[0].toUpperCase() + word.slice(1)
}

export function isMobileDevice() {
  return (
    (!!navigator.maxTouchPoints ||
      'ontouchstart' in document.documentElement) &&
    /android|iPad|iPhone|iPod/i.test(navigator.userAgent)
  )
}

export function clearLocalStorage(){
  const token = localStorage.getItem('token')
  const refreshToken = localStorage.getItem('refreshToken')
  localStorage.clear()
  localStorage.setItem('token', token!)
  localStorage.setItem('refreshToken', refreshToken!)
}

export function startFileDownload(fileName: string, fileUrl: string) {
  const a = document.createElement('a')
  a.download = fileName
  a.href = fileUrl
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export function formatDate(dateN: any) {
  function declOfNum(number: number, words: string[]) {
    return words[
      number % 100 > 4 && number % 100 < 20
        ? 2
        : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? Math.abs(number) % 10 : 5]
    ]
  }

  const current = new Date().getTime()
  const notifDate = new Date(dateN * 1000)
  const date = notifDate.getTime()

  const diff = current - date
  const minutesPassed = Math.floor(diff / 1000 / 60)

  const hoursPassed = Math.floor(minutesPassed / 60)

  let minutesRest = minutesPassed

  if (hoursPassed > 0) {
    minutesRest = minutesPassed - hoursPassed * 60
  }

  const hourNumerals = ['час', 'часа', 'часов']

  const minuteNumerals = ['минуту', 'минуты', 'минут']

  let res
  if (hoursPassed > 11) {
    res = notifDate.toLocaleString()
  } else if (minutesRest === 0) {
    res = `только что`
  } else if (hoursPassed === 0) {
    res = `${minutesRest} ${declOfNum(minutesRest, minuteNumerals)} назад`
  } else {
    res = `${hoursPassed} ${declOfNum(
      hoursPassed,
      hourNumerals
    )} и ${minutesRest} ${declOfNum(minutesRest, minuteNumerals)} назад`
  }

  return res
}

export function getFaviconEl() {
  return document.querySelector('[rel="shortcut icon"]')
}

export const priorities = ['Низкий', 'Обычный', 'Высокий']

export function stripPrefix(str: string) {
  const match = /(?<prefix>.+)\.(?<rest>.+)/.exec(str)
  if (!match) return str
  return match.groups!.rest
}
