import React, { useEffect, useRef, useState } from 'react'
import { useMutation } from 'react-query'
import { Link } from 'react-router-dom'
import Button from '../components/base/Button'
import {
  markAllNotificationsAsRead,
  markNotificationAsRead
} from '../shared/apiService'
import { useIsInViewport } from '../shared/hooks/useIsInViewport'
import {
  useUnreadNotificationCount,
  useUnreadNotifications
} from '../shared/hooks/useNotification'
import styles from './NotificationTab.module.css'

function NotifRow({ id, html, date }: { id: string; html: any; date: any }) {
  const hiddenRef = useRef<HTMLDivElement>(null)
  const isInViewport = useIsInViewport(hiddenRef)
  const [viewed, setViewed] = useState(false)

  const { refetch } = useUnreadNotificationCount()

  const mutation = useMutation(() => markNotificationAsRead(id), {
    onError: () => {
      console.log('Error in reading unread notifies')
    },
    onSuccess: async () => {
      await refetch()
    }
  })

  useEffect(() => {
    if (!viewed && isInViewport) {
      setViewed(true)
      mutation.mutate()
    }
  }, [isInViewport, id, viewed, mutation])

  return (
    <div className={styles.row} ref={hiddenRef}>
      <div style={{ paddingLeft: 20, paddingTop: 10 }}>
        <div className={styles.date}>{date}</div>
        <div className={styles.itemContainer}>{html}</div>
      </div>
    </div>
  )
}

export default function NotificationTab({
  forwardedRef
}: {
  forwardedRef?: React.RefObject<HTMLDivElement>
}) {
  const [isButtonEnabled, setIsButtonEnebled] = useState(false)

  const { data: notifications, refetch: refetchNotifications } =
    useUnreadNotifications(20)

  const { refetch } = useUnreadNotificationCount()

  const { mutate, isLoading } = useMutation(
    () => {
      if (notifications && notifications.length > 0) {
        return markAllNotificationsAsRead(notifications[0].id)
      }
      return Promise.resolve(false)
    },
    {
      onError: () => {
        console.log('Error in reading unread notifies')
      },
      onSuccess: async () => {
        await refetchNotifications()
        await refetch()
      }
    }
  )

  const handleMarkAllRead = () => {
    mutate()
  }

  useEffect(() => {
    setIsButtonEnebled(false)
    if (notifications!.length > 0) {
      setIsButtonEnebled(true)
    }
  }, [notifications])

  return (
    <div className={styles.container} ref={forwardedRef}>
      {!notifications?.length && (
        <div className={styles.empty}>Непрочитаннных уведомлений нет</div>
      )}
      <div className={styles.tab}>
        {notifications?.map((item: any, i: number) => (
          <NotifRow key={i} id={item.id} html={item.html} date={item.date} />
        ))}
      </div>
      <div className={styles.linkContainer}>
        <Link to='/notification' className={styles.link}>
          Все уведомления
        </Link>
        {isButtonEnabled && (
          <Button
            status={isLoading ? 'loading' : 'enabled'}
            className={styles.markButton}
            name={'Прочитать все'}
            onClick={handleMarkAllRead}
          />
        )}
      </div>
    </div>
  )
}
