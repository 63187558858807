import React, { useContext } from 'react'
import { AuthContext } from '../../app/authContext'
import { formatDateRelative, getDateFrom } from '../../shared/dateUtils'
import { TaskData } from '../../shared/models'
import taskStyles from '../Tasks/List/Task.module.css'
import style from './TaskPage.module.css'

export default function MainInfoBlock({
  task,
  showStatusModal
}: {
  task: Required<TaskData>
  showStatusModal: () => void
}) {
  const { userType } = useContext(AuthContext)
  const actionsAvailable = task.canPause || task.canProgress || task.canFinish
  return (
    <>
      <div className={style.title}>{task.title}</div>
      <div className={style.mainInfoContainer}>
        <div className={style.statusContainer}>
          <div className={taskStyles[`status--${task.status.code}`]}>
            {task.status.name}
          </div>
          {userType !== 'client' && actionsAvailable && (
            <button
              type='button'
              className={style.editButton}
              onClick={showStatusModal}
            />
          )}
        </div>
        <div className={style.time}>
          {formatDateRelative(getDateFrom(task.createdDatetime))}
        </div>
      </div>
    </>
  )
}
