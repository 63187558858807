import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { useMutation } from 'react-query'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router-dom'
import Button from '../../components/base/Button'
import Spinner from '../../components/base/Spinner'
import Title from '../../components/base/Title'
import {
  markAllNotificationsAsRead,
  markNotificationAsRead
} from '../../shared/apiService'
import { useIsInViewport } from '../../shared/hooks/useIsInViewport'
import {
  useNotifications,
  useUnreadNotificationCount
} from '../../shared/hooks/useNotification'
import styles from './NotificationList.module.css'

function NotifRow({
  id,
  html,
  read,
  date,
  page
}: {
  id: string
  html: any
  read: 'Y' | 'N'
  date: string | number | Date
  page: number
}) {
  const hiddenRef = useRef<HTMLDivElement>(null)
  const isInViewport = useIsInViewport(hiddenRef)
  const [viewed, setViewed] = useState(false)

  const { refetch } = useNotifications(page)

  const mutation = useMutation(() => markNotificationAsRead(id), {
    onError: () => {
      console.log('Error in reading unread notifies')
    },
    onSuccess: async () => {
      await refetch()
    }
  })

  useEffect(() => {
    if (!viewed && isInViewport) {
      setViewed(true)
      mutation.mutate()
    }
  }, [isInViewport, id, viewed, mutation])

  return (
    <div
      key={id}
      className={read === 'Y' ? styles.row : styles.rowUnread}
      ref={hiddenRef}
    >
      <div style={{ padding: 20 }}>
        <div className={styles.date}>{date}</div>
        <div className={styles.text}>{html}</div>
        <div className={styles.space}></div>
      </div>
    </div>
  )
}

export default function NotificationList() {
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' })
  const history = useHistory()
  const [page, setPage] = useState(1)
  const {
    data: notifications,
    isLoading,
    refetch: refetchNotifications
  } = useNotifications(page)

  const { refetch, data: unreadNotifsCount } = useUnreadNotificationCount()

  const { mutate, isLoading: markLoading } = useMutation(
    () => {
      if (notifications && notifications.length > 0) {
        return markAllNotificationsAsRead(notifications[0].id)
      }
      return Promise.resolve(false)
    },
    {
      onError: () => {
        console.log('Error in reading unread notifies')
      },
      onSuccess: async () => {
        await refetchNotifications()
        await refetch()
      }
    }
  )

  const handleMarkAllRead = () => {
    mutate()
  }

  return (
    <div
      className={classNames(styles.pageContainer, {
        [styles.pageContainerMobile]: !isDesktop
      })}
    >
      <div
        className={styles.back}
        onClick={() => {
          history.goBack()
        }}
      >
        <div className={styles.backArrow}></div>
        {`Назад`}
      </div>

      <Title>Уведомления</Title>
      {unreadNotifsCount && unreadNotifsCount.count > 0 && (
        <Button
          status={markLoading ? 'loading' : 'enabled'}
          className={styles.markButton}
          name={'Прочитать все'}
          onClick={handleMarkAllRead}
        />
      )}

      {isLoading && <Spinner className={styles.spinner} />}
      {!isLoading &&
        notifications?.map(item => {
          return (
            <NotifRow
              key={item.id}
              id={item.id}
              html={item.html}
              date={item.date}
              read={item.read}
              page={page}
            />
          )
        })}
      {!isLoading && (
        <div className={styles.paginationBlock}>
          {page > 1 && (
            <div
              className={styles.leftArrow}
              onClick={() => {
                setPage(old => old - 1)
              }}
            ></div>
          )}
          <span>Сраница: {page}</span>
          <div
            className={styles.rightArrow}
            onClick={() => {
              setPage(old => old + 1)
            }}
          ></div>
        </div>
      )}
    </div>
  )
}
