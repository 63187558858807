import * as Sentry from '@sentry/browser'
import React from 'react'
import logoIcon from '../icons/logo_none.svg'
import styles from './ErrorBoundary.module.css'

export default class ErrorBoundary extends React.Component {
  state: { hasError: boolean }

  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error | null) {
    Sentry.captureException(error)
  }

  render() {
    if (this.state.hasError) {
      return (
        <main className={styles.container}>
          <img src={logoIcon} alt='Logo' className={styles.logo} />
          <h1 className={styles.title}>Произошла ошибка</h1>
          <p>
            Пожалуйста, свяжитесь с менеджером площадки или администратором
            сайта – они помогут разобраться с причинами
          </p>
        </main>
      )
    }

    return this.props.children
  }
}
