import Modal from '../../components/Modal'

export function WarningModal({ hideModal }: { hideModal: () => void }) {
  return (
    <Modal hideModal={hideModal}>
      <div>
        Нельзя заказать выбранный тип графики.
        <br />
        Пожалуйста, обратитесь к супервайзеру сайта за помощью или выберете
        другой тип графики в форме.
      </div>
    </Modal>
  )
}
