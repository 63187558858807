import { HtmlEditor2 } from 'emg-ui-kit/components/HtmlEditor2'
import React, { useEffect, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import styles from './Base.module.css'
import Checkbox from './Checkbox'
import TextArea from './TextArea'
interface EditorProps {
  value: string
  setValue: (value: string) => void
  label?: string
  placeholder?: string
  required?: boolean
  isValid?: boolean
  onFocus?: () => void
  disabled?: boolean
  topLabel?: boolean
  extendedEditor: boolean
  setExtendedEditor: (value: boolean) => void
  initialValue?: string
  validationMessage?: string
  forwardedRef?: React.MutableRefObject<HTMLDivElement | null>
}

const buttonList = [
  ['undo', 'redo'],
  [
    'bold',
    'italic',
    'underline',
    'fontColor',
    'subscript',
    'superscript',
    'list',
    'link'
  ],
  ['removeFormat']
]

export default function Editor(props: EditorProps) {
  const isDesktop = useMediaQuery({ query: '(min-width: 600px)' })
  return (
    <>
      <Checkbox
        label='Расширенный редактор'
        checked={props.extendedEditor}
        setChecked={props.setExtendedEditor}
        style={isDesktop && !props.topLabel ? { marginLeft: 186 } : {}}
      />
      {props.extendedEditor ? (
        <div className={styles.control}>
          {props.label && (
            <label className={styles.label}>
              {props.label}
              {props.required && <sup className={styles.requiredMark}>*</sup>}
            </label>
          )}
          <div className={styles.inputContainer} style={{ width: '300px' }}>
            <HtmlEditor2
              defaultValue={props.initialValue ?? ''}
              colorList={[]}
              buttonList={buttonList}
              isValid={props.isValid}
              onChange={props.setValue}
              validationMessage={props.validationMessage}
              editorOptions={{
                height: 'auto',
                minHeight: '180px',
                pasteTagsBlacklist: '(pre)'
              }}
            />
          </div>
        </div>
      ) : (
        <TextArea
          ref={props.forwardedRef}
          name='text'
          label={props.label}
          placeholder={props.placeholder}
          value={props.value}
          onChange={event => props.setValue(event.target.value)}
          required={props.required}
          isValid={props.isValid}
          validationMessage={props.validationMessage}
        />
      )}
    </>
  )
}

export function extractContent(html: string) {
  const element = document.createElement('div')
  element.innerHTML = html
    .replace(/<\/p>/g, '</p>\n')
    .replace(/<\/li>/g, '</li>\n')
  return element.textContent ?? ''
}

export function useEditor(initialText?: string) {
  const initialValue = useRef(initialText ?? '')
  const [value, setValue] = useState(initialText ?? '')
  const [extendedEditor, setExtendedEditor] = useState(true)
  const valueType: 'text' | 'html' = extendedEditor ? 'html' : 'text'

  useEffect(
    function convertHtmlToTextOnEditorSwitch() {
      if (!extendedEditor) {
        setValue(value => extractContent(value))
      }
    },
    [extendedEditor]
  )

  useEffect(
    function saveValueToExtendedEditorInitial() {
      if (!extendedEditor) {
        initialValue.current = value
      }
    },
    [extendedEditor, value]
  )

  return {
    extendedEditor,
    setExtendedEditor,
    initialValue: initialValue.current,
    value,
    setValue,
    valueType
  }
}

export function useExtendedEditor() {
  const [extendedEditor, setExtendedEditor] = useState(true)
  const textType: 'text' | 'html' = extendedEditor ? 'html' : 'text'
  return { extendedEditor, setExtendedEditor, textType }
}
