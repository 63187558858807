import classNames from 'classnames'
import React from 'react'
import styles from './Button.module.css'

export type ButtonStatus = 'enabled' | 'disabled' | 'loading' | 'done'

interface ButtonProps {
  status?: ButtonStatus
  name: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  className?: string
  style?: React.CSSProperties
  type?: 'submit' | 'button'
}

export default function Button(props: ButtonProps) {
  const status = props.status ?? 'enabled'
  return (
    <button
      className={classNames(styles.button, {
        [styles.loading]: status === 'loading',
        [styles.disabled]: status === 'disabled',
        [styles.done]: status === 'done',
        ...(props.className && { [props.className]: props.className })
      })}
      style={props.style}
      disabled={status !== 'enabled'}
      onClick={props.onClick}
      type={props.type}
    >
      {props.name}
    </button>
  )
}
