import React from 'react'
import { TaskFilter } from '../../../shared/models'
import FilterButton from './FilterButton'
import styles from './Filters.module.css'

interface DropdownProps {
  filters: TaskFilter[]
  handleFilterChange: (filterId: number) => void
  currentFilter?: number
}

export default function Dropdown({
  filters,
  handleFilterChange,
  currentFilter
}: DropdownProps) {
  return (
    <div className={styles.dropdown}>
      {filters.map(filter => (
        <FilterButton
          filter={filter}
          handleFilterChange={handleFilterChange}
          isCurrent={currentFilter === filter.id}
          className={styles.dropdownButton}
        />
      ))}
    </div>
  )
}
