import React, { useState } from 'react'
import styles from '../Base.module.css'

interface Props {
  hint: string
}

export default function Hint(
  props: React.AllHTMLAttributes<HTMLDivElement> & Props
) {
  const [hintVisible, setHintVisible] = useState(false)
  return (
    <div style={props.style} className={props.className}>
      <button
        className={styles.hintButton}
        onMouseEnter={() => setHintVisible(true)}
        onMouseLeave={() => setHintVisible(false)}
        onTouchEnd={() => setHintVisible(!hintVisible)}
        onBlur={() => setHintVisible(false)}
      />
      {hintVisible && (
        <section className={styles.hintContainer}>
          <div className={styles.hintTriangle} />
          <div className={styles.hint}>{props.hint}</div>
        </section>
      )}
    </div>
  )
}
