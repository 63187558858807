import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { AuthContext } from '../../app/authContext'
import {
  ChannelTags,
  pullChannel,
  pullWatchChannel
} from '../../shared/apiService'

export type MessageData = {
  command: string
  params: Record<string, any>
}

export type MessageHandler = (messageData: MessageData) => void

export type SubscriptionParams =
  | { type: 'tasks' }
  | { type: 'comments'; taskId: number[] }
  | { type: 'tasks_comments'; taskId: number[] }

/** do not break connection on visibilityChange so notifications would work */
export default function useChannels(
  handler: MessageHandler,
  params?: SubscriptionParams
) {
  const [ready, setReady] = useState(false)
  const channelId = useRef('')
  const connectionUrl = useRef('')
  const wsRef = useRef<WebSocket>()
  const timer = useRef<number>()

  const initConnection = useCallback(() => {
    if (connectionUrl.current) {
      wsRef.current = new WebSocket(connectionUrl.current)
      wsRef.current.onmessage = event => {
        const str = event.data.replace(new RegExp('#!NGINXNM[SE]!#', 'g'), '')
        const messageData = JSON.parse(str).text.MESSAGE[0] as MessageData
        handler(messageData)
      }
    }
  }, [handler])

  const { handleError, authenticated } = useContext(AuthContext)

  useEffect(() => {
    if (authenticated) {
      const checkSocketStatus = () => {
        if (wsRef.current?.readyState === WebSocket.CLOSED) {
          initConnection()
        }
      }

      pullChannel()
        .then(channelData => {
          connectionUrl.current = channelData.url
          channelId.current = channelData.channelId
          initConnection()
          timer.current = window.setInterval(checkSocketStatus, 30000)
          setReady(true)
        })
        .catch(handleError)
    }

    return () => {
      wsRef.current?.close(1000, 'page closed')
      window.clearInterval(timer.current)
    }
  }, [authenticated, handleError, initConnection])

  useEffect(() => {
    if (authenticated) {
      if (ready && params) {
        const tags: ChannelTags[] =
          params.type === 'tasks'
            ? ['task_update']
            : params.type === 'comments'
            ? ['comments']
            : ['task_update', 'comments']
        const taskId = 'taskId' in params ? params.taskId : undefined
        pullWatchChannel(tags, taskId).catch(handleError)
      }
    }
  }, [ready, params, handleError, authenticated])
}
