import React, { useCallback, useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../app/authContext'
import { clearLocalStorage } from '../../shared/utils'
import { isAllValid } from '../../shared/validators'

function fillObjWith<T, U>(baseObj: T, fillValue: U): Record<keyof T, U> {
  const entries = Object.keys(baseObj).map(key => [key, fillValue])
  return Object.fromEntries(entries)
}

function getChangedObj<T>(
  initFn: () => T,
  initVal = false
): Record<keyof T, boolean> {
  return fillObjWith(initFn(), initVal)
}

export function useForm<T>(
  initValues: () => T,
  validate: (values: T) => Partial<Record<keyof T, string | undefined>>,
  onSubmit: (values: T) => Promise<unknown>,
  projectId: string,
  graphicsTypeId: string,
  graphicsKindId: string
) {
  const [values, setValues] = useState(initValues)
  const [changed, setChanged] = useState<Record<keyof T, boolean>>(
    getChangedObj(initValues)
  )
  const [isLoading, setIsLoading] = useState(false)

  const { handleError, setAlert } = useContext(AuthContext)

  const errors = validate(values)
  const allValid = isAllValid(errors)

  const isValid = (key: keyof T) => {
    return !changed[key] || !errors[key]
  }

  const setValue = useCallback((key: keyof T, value: T[typeof key]) => {
    setValues(values => ({ ...values, [key]: value }))
    setChanged(changed => ({ ...changed, [key]: true }))
  }, [])

  const setMultipleValues = (multValues: Partial<T>) => {
    setValues({ ...values, ...multValues })
    setChanged({ ...changed, ...fillObjWith(multValues, true) })
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    if (!allValid) {
      setChanged(getChangedObj(initValues, true))
      return
    }

    setIsLoading(true)
    try {
      await onSubmit(values)
    } catch {
      setIsLoading(false)
    }
  }

  useEffect(
    function saveValuesToStorage() {
      try {
        localStorage.setItem(
          'restore',
          JSON.stringify({
            ...values,
            files: [],
            projectId,
            graphicsTypeId,
            graphicsKindId
          })
        )
      } catch (error) {
        clearLocalStorage()
      }
    },
    [graphicsTypeId, graphicsKindId, handleError, projectId, setAlert, values]
  )

  return {
    values,
    setValue,
    setMultipleValues,
    errors,
    allValid,
    isValid,
    handleSubmit,
    isLoading
  }
}
