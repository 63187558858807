import React, { useContext, useRef } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { AuthContext } from '../../app/authContext'
import { MultiSearch, Option } from '../../components/base/Search/'
import Spinner from '../../components/base/Spinner'
import { changeTaskAuditors } from '../../shared/apiService'
import { TaskData, UserInfo } from '../../shared/models'
import style from './TaskPage.module.css'

interface Props {
  task: Required<TaskData>
  auditorsLoading: boolean
  availableAuditors: UserInfo[]
}

export default function AuditorsBlock({
  task,
  auditorsLoading,
  availableAuditors
}: Props) {
  const { handleError } = useContext(AuthContext)
  const queryClient = useQueryClient()

  const queryKey = ['task', task.id.toString()]

  const auditorsMutation = useMutation(
    (opts: Option[]) => {
      const auditorsIds = opts.map(({ id }) => id)
      return changeTaskAuditors(task.id, auditorsIds)
    },
    {
      onError: handleError,
      onSettled: () => {
        queryClient.invalidateQueries(queryKey)
      }
    }
  )

  const timer = useRef<number>()

  const handleUpdateSelected = async (opts: Option[]) => {
    window.clearTimeout(timer.current)
    timer.current = window.setTimeout(() => {
      auditorsMutation.mutate(opts)
    }, 1500)

    const auditors = opts
      .reduce<UserInfo[]>((acc, opt) => {
        const auditor = availableAuditors!.find(
          auditor => opt.id === auditor.id
        )
        if (auditor) acc.push(auditor)
        return acc
      }, [])
      .sort((a, b) => a.id - b.id)

    await queryClient.cancelQueries(queryKey)
    const prevTask = queryClient.getQueryData(queryKey) as Required<TaskData>
    queryClient.setQueryData(queryKey, { ...prevTask, auditors })
  }

  if (!auditorsLoading && !availableAuditors.length) return null
  return (
    <div className={style.auditorsRow}>
      <div className={style.auditorsTitle}>Наблюдатели:</div>
      <div className={style.infoDescription}>
        {auditorsLoading ? (
          <Spinner className={style.infoSpinner} />
        ) : (
          <MultiSearch
            options={availableAuditors.map(auditor => ({
              id: auditor.id,
              value: auditor.formatted_name
            }))}
            selected={task.auditors.map(auditor => ({
              id: auditor.id,
              value: auditor.formatted_name
            }))}
            updateSelected={handleUpdateSelected}
            placeholder='Добавить наблюдателей'
          />
        )}
      </div>
    </div>
  )
}
