import classNames from 'classnames'
import React from 'react'
import { useMediaQuery } from 'react-responsive'
import styles from './ContentSkeleton.module.css'
import SideMenuSkeleton from './SideMenuSkeleton'
import style from './TaskPage.module.css'

function Row() {
  return (
    <div className={styles.row}>
      <div className={styles.firstLine} />
      <div className={styles.secondLine} />
      <div className={styles.thirdLine} />
    </div>
  )
}

export default function ContentSkeleton() {
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' })
  return (
    <div
      className={classNames(style.pageContainer, {
        [style.pageContainerMobile]: !isDesktop
      })}
      style={{ marginTop: 30 }}
    >
      {isDesktop && <SideMenuSkeleton />}
      <section
        className={classNames(style.workspace, {
          [style.workspaceMobile]: !isDesktop
        })}
      >
        <div className={styles.title} />
        <div className={styles.status} />
        <div className={styles.description} />
        {Array(5)
          .fill(null)
          .map((_, idx) => (
            <Row key={idx} />
          ))}
      </section>
    </div>
  )
}
