import classNames from 'classnames'
import React from 'react'
import styles from '../Base.module.css'
import Error from './Error'
import Hint from './Hint'
import Label from './Label'

export interface BaseProps {
  label?: string
  required?: boolean
  isValid?: boolean
  validationMessage?: string
  hint?: string
  style?: React.CSSProperties
  name?: string
  className?: string
}

interface Props extends BaseProps {
  children: React.ReactElement | React.ReactElement[]
  forwardedRef?: React.ForwardedRef<HTMLDivElement>
  inputContainerClassName?: string
  inputContainerStyles?: React.CSSProperties
}

export default function InputContainer(props: Props) {
  const children = React.Children.map(props.children, child => {
    if (child.type === 'string') {
      return child
    } else {
      return React.cloneElement(child, {
        className: classNames(child.props.className, styles.controlInput, {
          [styles.controlInputInvalid]: props.isValid === false
        })
      })
    }
  })

  return (
    <div
      className={styles.control}
      style={props.style}
      ref={props.forwardedRef}
    >
      {props.label && (
        <Label
          message={props.label}
          htmlFor={props.name}
          required={props.required}
        />
      )}
      {props.hint && <Hint hint={props.hint} />}
      <div
        className={classNames(
          styles.inputContainer,
          props.inputContainerClassName
        )}
        style={props.inputContainerStyles}
      >
        {children}
        {props.isValid === false && (
          <Error validationMessage={props.validationMessage} />
        )}
      </div>
    </div>
  )
}
