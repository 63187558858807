import classNames from 'classnames'
import React, { useEffect, useMemo } from 'react'
import Dropzone from 'react-dropzone'
import styles from './FileInput.module.css'

const MAX_FILE_SIZE_MB = +process.env.REACT_APP_FILE_SIZE_MB!
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 10 ** 6

interface FileItemProps {
  file: File
  deleteFile: (file: File) => void
}

function FileItem({ file, deleteFile }: FileItemProps) {
  const fileUrl = useMemo(() => URL.createObjectURL(file), [file])

  useEffect(
    () => () => {
      URL.revokeObjectURL(fileUrl)
    },
    [fileUrl]
  )

  const MAX_NAME_SYMBOLS = 25
  const name =
    file.name.length < MAX_NAME_SYMBOLS
      ? file.name
      : file.name.slice(0, MAX_NAME_SYMBOLS) + '...'

  return (
    <div className={styles.fileContainer} key={file.name}>
      <button
        className={styles.deleteButton}
        onClick={() => deleteFile(file)}
      />
      {/image/.test(file.type) && (
        <div className={styles.imageContainer}>
          <img src={fileUrl} alt={file.name} className={styles.previewImage} />
        </div>
      )}
      <a
        href={fileUrl}
        target='_blank'
        rel='noopener noreferrer'
        className={styles.fileName}
      >
        {name}
      </a>
    </div>
  )
}

interface FileInputProps {
  files: File[]
  handleAcceptedFiles: (files: File[]) => void
  showAlert: (message: string) => void
  style?: React.CSSProperties
  className?: string
  disabled?: boolean
}

export default function FileInput(props: FileInputProps) {
  const deleteFile = (fileToDelete: File) => {
    const files = props.files.filter(file => file.name !== fileToDelete.name)
    props.handleAcceptedFiles(files)
  }

  const acceptFiles = (files: File[]) => {
    const allFiles = props.files.concat(files)
    const totalSize = allFiles.reduce((acc, file) => acc + file.size, 0)
    if (totalSize > MAX_FILE_SIZE_BYTES) {
      props.showAlert(`Суммарный размер файлов больше ${MAX_FILE_SIZE_MB} МБ`)
    } else {
      props.showAlert('')
      props.handleAcceptedFiles(allFiles)
    }
  }

  const handleRejected = () => {
    props.showAlert(`Файл больше ${MAX_FILE_SIZE_MB} МБ`)
  }

  return (
    <Dropzone
      multiple
      maxSize={MAX_FILE_SIZE_BYTES}
      onDrop={acceptFiles}
      onDropRejected={handleRejected}
      disabled={props.disabled}
    >
      {({ getRootProps, getInputProps }) => (
        <section
          className={classNames(styles.container, props.className)}
          style={props.style}
        >
          <div
            {...getRootProps()}
            className={classNames(styles.input, {
              [styles['input--disabled']]: props.disabled
            })}
          >
            <input {...getInputProps()} />
            Прикрепить файл (до {MAX_FILE_SIZE_MB} МБ)
          </div>
          {props.files.map(file => (
            <FileItem key={file.name} file={file} deleteFile={deleteFile} />
          ))}
        </section>
      )}
    </Dropzone>
  )
}
