import React, { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link, useLocation } from 'react-router-dom'
import BackButton from '../../components/BackButton'
import Spinner from '../../components/base/Spinner'
import Title from '../../components/base/Title'
import style from './SearchPage.module.css'
import { SearchResult } from './SearchResult'
import useSearch from './useSearch'

export default function SearchPage() {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const query = params.get('query') ?? ''

  const { setQuery, results, loading } = useSearch()

  useEffect(() => {
    setQuery(query)
  }, [query, setQuery])

  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 600px)' })

  if (loading) return <Spinner className={style.spinner} />
  return (
    <div className={style.container}>
      <BackButton />
      {query && isDesktopOrLaptop && (
        <Title>Результаты поиска для «{query}»</Title>
      )}
      {results.length ? (
        results.map(result => (
          <Link
            to={`/tasks/${result.taskId}`}
            key={result.taskId}
            className={style.link}
          >
            <SearchResult result={result} />
          </Link>
        ))
      ) : (
        <div className={style.noResults}>Нет результатов</div>
      )}
    </div>
  )
}
