import React from 'react'
import styles from './TaskTable.module.css'
import { TasksCountersObject } from './types'

export default function TasksCounters({
  tasksCountersState
}: {
  tasksCountersState: TasksCountersObject
}) {
  return (
    <div className={styles.taskCounters}>
      Выполняется:
      <span style={{ color: 'var(--blue)', fontFamily: 'var(--font-bold)' }}>
        {' '}
        {tasksCountersState.emg_tasks_org_do}
      </span>
      ; В очереди:{' '}
      <span style={{ color: '#FA964E', fontFamily: 'var(--font-bold)' }}>
        {tasksCountersState.emg_tasks_org_queue}
      </span>
    </div>
  )
}
