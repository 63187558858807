import React, { useMemo } from 'react'
import InputContainer, { BaseProps } from './BaseInput/InputContainer'

interface Props extends BaseProps {
  value?: string | number
  placeholder?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  type?: string
  autoComplete?: boolean
  inputMode?: 'text' | 'decimal'
}

function TextField(props: Props, ref: React.ForwardedRef<HTMLDivElement>) {
  const {
    value,
    placeholder,
    onChange,
    type,
    inputMode,
    autoComplete,
    ...baseProps
  } = props
  const inputProps = {
    value,
    placeholder,
    onChange,
    type,
    inputMode,
    id: props.name
  }

  const autoCompleteOptions = useMemo(() => {
    const options = props.name ? sessionStorage.getItem(props.name) : null
    return options ? options.split(',') : []
  }, [props.name])

  const datalistId = `${props.name ?? 'input'}-datalist`

  return (
    <>
      {autoComplete && (
        <datalist id={datalistId}>
          {autoCompleteOptions.map((option, index) => (
            <option key={index} value={option} />
          ))}
        </datalist>
      )}
      <InputContainer {...baseProps} forwardedRef={ref}>
        <input {...inputProps} autoComplete='off' />
      </InputContainer>
    </>
  )
}

export default React.forwardRef(TextField)
