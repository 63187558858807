import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { getTasksCounters } from '../../shared/apiService'
export default function useTasksCounters() {
  const [tasksCountersState, setTasksCountersState] = useState({
    emg_tasks_org_do: 0,
    emg_tasks_org_queue: 0
  })
  const { data } = useQuery('tasksCounters', getTasksCounters, {
    refetchOnWindowFocus: false,
    refetchInterval: false,
    select: arr => {
      const obj = {
        emg_tasks_org_do: 0,
        emg_tasks_org_queue: 0
      }
      for (const key in arr) {
        obj[arr[key].code] = arr[key].value
      }
      return obj
    }
  })
  useEffect(() => {
    if (data) {
      setTasksCountersState(data)
    }
  }, [data])

  return {
    tasksCountersState,
    setTasksCountersState,
    tasksCountersData: data
  }
}
