import classNames from 'classnames'
import React from 'react'
import { useMediaQuery } from 'react-responsive'
import styles from './Base.module.css'
import InputContainer, { BaseProps } from './BaseInput/InputContainer'

function trimOptionName(name: string, maxChars: number) {
  if (name.length <= maxChars) {
    return name
  } else {
    return name.slice(0, maxChars - 3) + '...'
  }
}

interface Props extends BaseProps {
  value?: string | number
  placeholder?: string
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void
  options: { id: string | number; name: string }[]
}

export default React.forwardRef<HTMLDivElement, Props>(function Select(
  props,
  ref
) {
  const { options, value, placeholder, onChange, className, ...baseProps } =
    props
  const inputProps = { value, placeholder, onChange, className, id: props.name }

  if (!props.value) {
    inputProps.className = classNames(inputProps.className, styles.placeholder)
  }

  const isDesktop = useMediaQuery({ query: '(min-width: 760px)' })

  const optionElements = options.map(option => (
    <option value={option.id} key={option.id}>
      {trimOptionName(option.name, isDesktop ? 45 : 35)}
    </option>
  ))

  return (
    <InputContainer {...baseProps} forwardedRef={ref}>
      <select {...inputProps} style={{ color: '#000' }}>
        <option value='' disabled>
          -- Выберите вариант --
        </option>
        {optionElements}
      </select>
    </InputContainer>
  )
})
