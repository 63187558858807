import React, { useCallback } from 'react'
import InputContainer, { BaseProps } from './BaseInput/InputContainer'
import DatePicker from './DatePicker'
import TimeInput from './TimeInput'

interface DateTimeProps extends BaseProps {
  value: Date | undefined
  setValue: (date?: Date) => void
  startDate?: Date
  endDate?: Date
}

export default React.forwardRef<HTMLDivElement, DateTimeProps>(
  function DateTime(props, ref) {
    const time = props.value ? getTimeStringFromDate(props.value) : '00:00'
    const { value, setValue } = props

    const handleDateChange = useCallback(
      (date?: Date) => {
        if (date) {
          const nextDate = getDateFromSeparateComponents(date, time)
          setValue(nextDate)
        } else {
          setValue(date)
        }
      },
      [setValue, time]
    )

    const handleTimeChange = useCallback(
      (time: string) => {
        if (value) {
          const nextDate = getDateFromSeparateComponents(value, time)
          setValue(nextDate)
        }
      },
      [setValue, value]
    )

    return (
      <InputContainer
        {...props}
        forwardedRef={ref}
        inputContainerStyles={{ display: 'flex', flexWrap: 'wrap' }}
      >
        <DatePicker
          selectedDate={props.value}
          name={`${props.name}Date`}
          onChange={handleDateChange}
          isValid={props.isValid}
          endDate={props.endDate}
        />
        <TimeInput
          time={time}
          name={`${props.name}Time`}
          disabled={!props.value}
          onChange={handleTimeChange}
          isValid={props.isValid}
        />
      </InputContainer>
    )
  }
)

function padNum(num: number) {
  return num.toString().padStart(2, '0')
}

function getTimeStringFromDate(date: Date) {
  const hours = padNum(date.getHours())
  const minutes = padNum(date.getMinutes())
  return `${hours}:${minutes}`
}

function getTimeValueFromString(time: string) {
  const [hours, minutes] = time.split(':').map(Number)
  return { hours, minutes }
}

function getDateFromSeparateComponents(date: Date, time: string) {
  const { hours, minutes } = getTimeValueFromString(time)
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    hours,
    minutes
  )
}
