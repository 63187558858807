import React, { useCallback, useContext, useMemo, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { AuthContext } from '../../../app/authContext'
import Select from '../../../components/base/Select'
import Title from '../../../components/base/Title'
import useCallbackOnVisible from '../../../shared/hooks/useCallbackOnVisible'
import useClearAlertOnUnmount from '../../../shared/hooks/useClearAlertOnUnmount'
import { Order, Sort } from '../../../shared/models'
import { taskCountersUsers } from '../constants'
import TasksCounters from '../Table/TasksCounters'
import useChannels, { MessageData, SubscriptionParams } from '../useChannels'
import { useTaskFilters } from '../useFilters'
import { useTasks } from '../useTasks'
import useTasksCounters from '../useTasksCounters'
import { updateTasksCounters } from '../utils'
import ListSkeleton from './ListSkeleton'
import Task from './Task'
import style from './TaskList.module.css'

interface SortType {
  sortParam: Sort
  order: Order
  name: string
}

const sortTypes: SortType[] = [
  { sortParam: 'title', order: 'asc', name: 'В алфавитном порядке А-Я' },
  { sortParam: 'title', order: 'desc', name: 'В алфавитном порядке Я-А' },
  {
    sortParam: 'changedDatetime',
    order: 'desc',
    name: 'По времени изменения: сначала новые'
  },
  {
    sortParam: 'changedDatetime',
    order: 'asc',
    name: 'По времени изменения: сначала старые'
  },
  {
    sortParam: 'createdDatetime',
    order: 'desc',
    name: 'По времени регистрации: сначала новые'
  },
  {
    sortParam: 'createdDatetime',
    order: 'asc',
    name: 'По времени регистрации: сначала старые'
  },
  { sortParam: 'status', order: 'desc', name: 'По статусу: А-Я' },
  { sortParam: 'status', order: 'asc', name: 'По статусу: Я-А' }
]

export default function TaskList() {
  const { userType, userId } = useContext(AuthContext)

  const { filters, filter, setFilter, sort, setSort, order, setOrder } =
    useTaskFilters()

  const { tasksCountersData, tasksCountersState, setTasksCountersState } =
    useTasksCounters()

  const {
    tasks,
    loadNext,
    allLoaded,
    isFetchingNextPage,
    isLoading: tasksLoading,
    isSuccess: tasksReady,
    refetch
  } = useTasks(filter, sort, order)

  const [sortAndOrder, setSortAndOrder] = useState<string>(() => {
    const foundIdx = sortTypes.findIndex(
      sortAndOrder =>
        sortAndOrder.order === order && sortAndOrder.sortParam === sort
    )
    return foundIdx >= 0 ? foundIdx.toString() : ''
  })

  const handleSortAndOrderChange = (value: string) => {
    setSortAndOrder(value)
    setSort(sortTypes[+value].sortParam)
    setOrder(sortTypes[+value].order)
  }

  useClearAlertOnUnmount()

  const handleChannelMessage = useCallback(
    (msgData: MessageData) => {
      if (['task_status_change', 'comment'].includes(msgData.command)) {
        refetch()
      }
      if (msgData.params.s1) {
        Object.keys(msgData.params.s1).forEach(key => {
          updateTasksCounters(setTasksCountersState, msgData, key)
        })
      }
    },
    [refetch, setTasksCountersState]
  )

  const subscriptionParams = useMemo<SubscriptionParams | undefined>(
    () =>
      tasks.length
        ? { type: 'tasks_comments', taskId: tasks.map(({ id }) => id) }
        : undefined,
    [tasks]
  )

  useChannels(handleChannelMessage, subscriptionParams)

  const callbackRef = useCallbackOnVisible(loadNext)

  const history = useHistory()
  const isTasksCountersShowing =
    tasksCountersData && taskCountersUsers.includes(userId)
  return (
    <>
      <Title>
        {userType === 'client' ? 'Список заказов' : 'Список задач'}
        {isTasksCountersShowing && (
          <TasksCounters tasksCountersState={tasksCountersState} />
        )}
      </Title>
      <fieldset>
        <div style={{ display: 'flex', marginBottom: '30px' }}>
          <Select
            name='taskType'
            options={filters}
            value={typeof filter === 'number' ? filter.toString() : ''}
            onChange={({ target: { value } }) => setFilter(parseInt(value))}
            style={{ marginBottom: 0, flexGrow: 999 }}
          />

          <select
            value={sortAndOrder}
            onChange={event => handleSortAndOrderChange(event.target.value)}
            className={style.imgSelect}
          >
            <option value='' disabled>
              -- Сортировать --
            </option>
            {sortTypes.map((sort, idx) => (
              <option key={idx} value={idx}>
                {sort.name}
              </option>
            ))}
          </select>
        </div>

        {tasks.map((task, idx) => (
          <Link
            to={`/tasks/${task.id}`}
            key={task.id}
            className={style.taskLink}
          >
            <Task
              task={task}
              ref={!allLoaded && idx === tasks.length - 1 ? callbackRef : null}
              dateKey={
                sort === 'changedDatetime' || sort === 'createdDatetime'
                  ? sort
                  : undefined
              }
            />
          </Link>
        ))}
        {(tasksLoading || isFetchingNextPage) && <ListSkeleton />}
        {tasksReady && !tasks.length && (
          <h3 className={style.noItems}>Нет задач</h3>
        )}
      </fieldset>
      <button
        className={style.plusButton}
        onClick={() => history.push('/order')}
      />
    </>
  )
}
