import React from 'react'
import InputContainer, { BaseProps } from './BaseInput/InputContainer'

interface Props extends BaseProps {
  value?: string
  placeholder?: string
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
}

function TextArea(props: Props, ref: React.ForwardedRef<HTMLDivElement>) {
  const { value, placeholder, onChange, ...baseProps } = props
  const inputProps = { value, placeholder, onChange, id: props.name }
  return (
    <InputContainer
      {...baseProps}
      forwardedRef={ref}
      // because of alignItems: 'baseline' issue ⬇️
      style={{ ...props.style, alignItems: 'flex-start' }}
    >
      <textarea {...inputProps} spellCheck='true' />
    </InputContainer>
  )
}

export default React.forwardRef(TextArea)
