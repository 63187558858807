import React from 'react'
import { formatDateRelative, getDateFrom } from '../../../shared/dateUtils'
import { TaskData } from '../../../shared/models'
import PriorityInfo from './PriorityInfo'
import style from './Task.module.css'

interface Props {
  task: TaskData
  dateKey?: 'changedDatetime' | 'createdDatetime'
}

export default React.forwardRef<HTMLDivElement, Props>(function Task(
  { task, dateKey },
  ref
) {
  const date = dateKey ? task[dateKey] : task.changedDatetime

  return (
    <article ref={ref} className={style.task}>
      <div className={style.project}>
        {task.clientProjectName || '—'}
        {task.responsibleUser && (
          <div>Исполнитель: {task.responsibleUser.formatted_name}</div>
        )}
        {task.deadline && (
          <div>
            Крайний срок: {formatDateRelative(getDateFrom(task.deadline), true)}
          </div>
        )}
      </div>
      <div className={style.time}>
        {date && formatDateRelative(getDateFrom(date), true)}
      </div>

      <div className={style.title}>{task.title}</div>
      <div className={style.priorityAndCommentsContainer}>
        {typeof task.priority === 'number' ? (
          <PriorityInfo
            priority={task.priority}
            style={{ marginLeft: 15, marginRight: 5 }}
            hideTitle
          />
        ) : (
          <div />
        )}
        <div className={style.comments}>
          {task.newCommentsCount ? `+${task.newCommentsCount}` : null}
        </div>
      </div>

      <div className={style[`status--${task.status.code}`]}>
        {task.status.name}
      </div>
    </article>
  )
})
