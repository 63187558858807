import React from 'react'
import styles from '../Base.module.css'

interface Props {
  message: string
  htmlFor?: string
  style?: React.CSSProperties
  required?: boolean
}

export default function Label(props: Props) {
  return (
    <label htmlFor={props.htmlFor} className={styles.label} style={props.style}>
      {props.message}
      {props.required && <sup className={styles.requiredMark}>*</sup>}
    </label>
  )
}
