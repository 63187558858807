import classNames from 'classnames'
import React from 'react'
import { TaskFilter } from '../../../shared/models'
import styles from './Filters.module.css'

interface FilterButtonProps {
  filter: TaskFilter
  handleFilterChange: (filterId: number) => void
  isCurrent: boolean
  className?: string
}

export default function FilterButton({
  filter,
  handleFilterChange,
  isCurrent,
  className
}: FilterButtonProps) {
  return (
    <button
      onClick={() => handleFilterChange(filter.id)}
      className={classNames(
        isCurrent ? styles.filterCurrent : styles.filter,
        className
      )}
    >
      {filter.name}
    </button>
  )
}
