import React from 'react'
import styles from './Notification.module.css'

interface Props {
  on?: boolean
  onClick?: () => void
  hasUnread: boolean
}

export default function Notification({ on, onClick, hasUnread }: Props) {
  return (
    <div
      className={
        hasUnread ? styles.unreadNotificationButton : styles.notificationButton
      }
      onClick={onClick}
    ></div>
  )
}
