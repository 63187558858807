import * as Sentry from '@sentry/browser'
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations'
import LogRocket from 'logrocket'
import React from 'react'
import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'
import App from './app/App'
import ErrorBoundary from './app/ErrorBoundary'

if (process.env.NODE_ENV === 'production') {
  LogRocket.init(process.env.REACT_APP_LOGROCKET_ID)
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    attachStacktrace: true,
    defaultIntegrations: false,
    integrations: [new CaptureConsoleIntegration({ levels: ['warn', 'error'] })]
  })
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID
  }
  TagManager.initialize(tagManagerArgs)
}

ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  document.getElementById('root')
)
