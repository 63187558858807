import React, { useContext, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { useMediaQuery } from 'react-responsive'
import { Link, useLocation } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import Spinner from '../components/base/Spinner'
import { gotoOldVersionPage } from '../shared/apiService'
import { AuthContext } from './authContext'
import styles from './Header.module.css'

interface MenuProps {
  visible: boolean
  close: () => void
}

function Tint({ visible, close }: MenuProps) {
  return (
    <CSSTransition
      in={visible}
      timeout={300}
      unmountOnExit
      classNames={{
        enter: styles.tintEnter,
        enterActive: styles.tintEnterActive,
        exit: styles.tintExit,
        exitActive: styles.tintExitActive
      }}
    >
      <div className={styles.tint} onClick={close} />
    </CSSTransition>
  )
}

export function Menu({ visible, close }: MenuProps) {
  const { logout, catalogUrl, userId, userType, logo } = useContext(AuthContext)
  const menuRef = useRef<HTMLDivElement>(null)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (visible && menuRef.current) menuRef.current.focus()
  }, [visible, menuRef])

  const handleLogout = () => {
    close()
    logout()
  }

  const location = useLocation()

  const handleSwitchToOldVersion = () => {
    setLoading(true)
    let url = `/company/personal/user/${userId}/tasks/`
    if (/order/.test(location.pathname)) {
      url += 'task/edit/0/'
    } else if (/tasks\/\d+/.test(location.pathname)) {
      const taskId = location.pathname.replace('/tasks/', '')
      url += `task/view/${taskId}/`
    }
    gotoOldVersionPage(url).catch(console.error)
  }

  let galleryUrl: string, teamUrl: string
  try {
    galleryUrl = new URL('/', catalogUrl).href
    teamUrl = new URL('/team', catalogUrl).href
  } catch (error) {
    galleryUrl = ''
    teamUrl = ''
  }

  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })

  const element = (
    <>
      <CSSTransition
        in={visible}
        timeout={300}
        unmountOnExit
        classNames={{
          enter: styles.menuEnter,
          enterActive: styles.menuEnterActive,
          exit: styles.menuExit,
          exitActive: styles.menuExitActive
        }}
      >
        <div
          ref={menuRef}
          className={styles.menu}
          onKeyDown={({ key }) => key === 'Escape' && close()}
          tabIndex={-1}
        >
          {loading ? (
            <Spinner className={styles.spinner} />
          ) : (
            <>
              <Link onClick={close} to='/order' className={styles.menuItem}>
                {userType === 'client' ? 'СДЕЛАТЬ ЗАКАЗ' : 'ПОСТАВИТЬ ЗАДАЧУ'}
              </Link>
              <Link onClick={close} to='/tasks' className={styles.menuItem}>
                {userType === 'client' ? 'СПИСОК ЗАКАЗОВ' : 'СПИСОК ЗАДАЧ'}
              </Link>
              {userType === 'client' && galleryUrl && (
                <a href={galleryUrl} className={styles.menuItem}>
                  Каталог графики
                </a>
              )}
              {userType === 'client' && teamUrl && (
                <a href={teamUrl} className={styles.menuItem}>
                  Контакты
                </a>
              )}
              <br />
              {isMobile && (
                <Link
                  onClick={close}
                  to='/search'
                  className={styles.searchButton}
                >
                  Поиск
                </Link>
              )}
              <button className={styles.logoutButton} onClick={handleLogout}>
                Выход
              </button>
              {logo !== 'map' && (
                <button
                  className={styles.oldVersionLink}
                  onClick={handleSwitchToOldVersion}
                >
                  перейти на полную версию сайта
                </button>
              )}
            </>
          )}
        </div>
      </CSSTransition>
      <Tint visible={visible} close={close} />
    </>
  )
  return ReactDOM.createPortal(element, document.getElementById('root')!)
}
