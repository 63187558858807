import React from 'react'
import {
  dayNames,
  getCalendarPage,
  isDayWithinRange,
  isSameDay,
  isSameMonth,
  monthNames
} from '../../../shared/dateUtils'
import styles from './DatePicker.module.css'
import { Day } from './Day'

interface CalendarProps {
  calendarDate: Date
  selectedDate?: Date
  setSelectedDate: (date: Date) => void
  endDate?: Date
  prevMonth: () => void
  nextMonth: () => void
}

export function Calendar({
  calendarDate,
  selectedDate,
  setSelectedDate,
  endDate,
  prevMonth,
  nextMonth
}: CalendarProps) {
  const month = calendarDate.getMonth()
  const year = calendarDate.getFullYear()
  const calendarPage = getCalendarPage(year, month)
  const now = new Date()

  const handleSelectDate = (event: React.MouseEvent) => {
    const dateString = (event.target as HTMLElement).getAttribute('data-date')
    if (dateString) {
      setSelectedDate(new Date(dateString))
    }
  }

  return (
    <div className={styles.picker}>
      <div className={styles.title}>
        <button type='button' className={styles.prev} onClick={prevMonth} />

        <div>
          {monthNames[month]} {year}
        </div>

        <button type='button' className={styles.next} onClick={nextMonth} />
      </div>

      <div className={styles.calendar} onClick={handleSelectDate}>
        {dayNames.map(dayName => (
          <div key={dayName} className={styles.dayName}>
            {dayName}
          </div>
        ))}

        {calendarPage.map(date => (
          <Day
            key={date.toString()}
            day={date}
            isSelected={!!selectedDate && isSameDay(selectedDate, date)}
            isSelectable={
              isSameMonth(calendarDate, date) &&
              isDayWithinRange(date, now, endDate)
            }
          />
        ))}
      </div>
    </div>
  )
}
