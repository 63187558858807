import escapeRegExp from 'lodash/escapeRegExp'
import { useEffect, useRef, useState } from 'react'
import { Option } from './types'

export function useAvailableOptions(
  query: string,
  options: Option[] | ((query: string) => Promise<Option[]>),
  selected?: Option | Option[]
) {
  const [availableOptions, setAvailableOptions] = useState<Option[]>([])
  const [isLoadingOptions, setIsLoadingOptions] = useState(false)

  const timeout = useRef<number>()
  const firstRender = useRef(true)

  useEffect(() => {
    if (typeof options === 'function') {
      window.clearTimeout(timeout.current)
      if (query || firstRender.current) {
        firstRender.current = false
        timeout.current = window.setTimeout(() => {
          setIsLoadingOptions(true)
          options(query)
            .then(setAvailableOptions)
            .then(() => setIsLoadingOptions(false))
        }, 500)
      }
    }
  }, [options, query])

  useEffect(() => {
    const isSelected = (option: Option) => {
      if (selected instanceof Array) {
        const selectedIds = new Set(selected.map(({ id }) => id))
        return selectedIds.has(option.id)
      } else if (typeof selected === 'object') {
        return selected.id === option.id
      } else {
        return false
      }
    }
    const queryRegexp = new RegExp(escapeRegExp(query), 'i')
    const shouldBeVisible = (option: Option) => {
      return !isSelected(option) && queryRegexp.test(option.value)
    }
    if (typeof options === 'object') {
      setAvailableOptions(options.filter(shouldBeVisible))
    }
  }, [options, selected, query])

  return { availableOptions, isLoadingOptions }
}
