import classNames from 'classnames'
import React from 'react'
import styles from './Burger.module.css'

interface Props {
  on: boolean
  onClick: () => void
  style?: React.CSSProperties
}

export default function Burger({ on, onClick, style }: Props) {
  return (
    <div className={styles.container} onClick={onClick} style={style}>
      <div className={classNames(styles.line, { [styles.openTop]: on })} />
      <div className={classNames(styles.line, { [styles.openMiddle]: on })} />
      <div className={classNames(styles.line, { [styles.openBottom]: on })} />
    </div>
  )
}
