import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { formatDate, getCurrentDate } from '../../../shared/dateUtils'
import baseStyles from '../Base.module.css'
import { Calendar } from './Calendar'
import styles from './DatePicker.module.css'

interface DatePickerProps {
  name: string
  isValid?: boolean
  selectedDate?: Date
  endDate?: Date
  onChange: (date?: Date) => void
}

export default React.forwardRef<HTMLDivElement, DatePickerProps>(
  function DatePicker(props, ref) {
    const [calendarDate, setCalendarDate] = useState(getCurrentDate())
    const [calendarVisible, setCalendarVisible] = useState(false)
    const { selectedDate, endDate, onChange, isValid = true } = props

    const containerRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
      setCalendarDate(selectedDate || getCurrentDate())
    }, [selectedDate])

    const changeMonth = (direction: 'prev' | 'next') => {
      const diff = direction === 'next' ? 1 : -1
      const date = new Date(calendarDate)
      date.setDate(1)
      date.setMonth(calendarDate.getMonth() + diff)
      setCalendarDate(date)
    }

    const nextMonth = () => {
      changeMonth('next')
    }

    const prevMonth = () => {
      changeMonth('prev')
    }

    const hideCalendar = () => {
      setCalendarVisible(false)
      setCalendarDate(selectedDate || getCurrentDate())
    }

    const toggleCalendar = () => {
      if (calendarVisible) hideCalendar()
      else setCalendarVisible(true)
    }

    const setSelectedDate = (date: Date) => {
      setCalendarVisible(false)
      onChange(date)
    }

    const clearDate = () => {
      setCalendarVisible(false)
      onChange()
    }

    const handleBlur = (event: React.FocusEvent) => {
      const { relatedTarget } = event
      if (
        !relatedTarget ||
        !containerRef.current?.contains(relatedTarget as HTMLElement)
      ) {
        hideCalendar()
      }
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
      if (event.key === 'Escape') {
        hideCalendar()
      }
    }

    return (
      <div
        ref={node => {
          if (typeof ref === 'function') {
            ref(node)
          } else if (ref) {
            ref.current = node
          }
          containerRef.current = node
        }}
        tabIndex={0}
        className={styles.outerContainer}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
      >
        <div
          className={classNames(baseStyles.controlInput, styles.input, {
            [baseStyles.controlInputInvalid]: !isValid
          })}
          data-testid={props.name}
        >
          <button
            type='button'
            onClick={toggleCalendar}
            className={classNames(styles.pickerButton, {
              [baseStyles.placeholder]: !selectedDate
            })}
          >
            {selectedDate ? formatDate(selectedDate) : 'дд.мм.гг'}
          </button>

          <button
            type='button'
            onClick={clearDate}
            className={baseStyles.deleteButton}
          />
        </div>

        {calendarVisible && (
          <Calendar
            calendarDate={calendarDate}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            endDate={endDate}
            prevMonth={prevMonth}
            nextMonth={nextMonth}
          />
        )}
      </div>
    )
  }
)
