import classNames from 'classnames'
import React from 'react'
import { CSSTransition } from 'react-transition-group'
import styles from './Details.module.css'

type Color = 'blue' | 'default'

type ExpandButtonProps = {
  detailsVisible: boolean
  onClick: () => void
  color?: Color
}

const ExpandButton: React.FC<ExpandButtonProps> = ({
  detailsVisible,
  onClick,
  color
}) => {
  const pathProps = {
    stroke: color === 'blue' ? 'var(--blue)' : '#616161',
    strokeWidth: '2'
  }
  return (
    <button onClick={onClick} className={styles.expandButton}>
      <svg
        width='29'
        height='29'
        viewBox='0 0 29 29'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        style={{ cursor: 'pointer' }}
      >
        <title>{detailsVisible ? 'Скрыть' : 'Раскрыть'}</title>
        <path d='M29 14.0323L-7.7486e-07 14.0323' {...pathProps} />
        {!detailsVisible && (
          <path d='M14.0322 29L14.0322 -7.7486e-07' {...pathProps} />
        )}
      </svg>
    </button>
  )
}

type DetailsProps = {
  detailsVisible: boolean
  setVisible: (val: boolean) => void
  title: React.ReactNode
  color?: Color
}

const Details: React.FC<DetailsProps> = ({
  detailsVisible,
  setVisible,
  title,
  color,
  children
}) => {
  return (
    <>
      <hr className={styles.line} />
      <div className={styles.row} style={{ alignItems: 'center' }}>
        <div
          className={classNames(styles.info, {
            [styles.blue]: color === 'blue'
          })}
        >
          {title}
        </div>
        <ExpandButton
          detailsVisible={detailsVisible}
          onClick={() => setVisible(!detailsVisible)}
          color={color}
        />
      </div>
      <CSSTransition
        in={detailsVisible}
        unmountOnExit
        timeout={300}
        classNames={{
          enter: styles.detailsEnter,
          enterActive: styles.detailsEnterActive,
          exit: styles.detailsExit,
          exitActive: styles.detailsExitActive
        }}
      >
        <section>{children}</section>
      </CSSTransition>
      <hr className={styles.line} />
    </>
  )
}

export default Details
