import { useContext } from 'react'
import { useInfiniteQuery } from 'react-query'
import { AuthContext } from '../../app/authContext'
import { getTasks } from '../../shared/apiService'
import { Order, Sort } from '../../shared/models'

export function useTasks(filter: number | undefined, sort: Sort, order: Order) {
  const { handleError } = useContext(AuthContext)

  const {
    data,
    fetchNextPage,
    refetch,
    status,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isSuccess
  } = useInfiniteQuery(
    ['tasks', filter, sort, order],
    ({ pageParam = 0 }) =>
      getTasks({
        filterId: filter!,
        offset: pageParam,
        sortParam: sort,
        order
      }),
    {
      enabled: !!filter,
      onError: handleError,
      getNextPageParam: lastPage => {
        // загружать пока в ответе есть результаты
        if (lastPage.tasksList.length) {
          return lastPage.offset + lastPage.limit
        }
      }
    }
  )

  return {
    tasks: data?.pages?.flatMap(res => res.tasksList) ?? [],
    tasksStatus: status,
    loadNext: fetchNextPage,
    allLoaded: !hasNextPage,
    isLoading,
    isFetchingNextPage,
    isSuccess,
    refetch
  }
}
