import classNames from 'classnames'
import React from 'react'
import { isWeekend } from '../../../shared/dateUtils'
import styles from './DatePicker.module.css'

interface DayProps {
  day: Date
  isSelected: boolean
  isSelectable: boolean
}

export function Day(props: DayProps) {
  const className = classNames(styles.day, {
    [styles.currentMonth]: props.isSelectable,
    [styles.weekend]: props.isSelectable && isWeekend(props.day),
    [styles.selected]: props.isSelected
  })
  return (
    <button
      type='button'
      className={className}
      disabled={!props.isSelectable}
      data-date={props.isSelectable ? props.day : undefined}
    >
      {props.day.getDate()}
    </button>
  )
}
