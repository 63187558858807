import React from 'react'
import Button from '../../components/base/Button'
import DateTime from '../../components/base/DateTime'
import Editor from '../../components/base/Editor'
import FileInput from '../../components/base/FileInput'
import { validateEditorState } from '../../components/base/HtmlEditor'
import { MultiSearch } from '../../components/base/Search/'
import Select from '../../components/base/Select'
import TextArea from '../../components/base/TextArea'
import TextField from '../../components/base/TextField'
import Preview from '../../components/Preview'
import { getTimeStampInSecs } from '../../shared/dateUtils'
import {
  DescriptionType,
  MapTask,
  Priority,
  SubFormProps
} from '../../shared/models'
import {
  DateValidators,
  StringValidators,
  validateDate,
  validateString
} from '../../shared/validators'
import {
  getAuditorOptions,
  getFormFields,
  getPriorityOptions,
  initAuditors,
  initDatetime,
  initPriority
} from './formUtils'
import useDescription from './useDescription'
import { useForm } from './useForm'
import { getFormNameParam } from './utils'

interface Values {
  taskTitle: string
  releaseDatetime?: Date
  decorType: string
  format: string
  timing: string
  title: string
  selectedAuditors: { id: number; value: string }[]
  priority: Priority
  description: string
  descriptionType: DescriptionType
  offscreenText: string
  files: File[]
}

function initValues(props: SubFormProps<MapTask>): Values {
  const { taskToRepeat, restoreData, selectedProgram } = props
  const formFields = props.selectedGraphic.form_fields
  return {
    taskTitle: taskToRepeat?.taskTitle ?? restoreData?.taskTitle ?? '',
    releaseDatetime: initDatetime(
      taskToRepeat?.releaseDatetime,
      restoreData?.releaseDatetime
    ),
    decorType: taskToRepeat?.mapDesign ?? restoreData?.decorType ?? '',
    format: taskToRepeat?.mediaFormat ?? restoreData?.format ?? '',
    timing: taskToRepeat?.timing ?? restoreData?.timing ?? '',
    title: taskToRepeat?.title ?? restoreData?.title ?? '',
    selectedAuditors: initAuditors(selectedProgram, taskToRepeat, restoreData),
    priority: initPriority(taskToRepeat, restoreData, formFields),
    description: taskToRepeat?.description ?? restoreData?.description ?? '',
    descriptionType:
      taskToRepeat?.descriptionType ?? restoreData?.descriptionType ?? 'html',
    offscreenText: taskToRepeat?.narration ?? restoreData?.offscreenText ?? '',
    files: []
  }
}

function validateValues(values: Values) {
  return {
    taskTitle: validateString(values.taskTitle, [
      StringValidators.notEmpty,
      StringValidators.maxLength(50)
    ]),
    releaseDatetime: validateDate(values.releaseDatetime, [
      DateValidators.notEmpty,
      DateValidators.notPast
    ]),
    description: validateEditorState(values.description),
    decorType: validateString(values.decorType, [StringValidators.notEmpty]),
    format: validateString(values.format, [StringValidators.notEmpty]),
    timing: validateString(values.timing, [
      StringValidators.notEmpty,
      StringValidators.isNumber,
      StringValidators.isInteger,
      StringValidators.isPositive,
      StringValidators.maxValue(86400)
    ]),
    title: validateString(
      values.title,
      [StringValidators.notEmpty, StringValidators.maxLength(50)],
      !!values.title
    )
  }
}

function prepareValues(values: Values, props: SubFormProps<MapTask>): MapTask {
  const formFields = getFormFields(props)
  const data: MapTask = {
    formType: 'need-map',
    projectId: props.selectedProgram.id.toString(),
    graphicId: props.selectedGraphic.id.toString(),
    auditors: values.selectedAuditors.map(({ id }) => id),
    releaseDatetime: getTimeStampInSecs(values.releaseDatetime!),
    mapDesign: values.decorType,
    mediaFormat: values.format,
    timing: values.timing,
    taskTitle: values.taskTitle,
    ...(values.title && { title: values.title }),
    description: values.description,
    descriptionType: values.descriptionType,
    ...(values.offscreenText && { narration: values.offscreenText })
  }

  const formName = getFormNameParam()
  if (formName) data.formName = formName
  if ('priority' in formFields) data.priority = values.priority
  if (props.repeatTaskId) data.repeatTaskId = props.repeatTaskId

  return data
}

export default function MapForm(props: SubFormProps<MapTask>) {
  const {
    values,
    errors,
    setValue,
    setMultipleValues,
    isValid,
    handleSubmit,
    isLoading
  } = useForm(
    () => initValues(props),
    validateValues,
    values => props.onSubmit(prepareValues(values, props), values.files),
    props.selectedProgram.id.toString(),
    props.selectedGraphic.id.toString(),
    props.graphicsKindId
  )

  const descriptionProps = useDescription(values, setValue, setMultipleValues)

  const formFields = getFormFields(props)
  const auditorOptions = getAuditorOptions(props.selectedProgram)
  const priorityOptions = getPriorityOptions(formFields)
  const decorPreviewSrc = formFields.mapDesign?.values?.find(
    ({ id }) => values.decorType === id.toString()
  )?.image?.preview
  const decorOptions = formFields.mapDesign?.values ?? []
  const formatOptions = formFields.mediaFormat?.values ?? []

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        type='text'
        value={values.taskTitle}
        name='taskTitle'
        label='Название заявки'
        placeholder='ДТП с участием самосвала'
        onChange={event => setValue('taskTitle', event.target.value)}
        required
        isValid={isValid('taskTitle')}
        validationMessage={errors.taskTitle}
      />
      <DateTime
        label='Дата и время сдачи'
        name='release'
        value={values.releaseDatetime}
        setValue={value => setValue('releaseDatetime', value)}
        required
        isValid={isValid('releaseDatetime')}
        validationMessage={errors.releaseDatetime}
      />
      <Select
        value={values.decorType}
        name='decorType'
        label='Оформление'
        onChange={event => setValue('decorType', event.target.value)}
        isValid={isValid('decorType')}
        validationMessage={errors.decorType}
        options={decorOptions}
        required
      />
      {decorPreviewSrc && (
        <Preview src={decorPreviewSrc} alt='Превью оформления' />
      )}
      <Select
        value={values.format}
        name='setFormat'
        label='Формат итогового материала'
        onChange={event => setValue('format', event.target.value)}
        isValid={isValid('format')}
        validationMessage={errors.format}
        options={formatOptions}
        required
      />
      <TextField
        type='text'
        value={values.timing}
        name='timing'
        isValid={isValid('timing')}
        validationMessage={errors.timing}
        label='Хронометраж'
        inputMode='decimal'
        onChange={event => setValue('timing', event.target.value)}
        required
      />
      <TextField
        type='text'
        name='title'
        isValid={isValid('title')}
        validationMessage={errors.title}
        label='Заголовок'
        value={values.title}
        placeholder='Ограничение движения'
        onChange={event => setValue('title', event.target.value)}
      />
      <Editor
        {...descriptionProps}
        label='Описание заказа'
        placeholder='Все подписи и названия указать в том виде, в котором они должны быть на графике'
        required
        isValid={isValid('description')}
        validationMessage={errors.description}
      />
      <TextArea
        name='offscreenText'
        label='Закадровый текст'
        onChange={event => setValue('offscreenText', event.target.value)}
        value={values.offscreenText}
      />
      {'priority' in formFields && (
        <Select
          value={values.priority}
          name='priority'
          label='Приоритет'
          onChange={event => setValue('priority', +event.target.value)}
          options={priorityOptions}
        />
      )}
      {!!auditorOptions.length && (
        <MultiSearch
          label=' '
          options={auditorOptions}
          selected={values.selectedAuditors}
          updateSelected={value => setValue('selectedAuditors', value)}
          placeholder='Добавить наблюдателей за заказом'
        />
      )}
      <FileInput
        files={values.files}
        handleAcceptedFiles={value => setValue('files', value)}
        showAlert={props.setAlertMessage}
      />
      <Button
        name='Отправить'
        type='submit'
        status={isLoading ? 'loading' : 'enabled'}
      />
    </form>
  )
}
