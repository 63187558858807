import { useCallback, useEffect, useState } from 'react'
import { getFile } from '../../shared/apiService'

export default function useLoadFile(fileId: number) {
  const [fileUrl, setFileUrl] = useState('')

  useEffect(() => {
    return () => {
      if (fileUrl) URL.revokeObjectURL(fileUrl)
    }
  }, [fileUrl])

  const handleFileLoad = useCallback(() => {
    if (fileUrl) {
      return Promise.resolve(fileUrl)
    } else {
      return getFile(fileId)
        .then(blob => {
          const url = URL.createObjectURL(blob)
          setFileUrl(url)
          return url
        })
        .catch(console.error)
    }
  }, [fileId, fileUrl])

  return handleFileLoad
}
