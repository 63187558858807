import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { AuthContext } from '../../app/authContext'
import Button from '../../components/base/Button'
import Checkbox from '../../components/base/Checkbox'
import Editor, { useEditor } from '../../components/base/Editor'
import FileInput from '../../components/base/FileInput'
import { validateEditorState } from '../../components/base/HtmlEditor'
import {
  addComment,
  getComments,
  markCommentsAsRead,
  pullCommentActivity
} from '../../shared/apiService'
import useCallbackOnVisible from '../../shared/hooks/useCallbackOnVisible'
import { TaskData } from '../../shared/models'
import useChannels, {
  MessageData,
  SubscriptionParams
} from '../Tasks/useChannels'
import CommentView from './Comment'
import CommentSkeleton from './CommentSkeleton'
import style from './TaskPage.module.css'

function sortByDate<T extends { createdDate: number }>(a: T, b: T) {
  return a.createdDate - b.createdDate
}

export function CommentsBlock({
  task,
  setAlertMessage,
  handleError
}: {
  task: Required<TaskData>
  setAlertMessage: (msg: string) => void
  handleError: (err: Error) => void
}) {
  const [files, setFiles] = useState<File[]>([])
  const { valueType: textType, ...editorProps } = useEditor()
  const { value: commentText, setValue: setCommentText } = editorProps
  const [internal, setInternal] = useState(false)
  const [currentlyWriting, setCurrentlyWriting] =
    useState<{ id: number; name: string }>()

  const queryClient = useQueryClient()
  const { data: comments = [], isLoading: commentsLoading } = useQuery(
    ['comments', task.id],
    () => getComments(task.id),
    {
      onError: handleError,
      select: comments => comments.sort(sortByDate)
    }
  )

  const commentsMutation = useMutation(
    () => addComment(commentText, textType, task.id, files, internal),
    {
      onError: handleError,
      onSuccess: () => {
        setCommentText('')
        setFiles([])
        queryClient.invalidateQueries(['comments', task.id])
      }
    }
  )

  const handleAddComment = () => {
    commentsMutation.mutate()
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.ctrlKey && event.key === 'Enter' && isCommentValid) {
      handleAddComment()
    }
  }

  const hasUnreadComments = comments.some(comment => comment.unread)

  const commentValidationMessage = validateEditorState(commentText)
  const isCommentValid = !commentValidationMessage

  const notifyAllCommentsRead = useCallback(
    () => markCommentsAsRead(task.id).catch(handleError),
    [task.id, handleError]
  )
  const callbackRef = useCallbackOnVisible(notifyAllCommentsRead)

  const { userId, userType } = useContext(AuthContext)

  useEffect(() => {
    const interval = window.setInterval(
      () => setCurrentlyWriting(undefined),
      10000
    )
    return () => window.clearInterval(interval)
  }, [currentlyWriting])

  const handleChannelMessage = useCallback(
    (messageData: MessageData) => {
      if (messageData.command === 'answer') {
        setCurrentlyWriting(writer => {
          if (messageData.params.USER_ID !== userId) {
            return {
              id: messageData.params.USER_ID,
              name: messageData.params.NAME
            }
          }
          return writer
        })
      } else if (messageData.command === 'comment') {
        setCurrentlyWriting(writer => {
          if (writer && messageData.params.AUTHOR.ID === writer.id) {
            return undefined
          }
          return writer
        })
        if (messageData.params.AUTHOR.ID.toString() !== userId) {
          if (
            userType === 'client' &&
            messageData.params.UF.UF_IS_INTERNAL.VALUE === '1'
          ) {
            return
          }
          queryClient.invalidateQueries(['comments', task.id])
        }
      }
    },
    [queryClient, task.id, userId, userType]
  )

  const subscriptionParams = useMemo<SubscriptionParams>(
    () => ({ type: 'comments', taskId: [task.id] }),
    [task.id]
  )

  const handleEditorFocus = useCallback(async () => {
    await pullCommentActivity(task.id)
  }, [task.id])

  useChannels(handleChannelMessage, subscriptionParams)

  if (commentsLoading) return <CommentSkeleton />
  return (
    <>
      <section className={style.commentsSection}>
        {comments.map((comment, idx) => (
          <CommentView
            key={idx}
            comment={comment}
            ref={
              hasUnreadComments && idx === comments.length - 1
                ? callbackRef
                : null
            }
          />
        ))}
      </section>

      {currentlyWriting && (
        <div className={style.currentlyWriting}>
          {currentlyWriting.name} набирает сообщение...
        </div>
      )}

      {!['DECLINED', 'FINISHED'].includes(task.status.code) && (
        <section className={style.newCommentSection} onKeyDown={handleKeyDown}>
          <div className={style.addComment}>Добавить комментарий</div>
          {!commentsMutation.isLoading && (
            <>
              <Editor {...editorProps} onFocus={handleEditorFocus} topLabel />
              {userType !== 'client' && (
                <Checkbox
                  label='Внутренний комментарий'
                  checked={internal}
                  setChecked={setInternal}
                />
              )}
            </>
          )}
          <FileInput
            files={files}
            handleAcceptedFiles={setFiles}
            showAlert={setAlertMessage}
            className={style.commentFileInput}
            disabled={commentsMutation.isLoading}
          />
          <Button
            name='Отправить'
            onClick={handleAddComment}
            status={
              commentsMutation.isLoading
                ? 'loading'
                : isCommentValid
                ? 'enabled'
                : 'disabled'
            }
          />
        </section>
      )}
    </>
  )
}
