import classNames from 'classnames'
import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import useSearch from '../pages/Search/useSearch'
import styles from './SearchBar.module.css'

interface Props {
  style?: React.CSSProperties
}

const MAX_PREVIEW_RESULTS = 6

export default function SearchBar({ style }: Props) {
  const { query, setQuery, results, loading, clearQuery } = useSearch()
  const inputRef = useRef<HTMLInputElement>(null)
  const [active, setActive] = useState(false)

  const handleChange = (value: string) => {
    setQuery(value)
  }

  const reset = () => {
    clearQuery()
    setActive(false)
  }

  const handleBlur = (event: React.FocusEvent) => {
    if (
      event.relatedTarget &&
      (event.relatedTarget as HTMLElement).classList.contains(styles.result)
    ) {
      return
    } else {
      reset()
    }
  }

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape') {
      inputRef.current?.blur()
    }
  }

  return (
    <div className={styles.container} style={style}>
      <input
        ref={inputRef}
        placeholder='Поиск...'
        className={classNames(styles.search, {
          [styles.loading]: loading
        })}
        value={query}
        onChange={event => handleChange(event.target.value)}
        onFocus={() => setActive(true)}
        onBlur={handleBlur}
        onKeyUp={handleKeyUp}
      />
      {results.length > 0 && active && (
        <section className={styles.results}>
          {results.slice(0, MAX_PREVIEW_RESULTS).map(result => (
            <Link
              onClick={reset}
              tabIndex={0}
              key={result.taskId}
              to={`/tasks/${result.taskId}`}
              className={styles.result}
            >
              <span dangerouslySetInnerHTML={{ __html: result.title }} />
            </Link>
          ))}
          <Link
            onClick={reset}
            tabIndex={0}
            to={`/search?query=${query}`}
            className={styles.loadMoreButton}
          >
            Все результаты
          </Link>
        </section>
      )}
      {!results.length && active && !loading && (
        <section className={styles.results}>
          <div className={styles.result}>Нет результатов</div>
        </section>
      )}
    </div>
  )
}
