import React from 'react'
import styles from './Checkbox.module.css'

interface Props {
  /** Подпись */
  label: string
  /** Значение */
  checked: boolean
  /** Установить значение */
  setChecked: (value: boolean) => void
  /** Дополнительные стили */
  style?: React.CSSProperties
  id?: string
}

export default function Checkbox({
  label,
  checked,
  setChecked,
  style,
  id
}: Props) {
  return (
    <div className={styles.container} style={{ ...style }}>
      <label htmlFor={id} className={styles.label}>
        <input
          id={id}
          type='checkbox'
          checked={checked}
          onChange={event => setChecked(event.target.checked)}
          className={styles.input}
        />
        {label}
      </label>
    </div>
  )
}
