import React from 'react'
import Button from '../../components/base/Button'
import DateTime from '../../components/base/DateTime'
import Editor from '../../components/base/Editor'
import FileInput from '../../components/base/FileInput'
import { validateEditorState } from '../../components/base/HtmlEditor'
import { MultiSearch } from '../../components/base/Search/'
import Select from '../../components/base/Select'
import TextField from '../../components/base/TextField'
import { getTimeStampInSecs } from '../../shared/dateUtils'
import {
  DescriptionType,
  DesignTask,
  Priority,
  SubFormProps
} from '../../shared/models'
import {
  DateValidators,
  StringValidators,
  validateDate,
  validateString
} from '../../shared/validators'
import {
  getAuditorOptions,
  getFormFields,
  getPriorityOptions,
  initAuditors,
  initDatetime,
  initPriority
} from './formUtils'
import useDescription from './useDescription'
import { useForm } from './useForm'

interface Values {
  taskTitle: string
  sketchDatetime?: Date
  animaticDatetime?: Date
  releaseDatetime?: Date
  selectedAuditors: { id: number; value: string }[]
  priority: Priority
  description: string
  descriptionType: DescriptionType
  files: File[]
}

function initValues(props: SubFormProps<DesignTask>): Values {
  const { taskToRepeat, restoreData, selectedProgram } = props
  const formFields = props.selectedGraphic.form_fields
  return {
    taskTitle: taskToRepeat?.taskTitle ?? restoreData?.taskTitle ?? '',
    sketchDatetime: initDatetime(
      taskToRepeat?.sketchDatetime,
      restoreData?.sketchDatetime
    ),
    animaticDatetime: initDatetime(
      taskToRepeat?.animaticDatetime,
      restoreData?.animaticDatetime
    ),
    releaseDatetime: initDatetime(
      taskToRepeat?.releaseDatetime,
      restoreData?.releaseDatetime
    ),
    selectedAuditors: initAuditors(selectedProgram, taskToRepeat, restoreData),
    priority: initPriority(taskToRepeat, restoreData, formFields),
    description: taskToRepeat?.description ?? restoreData?.description ?? '',
    descriptionType:
      taskToRepeat?.descriptionType ?? restoreData?.descriptionType ?? 'html',
    files: []
  }
}

function validateValues(values: Values) {
  return {
    taskTitle: validateString(values.taskTitle, [
      StringValidators.notEmpty,
      StringValidators.maxLength(50)
    ]),
    description: validateEditorState(values.description),
    sketchDatetime: validateDate(values.sketchDatetime, [
      DateValidators.notPast,
      DateValidators.before(
        values.releaseDatetime,
        'Не должно быть позже даты сдачи'
      )
    ]),
    animaticDatetime: validateDate(values.animaticDatetime, [
      DateValidators.notPast,
      DateValidators.before(
        values.releaseDatetime,
        'Не должно быть позже даты сдачи'
      )
    ]),
    releaseDatetime: validateDate(values.releaseDatetime, [
      DateValidators.notEmpty,
      DateValidators.notPast
    ])
  }
}

function prepareValues(
  values: Values,
  props: SubFormProps<DesignTask>
): DesignTask {
  const formFields = getFormFields(props)
  const data: DesignTask = {
    formType: 'design-develop',
    projectId: props.selectedProgram.id.toString(),
    graphicId: props.selectedGraphic.id.toString(),
    taskTitle: values.taskTitle,
    description: values.description,
    descriptionType: values.descriptionType,
    releaseDatetime: getTimeStampInSecs(values.releaseDatetime!),
    auditors: values.selectedAuditors.map(({ id }) => id)
  }
  if (values.sketchDatetime) {
    data.sketchDatetime = getTimeStampInSecs(values.sketchDatetime)
  }
  if (values.animaticDatetime) {
    data.animaticDatetime = getTimeStampInSecs(values.animaticDatetime)
  }
  if ('priority' in formFields) data.priority = values.priority
  if (props.formName) data.formName = props.formName
  if (props.additionalDescr) data.additionalDescr = props.additionalDescr
  if (props.repeatTaskId) data.repeatTaskId = props.repeatTaskId

  return data
}

export default function DesignForm(props: SubFormProps<DesignTask>) {
  const {
    values,
    errors,
    isValid,
    setValue,
    setMultipleValues,
    isLoading,
    handleSubmit
  } = useForm(
    () => initValues(props),
    validateValues,
    values => props.onSubmit(prepareValues(values, props), values.files),
    props.selectedProgram.id.toString(),
    props.selectedGraphic.id.toString(),
    props.graphicsKindId
  )

  const descriptionProps = useDescription(values, setValue, setMultipleValues)

  const formFields = getFormFields(props)
  const auditorOptions = getAuditorOptions(props.selectedProgram)
  const priorityOptions = getPriorityOptions(formFields)

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        value={values.taskTitle}
        onChange={event => setValue('taskTitle', event.target.value)}
        type='text'
        name='taskTitle'
        label='Название заявки'
        placeholder='Новая шапка для ПИС'
        required
        isValid={isValid('taskTitle')}
        validationMessage={errors.taskTitle}
      />
      <DateTime
        label='Согласование эскизов'
        name='sketch'
        value={values.sketchDatetime}
        setValue={value => setValue('sketchDatetime', value)}
        endDate={values.releaseDatetime}
        isValid={isValid('sketchDatetime')}
        validationMessage={errors.sketchDatetime}
      />
      <DateTime
        label='Согласование аниматика'
        name='animatic'
        value={values.animaticDatetime}
        setValue={value => setValue('animaticDatetime', value)}
        endDate={values.releaseDatetime}
        isValid={isValid('animaticDatetime')}
        validationMessage={errors.animaticDatetime}
      />
      <DateTime
        label='Дата сдачи'
        name='release'
        value={values.releaseDatetime}
        setValue={value => setValue('releaseDatetime', value)}
        required
        isValid={isValid('releaseDatetime')}
        validationMessage={errors.releaseDatetime}
      />
      <Editor
        {...descriptionProps}
        label='Описание'
        placeholder='Не забудьте указать все необходимое, иначе разработка будет длиться дольше'
        required
        isValid={isValid('description')}
        validationMessage={errors.description}
      />
      {'priority' in formFields && (
        <Select
          value={values.priority}
          name='priority'
          label='Приоритет'
          onChange={event => setValue('priority', +event.target.value)}
          options={priorityOptions}
        />
      )}
      {!!auditorOptions.length && (
        <MultiSearch
          label=' '
          options={auditorOptions}
          selected={values.selectedAuditors}
          updateSelected={value => setValue('selectedAuditors', value)}
          placeholder='Добавить наблюдателей за заказом'
        />
      )}
      <FileInput
        files={values.files}
        handleAcceptedFiles={files => setValue('files', files)}
        showAlert={props.setAlertMessage}
      />
      <Button
        name='Отправить'
        type='submit'
        status={isLoading ? 'loading' : 'enabled'}
      />
    </form>
  )
}
