import React, { useEffect } from 'react'
import { createPortal } from 'react-dom'
import style from './Modal.module.css'

type Props = {
  hideModal: () => void
}

const Modal: React.FunctionComponent<Props> = ({ hideModal, children }) => {
  useEffect(() => {
    const toggleModal = () => {
      document.body.classList.toggle(style.modalOpen)
      document
        .getElementById('root')
        ?.firstElementChild?.classList.toggle(style.blurred)
    }
    toggleModal()
    return toggleModal
  }, [])

  return createPortal(
    <section className={style.container}>
      <div className={style.modal}>
        <div className={style.buttonContainer}>
          <button className={style.closeButton} onClick={hideModal} />
        </div>
        {children}
      </div>
    </section>,
    document.getElementById('root')!
  )
}

export default Modal
