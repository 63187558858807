import { useCallback, useEffect } from 'react'
import useChannels, { MessageData } from '../pages/Tasks/useChannels'

const SUBSCRIPTION_TYPE: { type: 'tasks' } = { type: 'tasks' }

export default function useNotifications() {
  useEffect(() => {
    ;(async () => {
      if (
        'Notification' in window &&
        Notification.permission !== 'granted' &&
        Notification.permission !== 'denied'
      ) {
        await Notification.requestPermission()
      }
    })()
  }, [])

  const messageHandler = useCallback((msgData: MessageData) => {
    const createNotification = (userName: string, taskId: string) => {
      const title = 'Новый комментарий'
      const text = `${userName} добавил комментарий в задачу ${taskId}`
      const notification = new Notification(title, { body: text })
      notification.onclick = event => {
        event.preventDefault()
        const baseUrl =
          process.env.NODE_ENV === 'production' ? '/customer_app' : ''
        window.open(`${baseUrl}/tasks/${taskId}`, '_blank')
      }
    }

    if (
      msgData.command === 'notify' &&
      msgData.params.type === '2' &&
      'Notification' in window
    ) {
      const match = /\/tasks\/task\/view\/(\d+)/.exec(msgData.params.text)
      const taskId = match?.[1]
      const userName = msgData.params.userName

      if (Notification.permission === 'granted') {
        createNotification(userName, taskId!)
      }
    }
  }, [])

  useChannels(messageHandler, SUBSCRIPTION_TYPE)
}
