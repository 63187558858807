import { getDateFrom, isTodayOrFuture } from '../../shared/dateUtils'
import {
  FormFields,
  IProject,
  Priority,
  SubFormProps,
  Task
} from '../../shared/models'
import { getSavedDatetime } from './utils'

export function initDatetime(
  repeatTaskTimeStamp?: number,
  restoreDataIsoString?: string,
  sessionKey?: string
) {
  const date = repeatTaskTimeStamp
    ? getDateFrom(repeatTaskTimeStamp)
    : restoreDataIsoString
    ? new Date(restoreDataIsoString)
    : sessionKey
    ? getSavedDatetime(sessionKey)
    : undefined
  return isTodayOrFuture(date) ? date : undefined
}

export function initPriority(
  taskToRepeat?: { priority?: Priority },
  restoreData?: { priority?: Priority },
  formFields?: FormFields
) {
  return (
    taskToRepeat?.priority ??
    restoreData?.priority ??
    (formFields?.priority?.default_value
      ? parseInt(formFields.priority.default_value)
      : Priority.NORMAL)
  )
}

export function initAuditors(
  selectedProgram: IProject,
  taskToRepeat?: { auditors: number[] },
  restoreData?: { selectedAuditors?: { id: number; value: string }[] }
) {
  const auditors = Object.values(selectedProgram.auditors).map(
    (auditor: any) => ({
      id: auditor.id,
      value: auditor.formatted_name
    })
  )
  return taskToRepeat
    ? auditors.filter(({ id }) => taskToRepeat?.auditors.includes(id))
    : restoreData?.selectedAuditors ?? []
}

export function getFormFields<T extends Task>(props: SubFormProps<T>) {
  return props.selectedGraphic.form_fields ?? {}
}

export function getPriorityOptions(formFields: FormFields) {
  return formFields.priority?.values ?? []
}

export function getAuditorOptions(selectedProgram: IProject) {
  return Object.values(selectedProgram.auditors).map((auditor: any) => ({
    id: auditor.id,
    value: auditor.formatted_name
  }))
}

export function getProgramDesignOptions(formFields: FormFields) {
  return (
    formFields.programDesign?.values?.map(design => ({
      id: design.id,
      value: design.name
    })) ?? []
  )
}

export function getLocationOptions(formFields: FormFields) {
  return formFields.locationOfGraphics?.values ?? []
}
