import classNames from 'classnames'
import React, { useContext } from 'react'
import { AuthContext } from '../../app/authContext'
import icon from '../../icons/private.svg'
import { formatDateRelative, getDateFrom } from '../../shared/dateUtils'
import { Comment } from '../../shared/models'
import styles from './Comment.module.css'
import DescriptionHtml from './DescriptionHtml'
import FileList from './FileList'

interface Props {
  comment: Comment
}

export default React.forwardRef<HTMLDivElement, Props>(function CommentView(
  { comment },
  ref
) {
  const { userName } = useContext(AuthContext)
  const isUserComment = userName === comment.author.formatted_name
  const avatarClass = classNames(styles.avatar, {
    [styles.userComment]: isUserComment
  })
  const attachedFiles = Object.values(comment.description.attachedFiles)

  return (
    <article className={styles.item}>
      <section className={styles.container}>
        <div ref={ref} className={avatarClass}>
          {comment.author.formatted_name[0]}
        </div>
        <div>
          <div className={styles.info}>
            {comment.internal && (
              <img
                src={icon}
                alt='Внутренний комментарий'
                title='Внутренний комментарий'
                className={styles.internalCommentImage}
              />
            )}
            {formatDateRelative(getDateFrom(comment.createdDate))},{' '}
            {comment.author.formatted_name}
          </div>
          <DescriptionHtml
            description={comment.description}
            className={
              comment.internal ? styles.commentInternal : styles.comment
            }
          />
          <div
            className={
              comment.internal ? styles.triangleInternal : styles.triangle
            }
          />
        </div>
      </section>
      {attachedFiles.length > 0 && (
        <FileList files={attachedFiles} className={styles.attachedFiles} />
      )}
    </article>
  )
})
