import classNames from 'classnames'
import React from 'react'
import spinnerIcon from '../../icons/spinner.svg'
import styles from './Spinner.module.css'

interface Props {
  className?: string
}

export default function Spinner({ className }: Props) {
  return (
    <svg
      viewBox='0 0 128 128'
      className={classNames(styles.spinner, className)}
    >
      <image xlinkHref={spinnerIcon} />
    </svg>
  )
}
