import React from 'react'

export const AuthContext = React.createContext({
  authenticated: false,
  logout: () => {},
  userId: '',
  userName: '',
  userType: '',
  catalogUrl: '',
  logo: 'none',
  alertMessage: '',
  setAlertMessage: (message: string) => {},
  setAlert: (message: string) => {},
  handleError: (err: Error) => {}
})
