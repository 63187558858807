import * as Sentry from '@sentry/browser'

function getUrlParam(param: string) {
  const searchParams = new URLSearchParams(document.location.search)
  return searchParams.get(param)
}

export function getGraphicIdParam() {
  const graphicId = getUrlParam('graphicId')
  return graphicId ? +graphicId : null
}

export function getFormNameParam() {
  return getUrlParam('formName')
}

export function getDescriptionParam() {
  let additionalDescr = getUrlParam('additionalDescr')
  if (additionalDescr) {
    try {
      additionalDescr = decodeURIComponent(additionalDescr)
    } catch (error) {
      Sentry.captureException(error)
    }
  }
  return additionalDescr
}

export function saveValueForAutoComplete(
  name: string,
  value: string,
  maxValues = 5
) {
  const values = sessionStorage.getItem(name)
  let options = values ? values.split(',') : []

  options = options.filter(option => option !== value)
  if (options.length >= maxValues) {
    options = options.slice(1 - maxValues)
  }
  options.push(value)
  sessionStorage.setItem(name, options.join(','))
}

export function getSavedValue(name: string) {
  const values = sessionStorage.getItem(name)
  const options = values ? values.split(',') : []
  return options.pop() || ''
}

export function saveDateForAutoComplete(name: string, date: Date) {
  sessionStorage.setItem(name, date.toISOString())
}

export function getSavedDatetime(name: string) {
  const isoDatetime = sessionStorage.getItem(name)
  if (isoDatetime) {
    return new Date(isoDatetime)
  }
}

export function cleanString(str: string) {
  return str.trim()
}
