import styles from './SideMenuSkeleton.module.css'

export default function SideMenuSkeleton() {
  return (
    <div className={styles.container}>
      <div className={styles.button} />
      {Array(5)
        .fill(null)
        .map((_, idx) => (
          <div key={idx} className={styles.filter} />
        ))}
    </div>
  )
}
