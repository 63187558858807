import { useContext } from 'react'
import ReactHtmlParser from 'html-react-parser'
import { useQuery } from 'react-query'
import { AuthContext } from '../../app/authContext'
import {
  getNotifications,
  getUnreadNotificationCount,
  getUnreadNotifications
} from '../apiService'
import { formatDate } from '../utils'

export function useUnreadNotificationCount() {
  const { authenticated } = useContext(AuthContext)
  return useQuery('unreadNotificationCount', getUnreadNotificationCount, {
    refetchOnWindowFocus: true,
    enabled: authenticated
  })
}

export function useUnreadNotifications(perPage: number = 20) {
  const { authenticated } = useContext(AuthContext)

  return useQuery('unreadNotifications', getUnreadNotifications, {
    refetchOnWindowFocus: false,
    refetchInterval: false,
    initialData: [],
    enabled: authenticated,
    select: data => {
      let arr = []
      for (let i = 0; i < perPage; i++) {
        if (data[i]) {
          let parsedHtml = ReactHtmlParser(data[i].text)
          arr.push({
            html: parsedHtml,
            id: data[i].id,
            date: formatDate(data[i].date)
          })
        }
      }
      return arr
    }
  })
}

export function useNotifications(page: number) {
  return useQuery(
    ['notifications', page],
    async () => {
      return await getNotifications(page).then(response => response)
    },
    {
      select: data => {
        const items: {
          html: any
          id: string
          read: 'Y' | 'N'
          date: any
        }[] = []
        for (let i = 0; i < data.length; i++) {
          let parsedHtml = ReactHtmlParser(data[i].text)
          items.push({
            html: parsedHtml,
            id: data[i].id,
            read: data[i].read,
            date: formatDate(data[i].date)
          })
        }
        return items
      }
    }
  )
}
