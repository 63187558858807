import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { AuthContext } from '../../app/authContext'
import Button from '../../components/base/Button'
import Spinner from '../../components/base/Spinner'
import Modal from '../../components/Modal'
import {
  finishTask,
  getTaskWorks,
  gotoOldVersionPage,
  pauseTask,
  progressTask,
  saveTaskWorks,
  TaskWorkItem
} from '../../shared/apiService'
import { TaskData } from '../../shared/models'
import style from './TaskPage.module.css'

type ModalState = 'idle' | 'loading' | 'confirm' | 'redirect'

export function StatusModal({
  task,
  hideModal
}: {
  task: TaskData
  hideModal: () => void
}) {
  const [state, setState] = useState<ModalState>('idle')
  const [workItems, setWorkItems] = useState<string[]>([])
  const [failReasons, setFailReasons] = useState<string[]>([])
  const [redirectUrl, setRedirectUrl] = useState('')

  const { handleError } = useContext(AuthContext)

  const queryClient = useQueryClient()
  const queryKey = ['task', task.id.toString()]

  const close = () => {
    setState('idle')
    hideModal()
  }

  const gotoOldPage = (url: string) => {
    setState('loading')
    gotoOldVersionPage(url).catch(err => {
      handleError(err)
      close()
    })
  }

  const handleProgress = () => {
    setState('loading')
    progressTask(task.id)
      .then(statusUpdateResult => {
        if (statusUpdateResult.status === 'success') {
          return queryClient.invalidateQueries(queryKey)
        } else {
          throw new Error('Невозможно сменить статус задачи')
        }
      })
      .catch(handleError)
      .finally(close)
  }

  const handlePause = () => {
    setState('loading')
    pauseTask(task.id)
      .then(statusUpdateResult => {
        if (statusUpdateResult.status === 'success') {
          return queryClient.invalidateQueries(queryKey)
        } else {
          throw new Error('Невозможно сменить статус задачи')
        }
      })
      .catch(handleError)
      .finally(close)
  }

  const handleFinish = (withDefaultWorks = false) => {
    setState('loading')
    finishTask(task.id, withDefaultWorks)
      .then(statusUpdateResult => {
        switch (statusUpdateResult.status) {
          case 'success':
            close()
            return queryClient.invalidateQueries(queryKey)
          case 'confirm_works':
            setState('confirm')
            setWorkItems(statusUpdateResult.result.map(item => item.name))
            break
          case 'redirect_to_bxpro':
            setState('redirect')
            setRedirectUrl(statusUpdateResult.result.url)
            if (statusUpdateResult.fail_reasons) {
              setFailReasons(
                statusUpdateResult.fail_reasons.map(reason => reason.message)
              )
            }
            break
          default:
            throw new Error('Неизвестный результат')
        }
      })
      .catch(err => {
        handleError(err)
        close()
      })
  }

  return (
    <Modal hideModal={hideModal}>
      <div className={style.modalContent}>
        {state === 'loading' && <Spinner className={style.spinner} />}
        {state === 'confirm' && (
          <>
            <span>Завершить задачу с зарегистрированными видами работ:</span>
            <ul>
              {workItems.map(item => (
                <li key={item} dangerouslySetInnerHTML={{ __html: item }} />
              ))}
            </ul>
            <br />
            <div style={{ display: 'flex' }}>
              <Button
                name='Отмена'
                className={style.changeStatusButton}
                style={{ minWidth: 150, marginRight: 10 }}
                onClick={close}
              />
              <Button
                name='Завершить'
                className={style.finishTaskButton}
                style={{ minWidth: 150 }}
                onClick={() => handleFinish(true)}
              />
            </div>
          </>
        )}
        {state === 'redirect' && (
          <>
            <span>Задачу нельзя завершить:</span>
            <ul>
              {failReasons.map(reason => (
                <li key={reason} dangerouslySetInnerHTML={{ __html: reason }} />
              ))}
            </ul>
            <br />
            <span>
              Внесите исправления здесь или перейдите на{' '}
              <button
                className={style.buttonLink}
                onClick={() => gotoOldPage(redirectUrl)}
              >
                полную версию сайта
              </button>
            </span>
          </>
        )}
        {state === 'idle' && (
          <>
            <Button
              name='Начать выполнение'
              className={style.changeStatusButton}
              onClick={handleProgress}
              status={task.canProgress ? 'enabled' : 'disabled'}
            />
            <br />
            <Button
              name='Приостановить'
              className={style.changeStatusButton}
              onClick={handlePause}
              status={task.canPause ? 'enabled' : 'disabled'}
            />
            <br />
            <Button
              name='Завершить'
              className={style.finishTaskButton}
              onClick={() => handleFinish()}
              status={task.canFinish ? 'enabled' : 'disabled'}
            />
          </>
        )}
      </div>
    </Modal>
  )
}

export function WorkRegistrationModal({
  taskId,
  handleError,
  hideModal
}: {
  taskId: number
  handleError: (err: Error) => void
  hideModal: () => void
}) {
  const [state, setState] = useState<
    'loading' | 'idle' | 'redirect' | 'registered'
  >('loading')
  const [workItems, setWorkItems] = useState<TaskWorkItem[]>([])

  const { userId } = useContext(AuthContext)

  const close = useCallback(() => {
    setState('idle')
    hideModal()
  }, [hideModal])

  const gotoOldPage = () => {
    setState('loading')
    gotoOldVersionPage(
      `/company/personal/user/${userId}/tasks/task/view/${taskId}/`
    ).catch(err => {
      handleError(err)
      close()
    })
  }

  const saveWorks = () => {
    setState('loading')
    saveTaskWorks(taskId)
      .then(() => {
        setState('registered')
      })
      .catch(err => {
        handleError(err)
        close()
      })
  }

  useEffect(() => {
    getTaskWorks(taskId)
      .then(workResult => {
        if (workResult.status === 'success') {
          setWorkItems(workResult.result)
          setState('idle')
        } else {
          setState('redirect')
        }
      })
      .catch(err => {
        handleError(err)
        close()
      })
  }, [close, handleError, taskId])

  return (
    <Modal hideModal={hideModal}>
      <div className={style.modalContent}>
        {state === 'loading' && <Spinner className={style.spinner} />}
        {state === 'idle' && (
          <>
            <span>Список работ:</span>
            <ul>
              {workItems.map(item => (
                <li
                  key={item.id}
                  dangerouslySetInnerHTML={{
                    __html: `${item.name} (${item.value})`
                  }}
                />
              ))}
            </ul>
            <span>
              Чтобы изменить виды работ,{' '}
              <button className={style.buttonLink} onClick={gotoOldPage}>
                перейдите на полную версию сайта
              </button>
            </span>
            <div style={{ marginTop: 20 }}>
              <Button
                name='Отмена'
                className={style.changeStatusButton}
                style={{ minWidth: 120, marginBottom: 10 }}
                onClick={close}
              />
              <Button
                name='Зарегистрировать'
                className={style.finishTaskButton}
                style={{ minWidth: 120 }}
                onClick={saveWorks}
              />
            </div>
          </>
        )}
        {state === 'redirect' && (
          <>
            <span>Нет работ по умолчанию.</span>
            <br />
            <span>
              Зарегистрировать работы можно{' '}
              <button className={style.buttonLink} onClick={gotoOldPage}>
                на полной версии сайта
              </button>
            </span>
          </>
        )}
        {state === 'registered' && <span>Работы зарегистрированы!</span>}
      </div>
    </Modal>
  )
}
