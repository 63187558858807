import React from 'react'
import { formatDateRelative, getDateFrom } from '../../shared/dateUtils'
import { SearchTaskResult } from '../../shared/models'
import style from './SearchResult.module.css'

export function SearchResult({ result }: { result: SearchTaskResult }) {
  return (
    <article className={style.task}>
      <div>
        <div
          className={style.taskTitle}
          dangerouslySetInnerHTML={{ __html: result.title }}
        />
        <div
          className={style.taskDescription}
          dangerouslySetInnerHTML={{ __html: result.description }}
        />
      </div>
      <div className={style.taskDateChanged}>
        {result.dateChanged &&
          formatDateRelative(getDateFrom(result.dateChanged), true)}
      </div>
    </article>
  )
}
