import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { isMobileDevice } from '../../../shared/utils'
import baseStyles from '../Base.module.css'
import InputContainer from '../BaseInput/InputContainer'
import Dropdown from './Dropdown'
import styles from './Search.module.css'
import { BaseSearchProps, Option } from './types'
import { useAvailableOptions } from './useAvailableOptions'

interface SearchProps extends BaseSearchProps {
  selected?: Option
  updateSelected: (selected?: Option) => void
  disableReset?: boolean
}

export default function Search(props: SearchProps) {
  const {
    options,
    placeholder,
    selected,
    updateSelected,
    disabled,
    ...baseProps
  } = props

  const [active, setActive] = useState(false)
  const [query, setQuery] = useState('')

  const containerRef = useRef<HTMLDivElement>(null!)
  const inputRef = useRef<HTMLInputElement>(null!)

  const initialRender = useRef(true)

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false
    } else {
      if (!active) {
        inputRef.current.blur()
        setQuery('')
      }
    }
  }, [active])

  const { availableOptions, isLoadingOptions } = useAvailableOptions(
    query,
    props.options,
    props.selected
  )

  const handleContainerClick = () => {
    if (props.disabled) return
    setActive(true)
    // focus on ios safari: https://stackoverflow.com/a/16601288
    inputRef.current.focus()
    if (isMobileDevice()) {
      // to avoid jitter
      setTimeout(() => {
        containerRef.current.scrollIntoView({ block: 'start' })
      }, 100)
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
  }

  const handleBlur = (event: React.FocusEvent) => {
    const { relatedTarget } = event
    const container = containerRef.current

    if (relatedTarget && (relatedTarget as Node).parentElement === container) {
      inputRef.current.focus()
    } else {
      setActive(false)
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      setActive(false)
    }
  }

  const handleItemSelect = (option: Option) => {
    props.updateSelected(option)
    setActive(false)
  }

  const handleReset = (event: React.SyntheticEvent) => {
    event.stopPropagation()
    props.updateSelected()
    setActive(false)
  }

  return (
    <InputContainer {...baseProps}>
      <div
        ref={containerRef}
        tabIndex={-1}
        className={classNames(baseStyles.controlInput, styles.singleSearch, {
          [styles['singleSearch--selected']]: props.selected,
          [styles['singleSearch--disabled']]: props.disabled
        })}
        onClick={handleContainerClick}
      >
        <input
          ref={inputRef}
          value={query}
          placeholder={props.selected?.value ?? props.placeholder}
          id={props.id}
          className={classNames(baseStyles.controlInput, styles.queryInput, {
            [styles['queryInput--selected']]: props.selected
          })}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          disabled={props.disabled}
        />

        {active && (
          <Dropdown
            options={availableOptions}
            isLoadingOptions={isLoadingOptions}
            handleClick={handleItemSelect}
          />
        )}

        {!props.disableReset && props.selected && (
          <button
            type='button'
            className={styles.resetButton}
            onClick={handleReset}
            disabled={props.disabled}
          />
        )}
      </div>
    </InputContainer>
  )
}
