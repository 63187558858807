import classNames from 'classnames'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useMediaQuery } from 'react-responsive'
import { useHistory, useParams } from 'react-router-dom'
import { AuthContext } from '../../app/authContext'
import BackButton from '../../components/BackButton'
import Button, { ButtonStatus } from '../../components/base/Button'
import {
  cancelTask,
  finishTask,
  getAuditorsList,
  getResponsibleUsers,
  getTask
} from '../../shared/apiService'
import useClearAlertOnUnmount from '../../shared/hooks/useClearAlertOnUnmount'
import useChannels, {
  MessageData,
  SubscriptionParams
} from '../Tasks/useChannels'
import { useTaskFilters } from '../Tasks/useFilters'
import { CommentsBlock } from './CommentsBlock'
import ContentSkeleton from './ContentSkeleton'
import DescriptionHtml from './DescriptionHtml'
import DetailsBlock from './DetailsBlock'
import FileList from './FileList'
import MainInfoBlock from './MainInfoBlock'
import { StatusModal, WorkRegistrationModal } from './Modals'
import SideMenu from './SideMenu'
import style from './TaskPage.module.css'

export default function TaskPage() {
  const { id } = useParams<{ id?: string }>()
  const history = useHistory()
  const { handleError, userType } = useContext(AuthContext)
  const { filters, filter, setFilter } = useTaskFilters()
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' })
  useClearAlertOnUnmount()

  const {
    data: task,
    isLoading: taskLoading,
    refetch
  } = useQuery(['task', id], () => getTask(+id!), {
    enabled: !!id,
    onError: handleError
  })

  const { isLoading: auditorsLoading, data: availableAuditors = [] } = useQuery(
    ['auditors', id],
    () => getAuditorsList(task!.clientProjectId),
    { enabled: !!task, onError: handleError }
  )

  const { data: responsibleUsers = [] } = useQuery(
    'responsibleUsers',
    getResponsibleUsers,
    { enabled: !!userType && userType !== 'client', onError: handleError }
  )

  const [finishButtonStatus, setFinishButtonStatus] =
    useState<ButtonStatus>('enabled')
  const [cancelButtonStatus, setCancelButtonStatus] =
    useState<ButtonStatus>('enabled')
  const [statusModalVisible, setStatusModalVisible] = useState(false)
  const [workModalVisible, setWorkModalVisible] = useState(false)

  const handleFinishTask = () => {
    if (!id) return
    setFinishButtonStatus('loading')
    finishTask(+id)
      .then(statusUpdateResult => {
        if (statusUpdateResult.status === 'success') {
          setFinishButtonStatus('done')
          return refetch()
        } else {
          setFinishButtonStatus('enabled')
          handleError(new Error('Не удалось подтвердить завершение'))
        }
      })
      .catch(err => {
        setFinishButtonStatus('enabled')
        handleError(err)
      })
  }

  const handleRepeatTask = () => {
    history.push(`/order?repeatTaskId=${id}`)
  }

  const handleCancelTask = () => {
    if (!id) return
    setCancelButtonStatus('loading')
    cancelTask(+id)
      .then(statusUpdateResult => {
        if (statusUpdateResult.status === 'success') {
          return refetch()
        } else {
          setCancelButtonStatus('enabled')
          handleError(new Error('Не удалось отменить задачу'))
        }
      })
      .catch(err => {
        setCancelButtonStatus('enabled')
        handleError(err)
      })
  }

  const handleChannelMessage = useCallback(
    (msgData: MessageData) => {
      if (msgData.command === 'task_status_change') {
        refetch()
      }
    },
    [refetch]
  )

  const subscriptionParams = useMemo<SubscriptionParams>(
    () => ({ type: 'tasks' }),
    []
  )

  useChannels(handleChannelMessage, subscriptionParams)

  if (taskLoading) return <ContentSkeleton />
  if (!task) return null
  return (
    <div
      className={classNames(style.pageContainer, {
        [style.pageContainerMobile]: !isDesktop
      })}
    >
      {isDesktop && (
        <SideMenu filter={filter} setFilter={setFilter} filters={filters} />
      )}
      <section
        className={classNames(style.workspace, {
          [style.workspaceMobile]: !isDesktop
        })}
      >
        <BackButton to='/tasks' />
        <MainInfoBlock
          task={task}
          showStatusModal={() => setStatusModalVisible(true)}
        />
        <DetailsBlock
          task={task}
          availableAuditors={availableAuditors}
          auditorsLoading={auditorsLoading}
          responsibleUsers={responsibleUsers}
          showWorkModal={() => setWorkModalVisible(true)}
        />
        <div className={style.buttonContainer}>
          {task.canConfirmFinish && (
            <Button
              name={
                finishButtonStatus === 'done'
                  ? 'Подтверждено'
                  : 'Подтвердить выполнение'
              }
              onClick={handleFinishTask}
              status={finishButtonStatus}
            />
          )}
          {task.canRepeatTask && (
            <Button
              name={
                userType === 'client' ? 'Повторить заказ' : 'Повторить задачу'
              }
              onClick={handleRepeatTask}
            />
          )}
          {task.canCancel && (
            <Button
              name={
                userType === 'client' ? 'Отменить заказ' : 'Отменить задачу'
              }
              onClick={handleCancelTask}
              className={style.cancelTaskButton}
              status={cancelButtonStatus}
            />
          )}
        </div>
        <DescriptionHtml
          description={task.description}
          clearBlockquote={false}
        />
        <FileList
          files={Object.values(task.description.attachedFiles)}
          className={style.attachedFiles}
        />
        <CommentsBlock
          task={task}
          setAlertMessage={msg => handleError(new Error(msg))}
          handleError={handleError}
        />
      </section>
      {statusModalVisible && (
        <StatusModal
          task={task}
          hideModal={() => setStatusModalVisible(false)}
        />
      )}
      {workModalVisible && (
        <WorkRegistrationModal
          taskId={task.id}
          hideModal={() => setWorkModalVisible(false)}
          handleError={handleError}
        />
      )}
    </div>
  )
}
