import React, { useContext } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Redirect, Route } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import ClientTaskForm from '../pages/AddTask/ClientTaskForm'
import EmployeeTaskForm from '../pages/AddTask/EmployeeTaskForm'
import FormErrorBoundary from '../pages/AddTask/FormErrorBoundary'
import NotificationList from '../pages/Notification/NotificationList'
import SearchPage from '../pages/Search/SearchPage'
import TaskList from '../pages/Tasks/List/TaskList'
import TaskTable from '../pages/Tasks/Table/TaskTable'
import TaskPage from '../pages/ViewTask'
import { AuthContext } from './authContext'
import useNotifications from './useNotifications'

export default function AuthenticatedRoutes() {
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' })
  const { userType } = useContext(AuthContext)
  useNotifications()

  return (
    <>
      <Route exact path='/search'>
        <SearchPage />
      </Route>
      <Route exact path='/tasks/:id'>
        {({ match }) => (
          <CSSTransition
            in={match !== null}
            timeout={300}
            classNames='page'
            unmountOnExit
          >
            <div className='page'>
              <TaskPage />
            </div>
          </CSSTransition>
        )}
      </Route>
      <Route exact path='/tasks'>
        {isDesktop ? <TaskTable /> : <TaskList />}
      </Route>
      <Route exact path='/notification'>
        <NotificationList />
      </Route>
      <Route exact path='/order'>
        {userType === 'client' ? (
          <FormErrorBoundary>
            <ClientTaskForm />
          </FormErrorBoundary>
        ) : userType === 'employee' || userType === 'admin' ? (
          <EmployeeTaskForm />
        ) : null}
      </Route>
      <Route
        exact
        path='/'
        render={({ location }) => {
          if (location.search) {
            return <Redirect to={`/order/${location.search}`} />
          } else {
            return <Redirect to={{ pathname: '/tasks' }} />
          }
        }}
      />
    </>
  )
}
