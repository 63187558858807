import React, { useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { AuthContext } from '../../app/authContext'
import Button from '../../components/base/Button'
import { TaskFilter } from '../../shared/models'
import styles from './SideMenu.module.css'

interface Props {
  filter: number | undefined
  setFilter: (filter: number) => void
  filters: TaskFilter[]
}

export default function SideMenu({ filter, setFilter, filters }: Props) {
  const history = useHistory()
  const location = useLocation()
  const { userType } = useContext(AuthContext)

  const handleFilterChange = (filterId: number) => {
    setFilter(filterId)
    if (location.pathname !== '/tasks') {
      history.push('/tasks')
    }
  }

  return (
    <aside className={styles.sidebar}>
      <Button
        name={userType === 'client' ? 'Сделать заказ' : 'Поставить задачу'}
        onClick={() => history.push('/order')}
        className={styles.button}
      />
      {filters.map(filterItem => (
        <button
          key={filterItem.id}
          onClick={() => handleFilterChange(filterItem.id)}
          className={
            filter === filterItem.id
              ? styles.filterLinkCurrent
              : styles.filterLink
          }
        >
          {filterItem.name}
        </button>
      ))}
    </aside>
  )
}
