import React, { useState } from 'react'
import ImagePreview from '../../components/ImagePreview'
import archiveIcon from '../../icons/archive.svg'
import audioIcon from '../../icons/audio.svg'
import fileIcon from '../../icons/file.svg'
import imageIcon from '../../icons/image.svg'
import pdfIcon from '../../icons/pdf.svg'
import tableIcon from '../../icons/table.svg'
import textIcon from '../../icons/text.svg'
import videoIcon from '../../icons/video.svg'
import { FileInfo } from '../../shared/models'
import { startFileDownload } from '../../shared/utils'
import styles from './FileList.module.css'
import useLoadFile from './useLoadFile'

function truncateFilename(filename: string, maxChars = 50) {
  if (filename.length > maxChars) {
    const dotIndex = filename.lastIndexOf('.')
    if (dotIndex > 0) {
      const ext = filename.slice(dotIndex)
      const name = filename.slice(0, dotIndex)
      const maxNameLength = maxChars - 3 - ext.length
      const splitSize = Math.floor(maxNameLength / 2)
      return `${name.slice(0, splitSize)}...${name.slice(-splitSize)}${ext}`
    } else {
      return `${filename.slice(0, maxChars - 3)}...`
    }
  } else {
    return filename
  }
}

function getFileIcon(mimeType: string) {
  const isCorrectMime = /[^/]+\/[^/]+/.test(mimeType)
  if (!isCorrectMime) return fileIcon

  const textFormats = new Set([
    'xml',
    'json',
    'rtf',
    'msword',
    'vnd.openxmlformats-officedocument.wordprocessingml.document',
    'vnd.oasis.opendocument.text'
  ])

  const tableFormats = new Set([
    'vnd.ms-excel',
    'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'vnd.oasis.opendocument.spreadsheet'
  ])

  const archiveFormats = new Set([
    'zip',
    'vnd.rar',
    'x-7z-compressed',
    'x-bzip',
    'x-bzip2',
    'gzip',
    'x-tar'
  ])

  const [type, format] = mimeType.split('/')
  switch (type) {
    case 'image':
      return imageIcon
    case 'text':
      return textIcon
    case 'video':
      return videoIcon
    case 'audio':
      return audioIcon
    case 'application': {
      if (format === 'pdf') return pdfIcon
      if (textFormats.has(format)) return textIcon
      if (tableFormats.has(format)) return tableIcon
      if (archiveFormats.has(format)) return archiveIcon
      else return fileIcon
    }
    default:
      return fileIcon
  }
}

function FileItem({ fileInfo }: { fileInfo: FileInfo }) {
  const handleFileLoad = useLoadFile(fileInfo.fileId)
  const downloadFile = () => {
    handleFileLoad().then(fileUrl => {
      if (fileUrl) {
        startFileDownload(fileInfo.fileName, fileUrl)
      }
    })
  }
  return (
    <button className={styles.file} onClick={downloadFile}>
      <img
        src={getFileIcon(fileInfo.contentType)}
        className={styles.fileIcon}
        alt={fileInfo.fileName}
      />
      {truncateFilename(fileInfo.fileName)}
    </button>
  )
}

function ImageItem({ fileInfo }: { fileInfo: FileInfo }) {
  const [url, setUrl] = useState('')
  const [previewVisible, setPreviewVisible] = useState(false)

  const handleFileLoad = useLoadFile(fileInfo.fileId)
  const showFile = () => {
    setPreviewVisible(true)
    handleFileLoad().then(url => {
      if (url) {
        setUrl(url)
      } else {
        setPreviewVisible(false)
      }
    })
  }

  return (
    <>
      <button className={styles.imageButton} onClick={showFile}>
        <img
          className={styles.previewImage}
          src={fileInfo.thumbnail}
          alt={fileInfo.fileName}
        />
      </button>
      {previewVisible && (
        <ImagePreview
          imageUrl={url}
          fileName={fileInfo.fileName}
          close={() => setPreviewVisible(false)}
        />
      )}
    </>
  )
}

interface Props {
  files: FileInfo[]
  className?: string
}

export default function FileList(props: Props) {
  const { files, className } = props
  const images: FileInfo[] = []
  const otherFiles: FileInfo[] = []
  files.forEach(fileInfo => {
    if (/image/.test(fileInfo.contentType)) {
      images.push(fileInfo)
    } else {
      otherFiles.push(fileInfo)
    }
  })
  return (
    <section className={className}>
      {!!otherFiles.length && (
        <>
          <div className={styles.fileListTitle}>Файлы:</div>
          <div className={styles.fileContainer}>
            {otherFiles.map(fileInfo => (
              <FileItem key={fileInfo.fileId} fileInfo={fileInfo} />
            ))}
          </div>
        </>
      )}
      {!!images.length && (
        <>
          <div className={styles.fileListTitle}>Изображения:</div>
          <div className={styles.fileContainer}>
            {images.map(fileInfo => (
              <ImageItem key={fileInfo.fileId} fileInfo={fileInfo} />
            ))}
          </div>
        </>
      )}
    </section>
  )
}
