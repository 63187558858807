import { useCallback, useRef } from 'react'

export default function useCallbackOnVisible(
  callback?: Function,
  threshold = 0.5
) {
  const observerRef = useRef<IntersectionObserver>()

  const elementCallbackRef = useCallback(
    node => {
      if (node !== null && callback) {
        if (observerRef.current) {
          observerRef.current.disconnect()
        }

        observerRef.current = new IntersectionObserver(
          (entries, observer) => {
            const [entry] = entries
            if (entry.isIntersecting) {
              observer.disconnect()
              if (callback) callback()
            }
          },
          { threshold }
        )
        observerRef.current.observe(node)
      }
    },
    [callback, threshold]
  )
  return elementCallbackRef
}
