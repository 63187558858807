import React from 'react'
import styles from './Preview.module.css'

interface Props {
  src: string
  alt?: string
  style?: React.CSSProperties
}

export default function Preview({ src, alt, style }: Props) {
  return (
    <div className={styles.imageContainer} style={style}>
      <img className={styles.image} src={src} alt={alt} />
    </div>
  )
}
