import { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { AuthContext } from '../../app/authContext'
import { getUserFilters } from '../../shared/apiService'
import { Order, Sort, TaskFilter } from '../../shared/models'

function sortFilters(filterA: TaskFilter, filterB: TaskFilter) {
  return filterA.sort_index - filterB.sort_index
}

function findFilterById(filterId: number, filters: TaskFilter[]) {
  return filters.find(filter => filter.id === filterId)
}

function initSort() {
  const currentSort = localStorage.getItem('sort')
  return currentSort &&
    new Set(['title', 'status', 'changedDatetime']).has(currentSort)
    ? (currentSort as Sort)
    : 'changedDatetime'
}

function initOrder() {
  const currentOrder = localStorage.getItem('order')
  return currentOrder && new Set(['asc', 'desc']).has(currentOrder)
    ? (currentOrder as Order)
    : 'desc'
}

export function useTaskFilters() {
  const { handleError } = useContext(AuthContext)

  const { data: filters, isLoading } = useQuery('filters', getUserFilters, {
    select: filters => Object.values(filters).sort(sortFilters) as TaskFilter[],
    onError: handleError
  })

  const [filter, setFilter] = useState<number>()
  const [sort, setSort] = useState<Sort>(initSort)
  const [order, setOrder] = useState<Order>(initOrder)

  useEffect(() => {
    if (filters?.length) {
      const currentFilter = localStorage.getItem('filter')
      const currentFilterId = currentFilter ? parseInt(currentFilter) : NaN
      if (!isNaN(currentFilterId) && findFilterById(currentFilterId, filters)) {
        setFilter(currentFilterId)
      } else {
        localStorage.removeItem('filter')
        setFilter(filters[0].id)
      }
    }
  }, [filters])

  const handleFilterChange = (filterId: number) => {
    setFilter(filterId)
    localStorage.setItem('filter', filterId.toString())
  }

  const handleSortChange = (sortType: Sort) => {
    setSort(sortType)
    localStorage.setItem('sort', sortType)
  }

  const handleOrderChange = (orderType: Order) => {
    setOrder(orderType)
    localStorage.setItem('order', orderType)
  }

  return {
    isLoading,
    filters: filters ?? [],
    filter,
    setFilter: handleFilterChange,
    sort,
    setSort: handleSortChange,
    order,
    setOrder: handleOrderChange
  }
}
