import React from 'react'
import Spinner from './base/Spinner'
import styles from './ImagePreview.module.css'

export default function ImagePreview({
  imageUrl,
  fileName,
  close
}: {
  imageUrl: string
  fileName: string
  close: () => void
}) {
  const maxLength = 25
  const shortFileName =
    fileName.length <= maxLength
      ? fileName
      : fileName.slice(0, maxLength - 3) + '...'
  return (
    <div className={styles.background} onClick={imageUrl ? close : undefined}>
      {imageUrl ? (
        <div className={styles.modal}>
          <img
            src={imageUrl}
            alt={fileName}
            className={styles.image}
            onClick={event => event.stopPropagation()}
          />
          <a
            className={styles.link}
            download={fileName}
            href={imageUrl}
            onClick={event => event.stopPropagation()}
          >
            {shortFileName}
          </a>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  )
}
