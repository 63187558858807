import React from 'react'
import styles from './TaskTable.module.css'

export function TableSkeleton() {
  return (
    <div>
      <div className={styles.skeleton} />
      <SkeletonRows />
    </div>
  )
}

export function SkeletonRows() {
  return (
    <>
      {Array(10)
        .fill(null)
        .map((_, idx) => (
          <div key={idx} className={styles.skeletonRow}>
            {Array(7)
              .fill(null)
              .map((_, idx) => (
                <div
                  key={idx}
                  className={styles.skeletonCell}
                  style={{ flex: idx ? 1 : 3 }}
                />
              ))}
          </div>
        ))}
    </>
  )
}
