import { useCallback, useContext, useEffect, useReducer, useRef } from 'react'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { AuthContext } from '../../app/authContext'
import {
  createClientTask,
  getFormData,
  getProjects,
  getTask
} from '../../shared/apiService'
import { IFormData, IProject, SelectedGraphic, Task } from '../../shared/models'
import {
  getDescriptionParam,
  getFormNameParam,
  getGraphicIdParam
} from './utils'

type Action =
  | {
      type: 'project'
      payload: { value: string }
    }
  | {
      type: 'graphicsKind'
      payload: { value: string }
    }
  | { type: 'any'; payload: { field: string; value: any } }

type Option = { id: string; name: string }

type State = {
  projectId: string
  graphicsKindId: string
  graphicKindsList: Option[]
  graphicsTypeId: string
  warningModalVisible: boolean
  selectedProject: IProject | undefined
  selectedGraphic: SelectedGraphic | undefined
  graphicTypesList: Option[] | []
  formName: string
  additionalDescr: string | undefined
  repeatTaskId?: number
  createdTaskId: string | undefined
  relations_doc?: string
}

export default function useClientTaskState() {
  const history = useHistory()
  const { handleError, setAlert } = useContext(AuthContext)
  const handleErrorAndRedirect = useCallback(
    (err: Error) => {
      handleError(err)
      if (err.name === 'AuthError') {
        history.replace('/order?restore')
      }
    },
    [handleError, history]
  )

  const prevGraphicsTypeId = useRef('')

  const initialState: State = {
    projectId: '',
    graphicsKindId: '',
    graphicKindsList: [],
    graphicsTypeId: '',
    graphicTypesList: [],
    warningModalVisible: false,
    selectedProject: undefined,
    selectedGraphic: undefined,
    formName: '',
    additionalDescr: undefined,
    repeatTaskId: undefined,
    createdTaskId: undefined,
    relations_doc: undefined
  }

  const { data: projects = [], isLoading: isProjectLoading } = useQuery(
    'projects',
    getProjects,
    {
      onError: handleErrorAndRedirect
    }
  )

  const [state, dispatch] = useReducer(reducer, initialState)

  const handleGraphicTypeChanged = (formData: IFormData) => {
    const selectedGraphic = state.graphicTypesList.find(
      ({ id }) => id.toString() === state.graphicsTypeId
    )
    dispatch({
      type: 'any',
      payload: {
        field: 'selectedGraphic',
        value: { ...selectedGraphic, ...formData }
      }
    })
    dispatch({
      type: 'any',
      payload: {
        field: 'relations_doc',
        value: formData.relations_doc
      }
    })
  }

  const { refetch: refetchFormData, isFetching: isFormDataFetching } = useQuery(
    'formData',
    () => {
      return getFormData({
        projectId: state.projectId,
        graphicId: state.graphicsTypeId
      })
    },
    {
      onError: handleErrorAndRedirect,
      enabled: false,
      onSuccess: handleGraphicTypeChanged,
      staleTime: Infinity
    }
  )
  const params = new URLSearchParams(window.location.search)
  const repeatTaskIdParam = params.get('repeatTaskId')
  const repeatTaskId =
    repeatTaskIdParam && !isNaN(+repeatTaskIdParam)
      ? +repeatTaskIdParam
      : undefined

  const { data: taskToRepeat, isLoading: taskToRepeatLoading } = useQuery(
    ['task', repeatTaskId],
    () => getTask(repeatTaskId!),
    {
      enabled: state.repeatTaskId !== undefined,
      onError: handleError
    }
  )
  const taskToRepeatData = taskToRepeat?.clientCreatedFormRawData

  useEffect(() => {
    if (projects.length) {
      const graphicsTypeId = getGraphicIdParam()
      if (graphicsTypeId) {
        const targetProject = projects.find(({ graphicTypesList }) => {
          return graphicTypesList.find(
            ({ id }) => id === graphicsTypeId.toString()
          )
        })
        if (targetProject) {
          dispatch({
            type: 'project',
            payload: { value: targetProject.id.toString() }
          })
          dispatch({
            type: 'any',
            payload: {
              field: 'graphicsTypeId',
              value: graphicsTypeId.toString()
            }
          })
          const formNameParam = getFormNameParam()
          if (formNameParam)
            dispatch({
              type: 'any',
              payload: { field: 'formName', value: formNameParam }
            })
          const additionalDescrParam = getDescriptionParam()
          if (additionalDescrParam)
            dispatch({
              type: 'any',
              payload: { field: 'additionalDescr', value: additionalDescrParam }
            })
        } else {
          dispatch({
            type: 'any',
            payload: { field: 'warningModalVisible', value: true }
          })
        }

        history.replace('/order')
      }
    }
  }, [projects, history])

  const prevGraphicsType = useRef('')
  useEffect(() => {
    setAlert('')
    if (
      prevGraphicsType.current &&
      prevGraphicsType.current !== state.graphicsTypeId
    ) {
      dispatch({
        type: 'any',
        payload: { field: 'formName', value: undefined }
      })
      dispatch({
        type: 'any',
        payload: { field: 'additionalDescr', value: undefined }
      })
    }
    prevGraphicsType.current = state.graphicsTypeId
  }, [state.graphicsTypeId, setAlert])

  const onSubmit = (data: Task, files?: File[]) => {
    setAlert('')
    return createClientTask(data, files)
      .then(task => {
        dispatch({
          type: 'any',
          payload: { field: 'createdTaskId', value: task.id.toString() }
        })
      })
      .catch(handleErrorAndRedirect)
  }

  function getGraphicTypesList(code: string, selectedProject?: IProject) {
    return (
      selectedProject?.graphicTypesList
        .filter(({ type_code }) => type_code.toString() === code)
        .sort((a, b) => (a.name > b.name ? 1 : -1)) || []
    )
  }

  function getGraphicKindsList(selectedProject?: IProject) {
    return (
      selectedProject?.graphicKindsList
        .map(({ code, name }) => ({
          id: code,
          name: name
        }))
        .sort((a, b) => (a.name > b.name ? 1 : -1)) || []
    )
  }

  function getGraphicsKindIdByGraphicId(
    selectedProject: IProject,
    graphicId: string
  ) {
    return (
      selectedProject.graphicTypesList
        .find(({ id }) => id.toString() === graphicId.toString())
        ?.type_code.toString() || ''
    )
  }

  function getSelectedProject(projectId: string) {
    return projects.find(({ id }) => id.toString() === projectId)
  }

  function reducer(state: State, action: Action): State {
    switch (action.type) {
      case 'project':
        const projectId = action.payload.value
        const selectedProject = getSelectedProject(projectId)
        const graphicKindsList = getGraphicKindsList(selectedProject)
        const selectedGraphicsKindId =
          graphicKindsList.length === 1 ? graphicKindsList[0].id : ''

        let nextGraphicTypesList: any = []
        if (graphicKindsList.length === 1) {
          nextGraphicTypesList = getGraphicTypesList(
            selectedGraphicsKindId.toString(),
            selectedProject
          )
        }

        const selectedGraphicsTypeId =
          nextGraphicTypesList.length === 1 ? nextGraphicTypesList[0].id : ''

        return {
          ...state,
          graphicsTypeId: selectedGraphicsTypeId,
          graphicsKindId: selectedGraphicsKindId,
          projectId,
          selectedProject,
          graphicKindsList,
          graphicTypesList: nextGraphicTypesList,
          selectedGraphic: undefined
        }
      case 'graphicsKind':
        const graphicsKindId = action.payload.value
        const graphicTypesList = getGraphicTypesList(
          graphicsKindId.toString(),
          state.selectedProject
        )
        const graphicsTypeId =
          graphicTypesList.length === 1 ? graphicTypesList[0].id : ''

        return {
          ...state,
          graphicsKindId,
          graphicsTypeId,
          selectedGraphic: undefined,
          graphicTypesList
        }
      case 'any':
        return {
          ...state,
          [action.payload.field]: action.payload.value
        }
      default:
        throw new Error()
    }
  }

  useEffect(
    function fetchFormData() {
      if (state.graphicsTypeId) {
        refetchFormData()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.graphicsTypeId]
  )

  const restoreData = useRef<Record<string, any>>()
  useEffect(
    function restoreTask() {
      if (projects.length) {
        const params = new URLSearchParams(window.location.search)
        if (params.has('restore') && localStorage.getItem('restore')) {
          const { projectId, graphicsTypeId, graphicsKindId, ...formData } =
            JSON.parse(localStorage.getItem('restore')!)
          restoreData.current = formData

          dispatch({
            type: 'project',
            payload: {
              value: projectId
            }
          })
          dispatch({
            type: 'graphicsKind',
            payload: { value: graphicsKindId }
          })
          dispatch({
            type: 'any',
            payload: { field: 'graphicsTypeId', value: graphicsTypeId }
          })

          history.replace('/order')
        }
      }
    },
    [history, projects.length]
  )

  useEffect(
    function repeatTask() {
      if (
        taskToRepeat &&
        projects.length &&
        (!state.projectId ||
          state.projectId === taskToRepeat.clientProjectId.toString())
      ) {
        dispatch({
          type: 'project',
          payload: { value: taskToRepeat.clientProjectId.toString() }
        })

        if (state.selectedProject) {
          const graphicsKindId = getGraphicsKindIdByGraphicId(
            state.selectedProject,
            taskToRepeat.clientCreatedFormRawData.graphicId
          )

          dispatch({
            type: 'graphicsKind',
            payload: { value: graphicsKindId }
          })

          dispatch({
            type: 'any',
            payload: {
              field: 'graphicsTypeId',
              value: taskToRepeat.clientCreatedFormRawData.graphicId
            }
          })
        }
      }
    },
    [taskToRepeat, projects.length, state.selectedProject, state.projectId]
  )

  useEffect(() => {
    if (repeatTaskId) {
      dispatch({
        type: 'any',
        payload: { field: 'repeatTaskId', value: +repeatTaskId }
      })
    }
  }, [repeatTaskId])

  const isLoading = isProjectLoading || taskToRepeatLoading

  return {
    projects,
    projectId: state.projectId,
    graphicsKindId: state.graphicsKindId,
    graphicKindsList: state.graphicKindsList,
    graphicsTypeId: state.graphicsTypeId,
    warningModalVisible: state.warningModalVisible,
    selectedProject: state.selectedProject,
    selectedGraphic: state.selectedGraphic,
    graphicTypesList: state.graphicTypesList,
    formName: state.formName,
    additionalDescr: state.additionalDescr,
    repeatTaskId: state.repeatTaskId,
    isLoading,
    relations_doc: state.relations_doc,
    isFormDataFetching,
    restoreData,
    prevGraphicsType: prevGraphicsTypeId,
    setAlert,
    taskToRepeatData,
    createdTaskId: state.createdTaskId,
    dispatch,
    onSubmit
  }
}
