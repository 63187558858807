import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState
} from 'react'
import { useHistory } from 'react-router-dom'
import { AuthContext } from '../../app/authContext'
import Button from '../../components/base/Button'
import Checkbox from '../../components/base/Checkbox'
import DateTime from '../../components/base/DateTime'
import Details from '../../components/base/Details'
import Editor, { useEditor } from '../../components/base/Editor'
import FileInput from '../../components/base/FileInput'
import { hasText } from '../../components/base/HtmlEditor'
import { MultiSearch, Option, Search } from '../../components/base/Search/'
import Select from '../../components/base/Select'
import TextField from '../../components/base/TextField'
import Title from '../../components/base/Title'
import {
  createTask,
  getAuditorsList,
  getClientProjects,
  getDepartments,
  getGraphicGroups,
  getParentTasks,
  getResponsibleUsers,
  getTask,
  getTemplate,
  getTemplates
} from '../../shared/apiService'
import { getTimeStampInSecs } from '../../shared/dateUtils'
import useClearAlertOnUnmount from '../../shared/hooks/useClearAlertOnUnmount'
import {
  ClientProject,
  Department,
  GenericDataType,
  GraphicGroup,
  ParentTask,
  Priority,
  Template,
  UserInfo
} from '../../shared/models'
import { priorities } from '../../shared/utils'
import {
  DateValidators,
  isAllValid,
  StringValidators,
  validateDate,
  validateString
} from '../../shared/validators'

const initialState = {
  fields: {
    title: '',
    files: [],
    selectedAuditors: [],
    selectedDepartments: [],
    useTiming: false,
    timing: 1,
    useExtraTiming: false,
    extraTiming: 0,

    templateId: NaN,
    parentTaskId: NaN,
    clientProjectId: NaN,
    graphicGroupId: NaN,
    graphicTypeId: NaN,
    responsibleUserId: NaN,
    departmentsIds: [],
    auditorsIds: [],

    usePriority: false,
    priority: Priority.NORMAL
  },
  directories: {
    templates: [],
    parentTasks: [],
    responsibleUsers: [],
    auditors: [],
    departments: [],
    clientProjects: [],
    graphicGroups: {}
  },
  validationMessages: {
    title: 'Не должно быть пустым'
  }
}

type Fields = {
  title: string
  templateId: number
  files: File[]
  useTiming: boolean
  timing: number
  useExtraTiming: boolean
  extraTiming: number
  deadlineDate?: Date
  parentTaskId: number
  clientProjectId: number
  graphicGroupId: number
  graphicTypeId: number
  responsibleUserId: number
  departmentsIds: number[]
  auditorsIds: number[]
  usePriority: boolean
  priority: Priority
}

type Directories = {
  templates: Template[]
  parentTasks: ParentTask[]
  responsibleUsers: UserInfo[]
  auditors: UserInfo[]
  departments: Department[]
  clientProjects: ClientProject[]
  graphicGroups: Record<number, GraphicGroup>
}

type State = {
  fields: Fields
  directories: Directories
  validationMessages: { [K in keyof Fields]?: string }
}

type Action =
  | { type: 'update_fields'; payload: Partial<Fields> }
  | { type: 'update_directories'; payload: Partial<Directories> }

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'update_fields': {
      if (action.payload.useTiming === false) {
        delete action.payload.timing
        action.payload.useExtraTiming = false
        delete action.payload.extraTiming
      }
      const fields = { ...state.fields, ...action.payload }

      const validationMessages = { ...state.validationMessages }

      validationMessages.title = validateString(
        action.payload.title ?? state.fields.title,
        [StringValidators.notEmpty]
      )
      validationMessages.timing = validateString(
        (action.payload.timing ?? state.fields.timing).toString(),
        [StringValidators.isPositive],
        action.payload.useTiming ?? state.fields.useTiming
      )
      validationMessages.extraTiming = validateString(
        (action.payload.extraTiming ?? state.fields.extraTiming).toString(),
        [StringValidators.isNotNegative],
        action.payload.useExtraTiming ?? state.fields.useExtraTiming
      )
      const deadlineDate =
        'deadlineDate' in action.payload
          ? action.payload.deadlineDate
          : state.fields.deadlineDate
      validationMessages.deadlineDate = validateDate(
        deadlineDate,
        [DateValidators.notEmpty, DateValidators.notPast],
        !!deadlineDate
      )

      return { ...state, fields, validationMessages }
    }
    case 'update_directories': {
      let templates = state.directories.templates.slice()
      if (action.payload.templates) {
        templates = templates.concat(action.payload.templates)
      }
      let parentTasks = state.directories.parentTasks.slice()
      if (action.payload.parentTasks) {
        parentTasks = parentTasks.concat(action.payload.parentTasks)
      }
      const directories = {
        ...state.directories,
        ...action.payload,
        templates,
        parentTasks
      }
      return { ...state, directories }
    }
    default:
      return state
  }
}

function userInfoToOption(userInfo: UserInfo) {
  return {
    id: userInfo.id,
    value: userInfo.formatted_name
  }
}
function genericToOption(data: GenericDataType) {
  return {
    id: data.id,
    value: data.name
  }
}
function toUserOptsList(list: UserInfo[]) {
  return list.map(userInfoToOption)
}
function toGenericOptsList(list: GenericDataType[]) {
  return list.map(genericToOption)
}
function idToData<T extends { id: number }>(id: number, list: T[]) {
  return list.find(obj => obj.id === id)
}
function selectedOptionFromId<T extends { id: number }>(
  id: number,
  list: T[],
  toOption: (data: T) => Option
) {
  const data = idToData(id, list)
  return data ? toOption(data) : undefined
}

export default function EmployeeTaskForm() {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { valueType: descriptionType, ...editorProps } = useEditor()
  const { value: description } = editorProps
  const { setAlert, handleError } = useContext(AuthContext)
  const history = useHistory()

  const [detailsVisible, setDetailsVisible] = useState(false)

  const [loading, setLoading] = useState(false)
  const [firstAttempt, setFirstAttempt] = useState(true)

  const allValid = isAllValid(state.validationMessages)

  useEffect(() => {
    getResponsibleUsers()
      .then(responsibleUsers => {
        dispatch({ type: 'update_directories', payload: { responsibleUsers } })
      })
      .catch(handleError)

    getClientProjects()
      .then(clientProjects => {
        dispatch({ type: 'update_directories', payload: { clientProjects } })
      })
      .catch(handleError)

    getGraphicGroups()
      .then(graphicGroups => {
        dispatch({ type: 'update_directories', payload: { graphicGroups } })
      })
      .catch(handleError)

    getDepartments()
      .then(departments => {
        dispatch({ type: 'update_directories', payload: { departments } })
      })
      .catch(handleError)
  }, [handleError])

  useEffect(() => {
    getAuditorsList(state.fields.clientProjectId)
      .then(auditors => {
        dispatch({ type: 'update_directories', payload: { auditors } })
      })
      .catch(handleError)
  }, [handleError, state.fields.clientProjectId])

  const graphicTypes = useMemo(() => {
    return state.fields.graphicGroupId
      ? state.directories.graphicGroups[state.fields.graphicGroupId].types
      : []
  }, [state.directories.graphicGroups, state.fields.graphicGroupId])

  useEffect(() => {
    if (state.fields.graphicTypeId) {
      const graphicType = graphicTypes.find(
        graphicType => graphicType.id === state.fields.graphicTypeId
      )
      if (!graphicType) {
        dispatch({ type: 'update_fields', payload: { graphicTypeId: NaN } })
      }
    }
  }, [graphicTypes, state.fields.graphicTypeId])

  useEffect(() => {
    if (state.fields.templateId) {
      getTemplate(state.fields.templateId)
        .then(template => {
          dispatch({
            type: 'update_fields',
            payload: {
              clientProjectId: template.clientProjectId,
              graphicGroupId: template.graphicGroupId,
              graphicTypeId: template.graphicTypeId,
              responsibleUserId: template.responsibleId,
              departmentsIds: template.departments,
              auditorsIds: template.auditors,
              useTiming: template.useTiming,
              ...(template.useTiming && { timing: template.timing }),
              useExtraTiming: template.useExtraTiming,
              ...(template.useExtraTiming && {
                extraTiming: template.extraTiming
              }),
              usePriority: template.usePriority,
              ...(template.usePriority && { priority: template.priority })
            }
          })
        })
        .catch(handleError)
    }
  }, [handleError, state.fields.templateId])

  useEffect(() => {
    if (state.fields.parentTaskId) {
      const parentTask = idToData(
        state.fields.parentTaskId,
        state.directories.parentTasks
      )
      if (parentTask) {
        dispatch({
          type: 'update_fields',
          payload: { clientProjectId: parentTask.clientProjectId }
        })
      }
    }
  }, [state.fields.parentTaskId, state.directories.parentTasks])

  const params = new URLSearchParams(window.location.search)
  const repeatTaskId = params.get('repeatTaskId')

  useEffect(() => {
    if (!repeatTaskId) return
    setLoading(true)
    getTask(+repeatTaskId)
      .then(task => {
        dispatch({
          type: 'update_fields',
          payload: {
            title: task.title,
            templateId: task.templateId
          }
        })
      })
      .catch(handleError)
      .finally(() => setLoading(false))
  }, [repeatTaskId, handleError])

  const updateField = useCallback(
    (name: keyof Fields, value: Fields[typeof name]) => {
      dispatch({ type: 'update_fields', payload: { [name]: value } })
    },
    []
  )

  const getTemplatesOptsByQuery = useCallback(
    query =>
      getTemplates(query)
        .then(templates => {
          dispatch({ type: 'update_directories', payload: { templates } })
          return toGenericOptsList(templates)
        })
        .catch(err => {
          handleError(err)
          return []
        }),
    [handleError]
  )

  const getParentTasksOptsByQuery = useCallback(
    query =>
      getParentTasks(query)
        .then(parentTasks => {
          dispatch({
            type: 'update_directories',
            payload: { parentTasks }
          })
          return toGenericOptsList(parentTasks)
        })
        .catch(err => {
          handleError(err)
          return []
        }),
    [handleError]
  )

  const handleSubmit = () => {
    if (firstAttempt) setFirstAttempt(false)
    if (!allValid) return

    setLoading(true)
    const data = {
      title: state.fields.title,
      templateId: state.fields.templateId || undefined,
      clientProjectId: state.fields.clientProjectId || undefined,
      graphicGroupId: state.fields.graphicGroupId || undefined,
      graphicTypeId: state.fields.graphicTypeId || undefined,
      responsibleId: state.fields.responsibleUserId || undefined,
      description: hasText(description) ? description : undefined,
      descriptionType: hasText(description) ? descriptionType : undefined,
      parentTaskId: state.fields.parentTaskId || undefined,
      useTiming: state.fields.useTiming,
      timing: state.fields.useTiming ? state.fields.timing : undefined,
      useExtraTiming: state.fields.useExtraTiming,
      extraTiming: state.fields.useExtraTiming
        ? state.fields.extraTiming
        : undefined,
      deadline: state.fields.deadlineDate
        ? getTimeStampInSecs(state.fields.deadlineDate)
        : undefined,
      departments: state.fields.departmentsIds,
      auditors: state.fields.auditorsIds,
      usePriority: state.fields.usePriority,
      priority: state.fields.usePriority ? state.fields.priority : undefined,
      taskFiles: state.fields.files
    }

    setAlert('')
    createTask(data)
      .then(({ id }) => {
        history.push(`/tasks/${id}`)
      })
      .catch(err => {
        handleError(err)
        setLoading(false)
      })
  }

  useClearAlertOnUnmount()

  return (
    <>
      <Title>Поставить задачу</Title>
      <fieldset>
        <TextField
          type='text'
          value={state.fields.title}
          name='title'
          label='Название задачи'
          placeholder='Летучка по картам'
          onChange={event => updateField('title', event.target.value)}
          required
          isValid={firstAttempt || !state.validationMessages.title}
          validationMessage={state.validationMessages.title}
        />
        <Search
          id='template'
          label='Шаблон задачи'
          placeholder='Шаблон задачи'
          options={getTemplatesOptsByQuery}
          selected={selectedOptionFromId(
            state.fields.templateId,
            state.directories.templates,
            genericToOption
          )}
          updateSelected={option =>
            updateField('templateId', option?.id ?? NaN)
          }
        />
        <Search
          id='responsibleUser'
          label='Исполнитель'
          placeholder='Исполнитель'
          options={toUserOptsList(state.directories.responsibleUsers)}
          selected={selectedOptionFromId(
            state.fields.responsibleUserId,
            state.directories.responsibleUsers,
            userInfoToOption
          )}
          updateSelected={option =>
            updateField('responsibleUserId', option?.id ?? NaN)
          }
        />
        <Editor
          {...editorProps}
          label='Описание'
          placeholder='Все подписи и названия указать в том виде, в котором они должны быть на графике'
        />
        <Details
          title='Дополнительные поля'
          detailsVisible={detailsVisible}
          setVisible={setDetailsVisible}
          color='blue'
        >
          <MultiSearch
            id='auditors'
            options={toUserOptsList(state.directories.auditors)}
            selected={state.fields.auditorsIds.reduce<Option[]>((acc, id) => {
              const option = selectedOptionFromId(
                id,
                state.directories.auditors,
                userInfoToOption
              )
              if (option) acc.push(option)
              return acc
            }, [])}
            updateSelected={opts => {
              const ids = opts.map(({ id }) => id)
              updateField('auditorsIds', ids)
            }}
            placeholder='Наблюдатели'
            label='Наблюдатели'
          />
          <MultiSearch
            id='departments'
            options={toGenericOptsList(state.directories.departments)}
            selected={state.fields.departmentsIds.reduce<Option[]>(
              (acc, id) => {
                const option = selectedOptionFromId(
                  id,
                  state.directories.departments,
                  genericToOption
                )
                if (option) acc.push(option)
                return acc
              },
              []
            )}
            updateSelected={opts => {
              const ids = opts.map(({ id }) => id)
              updateField('departmentsIds', ids)
            }}
            placeholder='Исполняющие отделы'
            label='Исполняющие отделы'
          />
          <Checkbox
            id='useTiming'
            label='Нужен хронометраж'
            checked={state.fields.useTiming}
            setChecked={value => updateField('useTiming', value)}
          />
          {state.fields.useTiming && (
            <TextField
              type='number'
              value={state.fields.timing}
              name='timing'
              label='Хронометраж (сек)'
              inputMode='decimal'
              placeholder='30'
              onChange={event => updateField('timing', +event.target.value)}
              isValid={firstAttempt || !state.validationMessages.timing}
              validationMessage={state.validationMessages.timing}
            />
          )}
          {state.fields.useTiming && (
            <Checkbox
              id='useExtraTiming'
              label='Нужен захлест'
              checked={state.fields.useExtraTiming}
              setChecked={value => updateField('useExtraTiming', value)}
            />
          )}
          {state.fields.useTiming && state.fields.useExtraTiming && (
            <TextField
              type='number'
              value={state.fields.extraTiming}
              name='extraTiming'
              label='Захлест (сек)'
              inputMode='decimal'
              placeholder='5'
              onChange={event =>
                updateField('extraTiming', +event.target.value)
              }
              isValid={firstAttempt || !state.validationMessages.extraTiming}
              validationMessage={state.validationMessages.extraTiming}
            />
          )}
          <DateTime
            label='Крайний срок'
            name='deadline'
            value={state.fields.deadlineDate}
            setValue={deadlineDate => updateField('deadlineDate', deadlineDate)}
            isValid={firstAttempt || !state.validationMessages.deadlineDate}
            validationMessage={state.validationMessages.deadlineDate}
          />
          <Search
            id='clientProject'
            placeholder='Проект заказчика'
            label='Проект заказчика'
            options={toGenericOptsList(state.directories.clientProjects)}
            selected={selectedOptionFromId(
              state.fields.clientProjectId,
              state.directories.clientProjects,
              genericToOption
            )}
            updateSelected={option =>
              updateField('clientProjectId', option?.id ?? NaN)
            }
            disabled={!!state.fields.parentTaskId}
          />
          <Search
            id='graphicGroup'
            placeholder='Категория'
            label='Категория'
            options={toGenericOptsList(
              Object.values(state.directories.graphicGroups)
            )}
            selected={selectedOptionFromId(
              state.fields.graphicGroupId,
              Object.values(state.directories.graphicGroups),
              genericToOption
            )}
            updateSelected={option =>
              updateField('graphicGroupId', option?.id ?? NaN)
            }
          />
          <Search
            id='graphicType'
            placeholder='Вид продукции'
            label='Вид продукции'
            options={toGenericOptsList(graphicTypes)}
            selected={selectedOptionFromId(
              state.fields.graphicTypeId,
              graphicTypes,
              genericToOption
            )}
            updateSelected={option =>
              updateField('graphicTypeId', option?.id ?? NaN)
            }
          />
          <Search
            id='parentTask'
            placeholder='Родительская задача'
            label='Родительская задача'
            options={getParentTasksOptsByQuery}
            selected={selectedOptionFromId(
              state.fields.parentTaskId,
              state.directories.parentTasks,
              genericToOption
            )}
            updateSelected={option =>
              updateField('parentTaskId', option?.id ?? NaN)
            }
          />
          <Checkbox
            id='usePriority'
            label='Использовать приоритет'
            checked={state.fields.usePriority}
            setChecked={value => updateField('usePriority', value)}
          />
          {state.fields.usePriority && (
            <Select
              value={state.fields.priority.toString()}
              name='priority'
              label='Приоритет'
              onChange={event => updateField('priority', +event.target.value)}
              options={priorities.map((name, index) => ({
                id: index.toString(),
                name
              }))}
            />
          )}
        </Details>
        <FileInput
          style={{ marginTop: 25 }}
          files={state.fields.files}
          handleAcceptedFiles={files => updateField('files', files)}
          showAlert={setAlert}
        />
        <Button
          name='Поставить задачу'
          onClick={handleSubmit}
          status={
            loading
              ? 'loading'
              : !firstAttempt && !allValid
              ? 'disabled'
              : 'enabled'
          }
        />
      </fieldset>
    </>
  )
}
