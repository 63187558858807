import React from 'react'
import Modal from '../../components/Modal'

export function AccountModal({ hideModal }: { hideModal: () => void }) {
  return (
    <Modal hideModal={hideModal}>
      <div>
        Отправьте нам письмо с темой «Учётная запись для сайта». В ответном
        письме мы расскажем, что делать.
      </div>
    </Modal>
  )
}
