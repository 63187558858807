import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { useMediaQuery } from 'react-responsive'
import { useHistory, useLocation } from 'react-router-dom'
import Alert from '../components/base/Alert'
import Burger from '../components/base/Burger'
import Notification from '../components/base/Notification'
import modalStyles from '../components/Modal.module.css'
import logoEmg from '../icons/logo.svg'
import logoGetrender from '../icons/logo_getrender.png'
import logoNone from '../icons/logo_none.svg'
import useChannels, { MessageData } from '../pages/Tasks/useChannels'
import {
  useUnreadNotificationCount,
  useUnreadNotifications
} from '../shared/hooks/useNotification'
import useOutsideClick from '../shared/hooks/useOutsideClick'
import { getFaviconEl } from '../shared/utils'
import { AuthContext } from './authContext'
import styles from './Header.module.css'
import { Menu } from './Menu'
import NotificationTab from './NotificationTab'
import SearchBar from './SearchBar'
import SearchBarMini from './SearchBarMini'

export default function Header() {
  const { refetch } = useUnreadNotifications()
  const { data: unreadNotifsCount, refetch: refetchCount } =
    useUnreadNotificationCount()

  const [isMenuVisible, setIsMenuVisible] = useState(false)
  const [isNotificationVisible, setIsNotificationVisible] = useState(false)

  const { authenticated, logo, userType, alertMessage, setAlertMessage } =
    useContext(AuthContext)
  const location = useLocation()
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 600px)' })
  const history = useHistory()
  const ref = useRef(null)

  useOutsideClick(ref, () => {
    if (isNotificationVisible) {
      setIsNotificationVisible(false)
    }
  })

  const notifCounterCallback = useCallback(
    (msgData: MessageData) => {
      if (msgData.command === 'comment') {
        refetchCount()
      }
    },
    [refetchCount]
  )

  useChannels(notifCounterCallback)

  useEffect(() => {
    if (isMenuVisible) {
      document.documentElement.classList.add(modalStyles.modalOpen)
    } else {
      document.documentElement.classList.remove(modalStyles.modalOpen)
    }
  }, [isMenuVisible])

  useEffect(() => {
    if (authenticated) {
      const notifCount = `(${unreadNotifsCount?.count.toString()!}) `
      let count = ''
      const fav = getFaviconEl()
      if (unreadNotifsCount?.count! > 0) {
        count = notifCount
        fav?.setAttribute('href', '/customer_app/favicon-notif.png')
      } else {
        fav?.setAttribute('href', '/customer_app/favicon.png')
      }
      document.title = `${count}Сайт заказов`
    }
  }, [authenticated, unreadNotifsCount?.count, history.location])

  useEffect(() => {
    if (isNotificationVisible) {
      refetch()
    }
  }, [refetch, isNotificationVisible])

  useEffect(() => {
    setIsNotificationVisible(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const showLogo = !(
    new RegExp(String.raw`/search`).test(location.pathname) &&
    !isDesktopOrLaptop
  )
  const logoIcon =
    userType === 'employee' || logo === 'emg'
      ? logoEmg
      : logo === 'map'
      ? logoGetrender
      : logoNone

  return (
    <>
      <header className={styles.header}>
        {showLogo && (
          <img
            onClick={() => history.push('/')}
            src={logoIcon}
            alt='Logo'
            className={styles.logo}
          />
        )}
        <div className={styles.rightSideBlock}>
          {authenticated ? (
            <>
              {isDesktopOrLaptop && <SearchBar style={{ marginRight: 15 }} />}
              <div className={styles.container}>
                <div ref={ref} style={{ float: 'left', marginRight: 10 }}>
                  <Notification
                    onClick={() => {
                      setIsNotificationVisible(!isNotificationVisible)
                    }}
                    hasUnread={unreadNotifsCount?.count! > 0}
                  />
                </div>
                <div style={{ float: 'right' }}>
                  <Burger
                    on={isMenuVisible}
                    onClick={() => setIsMenuVisible(!isMenuVisible)}
                  />
                </div>
              </div>
            </>
          ) : (
            <div />
          )}
        </div>

        {authenticated &&
          !isDesktopOrLaptop &&
          new RegExp(String.raw`/search`).test(location.pathname) && (
            <SearchBarMini />
          )}
        <Menu visible={isMenuVisible} close={() => setIsMenuVisible(false)} />
      </header>

      <div
        style={{
          height: 10,
          width: '100vw',
          position: 'fixed',
          zIndex: 999
        }}
      >
        {alertMessage && (
          <Alert message={alertMessage} close={() => setAlertMessage('')} />
        )}

        {authenticated && isNotificationVisible && (
          <NotificationTab forwardedRef={ref} />
        )}
      </div>
    </>
  )
}
