import React, { useContext, useState } from 'react'
import { AuthContext } from '../../app/authContext'
import Button from '../../components/base/Button'
import TextField from '../../components/base/TextField'
import Title from '../../components/base/Title'
import { getCurrentUserInfo, getNewToken } from '../../shared/apiService'
import { UserInfo } from '../../shared/models'
import { AccountModal } from './AccountModal'
import styles from './AuthForm.module.css'

interface AuthFormProps {
  authenticate: (userInfo?: UserInfo) => void
}

export default function AuthForm(props: AuthFormProps) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [firstAttempt, setFirstAttempt] = useState(true)
  const [loading, setLoading] = useState(false)

  const [accountModalVisible, setAccountModalVisible] = useState(false)

  const { setAlert } = useContext(AuthContext)

  const emailValid = !!email
  const passwordValid = !!password
  const buttonEnabled = firstAttempt || (emailValid && passwordValid)

  const handleClick = () => {
    if (firstAttempt) setFirstAttempt(false)
    setLoading(true)
    getNewToken(email, password)
      .then(getCurrentUserInfo)
      .then(props.authenticate)
      .catch(() => {
        props.authenticate()
        setAlert('Неверные эл. почта или пароль')
        setLoading(false)
      })
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLFieldSetElement>) => {
    if (event.key === 'Enter') handleClick()
  }

  return (
    <>
      <Title>Авторизация</Title>
      <fieldset onKeyDown={handleKeyDown}>
        <form>
          <TextField
            type='email'
            label='Эл. почта'
            name='email'
            value={email}
            onChange={event => {
              setAlert('')
              setEmail((event.target as HTMLInputElement).value.trim())
            }}
            isValid={firstAttempt || emailValid}
            placeholder='example@mail.com'
          />
          <TextField
            type='password'
            label='Пароль'
            name='password'
            value={password}
            onChange={event => {
              setAlert('')
              setPassword((event.target as HTMLInputElement).value.trim())
            }}
            isValid={firstAttempt || passwordValid}
          />
        </form>
        <Button
          name='Войти'
          status={loading ? 'loading' : buttonEnabled ? 'enabled' : 'disabled'}
          onClick={handleClick}
        />
      </fieldset>
      <p>
        <button
          type='button'
          className={styles.infoButton}
          onClick={() => setAccountModalVisible(true)}
        >
          Нет учетной записи
        </button>
      </p>
      {accountModalVisible && (
        <AccountModal hideModal={() => setAccountModalVisible(false)} />
      )}
    </>
  )
}
