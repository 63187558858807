import Select from 'emg-ui-kit/components/Select'
import { SelectSearch } from 'emg-ui-kit/components/SelectSearch'
import { useMediaQuery } from 'react-responsive'
import Hint from '../../components/base/BaseInput/Hint'
import InputContainer from '../../components/base/BaseInput/InputContainer'
import Spinner from '../../components/base/Spinner'
import Title from '../../components/base/Title'
import Preview from '../../components/Preview'
import useClearAlertOnUnmount from '../../shared/hooks/useClearAlertOnUnmount'
import {
  DesignTask,
  GraphicsTask,
  MapTask,
  VizrtDesignTask,
  VizrtTask
} from '../../shared/models'
import styles from './ClientTaskForm.module.css'
import DesignForm from './DesignForm'
import DesignVizrtForm from './DesignVizrtForm'
import GraphicsForm from './GraphicsForm'
import MapForm from './MapForm'
import TaskCreated from './TaskCreated'
import useClientTaskState from './useClientTaskState'
import VizrtForm from './VizrtForm'
import { WarningModal } from './WarningModal'

const baseUrl = process.env.NODE_ENV === 'production' ? '/customer_app' : ''

export default function ClientTaskForm() {
  const {
    projects,
    projectId,
    graphicsKindId,
    graphicKindsList,
    graphicsTypeId,
    warningModalVisible,
    selectedProject,
    selectedGraphic,
    graphicTypesList,
    isLoading,
    isFormDataFetching,
    setAlert,
    dispatch,
    formName,
    additionalDescr,
    repeatTaskId,
    restoreData,
    prevGraphicsType,
    taskToRepeatData,
    createdTaskId,
    onSubmit,
    relations_doc
  } = useClientTaskState()

  useClearAlertOnUnmount()

  const BITRIX_APP_LABEL_STYLE = { flexBasis: 183 }

  const subform = (() => {
    if (selectedProject && selectedGraphic) {
      const subformProps = {
        selectedProgram: selectedProject,
        selectedGraphic,
        graphicsKindId,
        setAlertMessage: setAlert,
        formName,
        additionalDescr,
        repeatTaskId,
        restoreData: restoreData?.current,
        prevGraphicsType,
        onSubmit
      }
      switch (selectedGraphic.form_type) {
        case 'vizrt-develop':
          return (
            <VizrtForm
              {...subformProps}
              taskToRepeat={taskToRepeatData as VizrtTask}
            />
          )
        case 'graphic':
          return (
            <GraphicsForm
              {...subformProps}
              taskToRepeat={taskToRepeatData as GraphicsTask}
            />
          )
        case 'design-develop':
          return (
            <DesignForm
              {...subformProps}
              taskToRepeat={taskToRepeatData as DesignTask}
            />
          )
        case 'design-develop-vizrt':
          return (
            <DesignVizrtForm
              {...subformProps}
              taskToRepeat={taskToRepeatData as VizrtDesignTask}
            />
          )
        case 'need-map':
          return (
            <MapForm
              {...subformProps}
              taskToRepeat={taskToRepeatData as MapTask}
            />
          )
      }
    } else {
      return null
    }
  })()

  const isRestoreAvailable = localStorage.getItem('restore') && !graphicsTypeId

  const isPreviewAvailable =
    !isFormDataFetching && !!selectedGraphic && selectedGraphic.preview?.preview

  const isDesktop = useMediaQuery({ query: '(min-width: 600px)' })

  if (isLoading) return <Spinner className={styles.spinner} />
  if (createdTaskId)
    return (
      <TaskCreated
        taskId={createdTaskId}
        createAnotherTask={() =>
          dispatch({
            type: 'any',
            payload: { field: 'createdTaskId', value: '' }
          })
        }
      />
    )
  return (
    <>
      <Title>
        {selectedGraphic?.form_type === 'need-map'
          ? 'Заявка на карту'
          : 'Заявка'}
      </Title>
      <fieldset>
        <Select
          value={projectId}
          name='project'
          label='Программа'
          onChange={event =>
            dispatch({
              type: 'project',
              payload: { value: event.target.value }
            })
          }
          isValid={!!projectId}
          options={projects}
          required
          labelStyle={BITRIX_APP_LABEL_STYLE}
        />

        {selectedProject?.contractCounter?.value ? (
          <InputContainer label=' ' inputContainerStyles={{}}>
            <div className={styles.contractCount}>
              Кол-во заказов:{' '}
              <span
                style={{
                  fontSize: 18,
                  fontFamily: 'var(--font-bold)',
                  color: `var(--${selectedProject?.contractCounter?.color})`
                }}
              >
                {selectedProject?.contractCounter?.value}
              </span>
              <Hint
                hint={`Заказы за месяц. Информация несет ознакомительный характер. Количество заявок для ориентира - ${selectedProject?.contractCounter?.contract_value}`}
                className={styles.contractCountHint}
                style={{
                  ...(isDesktop
                    ? { right: 0, top: -8 }
                    : { right: -25, top: -4 })
                }}
              />
            </div>
          </InputContainer>
        ) : null}

        <Select
          value={graphicsKindId}
          name='project'
          label='Вид графики'
          onChange={event => {
            dispatch({
              type: 'graphicsKind',
              payload: { value: event.target.value }
            })
          }}
          isValid={!!graphicsKindId}
          options={graphicKindsList}
          required
          labelStyle={BITRIX_APP_LABEL_STYLE}
        />

        <SelectSearch
          required
          label='Тип графики'
          placeholder='--Выберите вариант--'
          options={graphicTypesList}
          selected={selectedGraphic}
          updateSelected={(option: any) => {
            if (option) {
              dispatch({
                type: 'any',
                payload: { field: 'graphicsTypeId', value: option.id }
              })
            }
          }}
          isValid={!!graphicsTypeId}
          disabled={!graphicsKindId}
          labelStyle={BITRIX_APP_LABEL_STYLE}
        />

        {!!graphicsTypeId && !!relations_doc && (
          <InputContainer label=' ' inputContainerStyles={{}}>
            <div className={styles.reglamentText}>
              Ознакомиться с{' '}
              <button
                onClick={() => {
                  window.open(`${relations_doc}`, '_blank')
                }}
                className={styles.reglamentButton}
              >
                регламентом заказа{' '}
              </button>
            </div>
          </InputContainer>
        )}

        {isRestoreAvailable && (
          <div style={{ display: 'flex' }}>
            <button
              onClick={() => {
                window.location.assign(`${baseUrl}/order?restore`)
              }}
              className={styles.restoreButton}
            >
              Восстановить черновик
            </button>
          </div>
        )}
        {isPreviewAvailable && (
          <Preview
            src={selectedGraphic.preview!.preview ?? ''}
            alt='Превью шаблона'
          />
        )}
        {isFormDataFetching ? <Spinner className={styles.spinner} /> : subform}
      </fieldset>
      {warningModalVisible && (
        <WarningModal
          hideModal={() =>
            dispatch({
              type: 'any',
              payload: { field: 'warningModalVisible', value: false }
            })
          }
        />
      )}
    </>
  )
}
