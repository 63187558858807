import { MutableRefObject } from 'react'

export interface IGraphicKind {
  name: string
  code: string
}

export interface Auditor {
  id: number
  formatted_name: string
  emails: string[]
  user_type: string
}

export interface IProject {
  id: number
  name: string
  auditors: Record<string, Auditor>
  graphicKindsList: IGraphicKind[]
  graphicTypesList: IGraphicType[]
  catalogUrl?: string
  contractCounter?: {
    code: string
    value: number
    color: string
    contract_value: number
  }
}

export interface IGraphicType {
  id: string
  name: string
  type_code: number
}

export interface SelectedGraphic
  extends Omit<IFormData, 'id' | 'name'>,
    IGraphicType {}

export interface IFormData {
  id: number
  name: string
  form_type: FormType
  form_fields?: FormFields
  relations_doc?: string
  preview: FileInfo | null
}

type FormType =
  | 'graphic'
  | 'design-develop'
  | 'vizrt-develop'
  | 'design-develop-vizrt'
  | 'need-map'

type OptionalFields =
  | 'taskTitle'
  | 'title'
  | 'issueName'
  | 'subtitle'
  | 'executedGraphicsName'
  | 'sameCount'
  | 'timing'
  | 'extraTiming'
  | 'mediaFormat'
  | 'mapDesign'
  | 'programDesign'
  | 'priority'
  | 'geo'
  | 'locationOfGraphics'
  | 'airingDatetime'
  | 'montageDatetime'

export interface FormField {
  name: string
  required: boolean
  default_value?: string
  relation_field?: string
  values?: { id: number; name: string; image?: FileInfo }[]
}

export type FormFields = {
  [key in OptionalFields]?: FormField
}

export type DescriptionType = 'text' | 'html'

interface BaseTask {
  formType: FormType
  projectId: string
  graphicId: string
  auditors: number[]
  description: string
  descriptionType: DescriptionType
  formName?: string
  additionalDescr?: string
  priority?: number
  repeatTaskId?: number
}

export interface GraphicsTask extends BaseTask {
  formType: 'graphic'
  sameCount?: number
  issueName?: string
  timing?: number
  extraTiming?: number
  airingDatetime: number
  montageDatetime?: number
  title?: string
  subtitle?: string
  taskTitle?: string
  executedGraphicsName?: string
  narration?: string
  programDesign?: number[]
  geo?: string
  locationOfGraphics?: string
}

export interface VizrtTask extends BaseTask {
  formType: 'vizrt-develop'
  whereIssued: string
  taskTitle: string
  tractDatetime: number
  releaseDatetime: number
}

export interface DesignTask extends BaseTask {
  formType: 'design-develop'
  taskTitle: string
  sketchDatetime?: number
  animaticDatetime?: number
  releaseDatetime: number
}

export interface VizrtDesignTask extends BaseTask {
  formType: 'design-develop-vizrt'
  whereIssued: string
  taskTitle: string
  sketchDatetime?: number
  animaticDatetime?: number
  tractDatetime: number
  releaseDatetime: number
}

export interface MapTask extends BaseTask {
  formType: 'need-map'
  taskTitle?: string
  mapDesign: string
  mediaFormat: string
  releaseDatetime: number
  timing: string
  title?: string
  narration?: string
}

export type Task =
  | GraphicsTask
  | VizrtTask
  | DesignTask
  | VizrtDesignTask
  | MapTask

export interface TaskInfo {
  id: number
  title: string
}

export interface TaskFilter {
  id: number
  name: string
  sort_index: number
}

export interface TaskFilters {
  [id: number]: TaskFilter
}

export type Sort =
  | 'status'
  | 'changedDatetime'
  | 'createdDatetime'
  | 'title'
  | 'graphicType'
export type Order = 'asc' | 'desc'

export interface GetTasksOptions {
  filterId: number
  offset?: number
  limit?: number
  sortParam?: Sort
  order?: Order
}

export interface FileInfo {
  fileId: number
  fileName: string
  contentType: string
  width?: number
  height?: number
  size: number
  src_url: string
  thumbnail?: string
  preview?: string
}

export type AttachedFiles = Record<string, FileInfo>

export interface Description {
  descriptionText: string
  textType: 'html' | 'bbcode' | 'plain-text'
  attachedFiles: AttachedFiles
}

export interface Comment {
  id: number
  author: UserInfo
  unread: boolean
  createdDate: number
  description: Description
  internal: boolean
}

type TaskStatusCode =
  | 'NOT_PROCESSED'
  | 'NEW'
  | 'REGISTRED'
  | 'PROGRESS'
  | 'PAUSED'
  | 'RESUMED'
  | 'DEFERRED'
  | 'DECLINED'
  | 'COMPLETED'
  | 'FINISHED'

type TaskColorCode =
  | 'red'
  | 'cerise'
  | 'ginger'
  | 'ocean-blue'
  | 'white-aluminium'
  | 'orange-red'
  | 'light-bluish-green'
  | 'very-light-yellow-green'

export interface TaskStatus {
  name: string
  code: TaskStatusCode
  color_code: TaskColorCode
}

export interface UserInfo {
  id: number
  formatted_name: string
  user_type: 'admin' | 'employee' | 'client'
  emails?: string[]
  catalogUrl?: string
  view_form_type?:
    | 'bx-light_without_logo'
    | 'bx-light_with_logo'
    | 'bx-light_need_map'
}

export interface TaskData {
  id: number
  title: string
  templateId: number
  clientTemplate: string
  createdDatetime: number
  closedDatetime?: number
  changedDatetime?: number
  clientProjectName: string
  status: TaskStatus
  newCommentsCount: number
  comments?: number[]
  createdBy?: UserInfo
  clientUser?: UserInfo
  responsibleUser: null | UserInfo
  deadline: number
  timing?: number
  extraTiming?: number
  parentTask?: { id: number; title: string } | null
  sameCount?: number
  graphicGroupType?: string
  description?: Description
  auditors: UserInfo[]
  canConfirmFinish: boolean
  graphicType: string
  clientProjectId?: number
  priority?: number
  canProgress?: boolean
  canPause?: boolean
  canFinish?: boolean
  canCancel?: boolean
  canSaveEmployeeWorks?: boolean
  clientCreatedFormRawData?: Task
  canRepeatTask?: boolean
  canChangeAuditors: boolean
  canChangeTemplate: boolean
  canChangeResponsible: boolean
}

export interface TasksResult extends Required<GetTasksOptions> {
  tasksList: TaskData[]
}

export interface SearchTaskResult {
  taskId: number
  title: string
  description: string
  dateChanged: number
}

export interface SubFormProps<T extends Task> {
  setAlertMessage: (message: string) => void
  selectedProgram: IProject
  selectedGraphic: SelectedGraphic
  graphicsKindId: string
  formName?: string
  additionalDescr?: string
  taskToRepeat?: T
  repeatTaskId?: number
  restoreData?: Record<string, any>
  prevGraphicsType?: MutableRefObject<string>
  onSubmit: (data: T, files?: File[]) => Promise<unknown>
}

export type GenericDataType = {
  id: number
  name: string
}

export type Template = GenericDataType
export type FullTemplate = {
  title: string
  id: number
  clientProjectId: number
  graphicGroupId: number
  graphicTypeId: number
  responsibleId: number
  useTiming: boolean
  timing: number
  useExtraTiming: boolean
  extraTiming: number
  departments: number[]
  auditors: number[]
  usePriority: boolean
  priority: Priority
}
export type ClientProject = GenericDataType & { active: boolean }
export type ParentTask = GenericDataType & { clientProjectId: number }
export type GraphicGroup = GenericDataType & { types: GenericDataType[] }
export type Department = GenericDataType & {
  subordination: string
  depth: number
  parent: number
}

export enum Priority {
  LOW,
  NORMAL,
  HIGH
}

export interface Notification {
  id: string
  date: string | number | Date
  text: string
  read: 'N' | 'Y'
}

interface ITaskCounter {
  code: 'emg_tasks_org_do' | 'emg_tasks_org_queue'
  value: number
}

export type TasksCounters = Array<ITaskCounter>
