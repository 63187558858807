import styles from './ListSkeleton.module.css'

export default function ListSkeleton() {
  return (
    <div>
      {Array(6)
        .fill(null)
        .map((_, idx) => (
          <Row key={idx} />
        ))}
    </div>
  )
}

function Row() {
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.project} />
        <div className={styles.date} />
      </div>
      <div className={styles.title} />
      <div className={styles.status} />
    </div>
  )
}
