import React from 'react'
import styles from './Search.module.css'
import { Option } from './types'

export default function Dropdown(props: {
  options: Option[]
  handleClick: (option: Option) => void
  isLoadingOptions?: boolean
}) {
  const { options, handleClick, isLoadingOptions } = props
  return (
    <div className={styles.dropdown} tabIndex={-1}>
      {isLoadingOptions ? (
        <div className={styles.listInfoItem}>Загрузка...</div>
      ) : options.length ? (
        options.map((option, index) => (
          <div
            key={index}
            className={styles.listItem}
            onClick={event => {
              event.stopPropagation()
              handleClick(option)
            }}
          >
            {option.value}
          </div>
        ))
      ) : (
        <div className={styles.listInfoItem}>Нет элементов</div>
      )}
    </div>
  )
}
