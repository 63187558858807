import { useCallback } from 'react'
import { extractContent } from '../../components/base/Editor'
import { DescriptionType } from '../../shared/models'

export default function useDescription(
  values: { description: string; descriptionType: DescriptionType },
  setValue: (key: keyof typeof values, value: string) => void,
  setMultipleValues: (vals: Partial<typeof values>) => void
) {
  const setDescription = useCallback(
    (value: string) => setValue('description', value),
    [setValue]
  )

  return {
    value: values.description,
    initialValue: values.description,
    setValue: setDescription,
    extendedEditor: values.descriptionType === 'html',
    setExtendedEditor: (value: boolean) => {
      setMultipleValues({
        descriptionType: value ? 'html' : 'text',
        description: value
          ? values.description
          : extractContent(values.description)
      })
    }
  }
}
