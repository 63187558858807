import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../../components/base/Button'
import Title from '../../components/base/Title'
import styles from './TaskCreated.module.css'

interface Props {
  taskId: string
  createAnotherTask: () => void
}

export default function TaskCreated(props: Props) {
  return (
    <>
      <Title>Заявка отправлена</Title>
      <div className={styles.infoBlock}>
        <p>
          Вы можете посмотеть свою заявку в списке задач или перейти по{' '}
          <Link className={styles.link} to={`/tasks/${props.taskId}`}>
            ссылке
          </Link>
        </p>
        <p>
          Вам на почту отправлено письмо с номером заявки. В ответ на это письмо
          вы можете писать вопросы и комментарии.
        </p>
      </div>
      <Button name='Заказать ещё' onClick={props.createAnotherTask} />
    </>
  )
}
