import React from 'react'
import { priorities } from '../../../shared/utils'
import styles from './PriorityInfo.module.css'

export default function PriorityInfo(props: {
  priority: number
  hideTitle?: boolean
  style?: React.CSSProperties
}) {
  return (
    <div className={styles.priorityContainer} style={props.style}>
      <div className={styles[`priority--${props.priority}`]} />
      {!props.hideTitle && (
        <span className={styles.priorityTitle}>
          {priorities[props.priority]}
        </span>
      )}
    </div>
  )
}
