import React, { useCallback, useContext } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { AuthContext } from '../../app/authContext'
import { Option, Search } from '../../components/base/Search'
import { changeTemplate, getTemplates } from '../../shared/apiService'
import { TaskData } from '../../shared/models'
import style from './TaskPage.module.css'

interface Props {
  task: Required<TaskData>
}

export default function TemplateBlock({ task }: Props) {
  const { handleError } = useContext(AuthContext)

  const queryKey = ['task', task.id.toString()]
  const queryClient = useQueryClient()
  const templateMutation = useMutation(
    (val?: Option) => changeTemplate(task.id, val?.id),
    {
      onError: handleError,
      onSettled: () => {
        queryClient.invalidateQueries(queryKey)
      }
    }
  )

  const getTemplatesOptsByQuery = useCallback(
    query =>
      getTemplates(query)
        .then(templates => {
          return templates.map(template => ({
            id: template.id,
            value: template.name
          }))
        })
        .catch(err => {
          handleError(err)
          return []
        }),
    [handleError]
  )

  const handleUpdateTemplate = async (value?: Option) => {
    await queryClient.cancelQueries(queryKey)
    const prevTask = queryClient.getQueryData(queryKey) as Required<TaskData>
    const templateId = value?.id ?? null
    const clientTemplate = value?.value ?? ''
    queryClient.setQueryData(queryKey, {
      ...prevTask,
      templateId,
      clientTemplate
    })
    templateMutation.mutate(value)
  }

  const selected = task.templateId
    ? { id: task.templateId, value: task.clientTemplate }
    : undefined

  return (
    <div className={style.auditorsRow}>
      <div className={style.auditorsTitle}>Шаблон:</div>
      <div className={style.infoDescription}>
        <Search
          placeholder='Шаблон задачи'
          selected={selected}
          options={getTemplatesOptsByQuery}
          updateSelected={handleUpdateTemplate}
          disableReset
        />
      </div>
    </div>
  )
}
