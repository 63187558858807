import React, { useContext } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { AuthContext } from '../../app/authContext'
import { Option, Search } from '../../components/base/Search'
import { changeResponsibleUser } from '../../shared/apiService'
import { TaskData, UserInfo } from '../../shared/models'
import style from './TaskPage.module.css'

interface Props {
  task: Required<TaskData>
  responsibleUsers: UserInfo[]
}

export default function ResponsibleUserBlock({
  task,
  responsibleUsers
}: Props) {
  const { handleError } = useContext(AuthContext)

  const queryKey = ['task', task.id.toString()]
  const queryClient = useQueryClient()
  const responsibleUserMutation = useMutation(
    (val?: Option) => changeResponsibleUser(task.id, val?.id),
    {
      onError: handleError,
      onSettled: () => {
        queryClient.invalidateQueries(queryKey)
      }
    }
  )

  const handleUpdateResponsible = async (value?: Option) => {
    await queryClient.cancelQueries(queryKey)
    const prevTask = queryClient.getQueryData(queryKey) as Required<TaskData>
    const responsibleUser = value
      ? responsibleUsers.find(user => user.id === value.id)
      : null
    queryClient.setQueryData(queryKey, { ...prevTask, responsibleUser })
    responsibleUserMutation.mutate(value)
  }

  const responsibleUsersOptions = responsibleUsers.map(user => ({
    id: user.id,
    value: user.formatted_name
  }))

  const selected = task.responsibleUser
    ? {
        id: task.responsibleUser.id,
        value: task.responsibleUser.formatted_name
      }
    : undefined

  return (
    <div className={style.auditorsRow}>
      <div className={style.auditorsTitle}>Исполнитель:</div>
      <div className={style.infoDescription}>
        <Search
          placeholder='Добавить исполнителя'
          selected={selected}
          updateSelected={handleUpdateResponsible}
          options={responsibleUsersOptions}
        />
      </div>
    </div>
  )
}
