import classNames from 'classnames'
import React from 'react'
import InputMask from 'react-input-mask'
import { isMobileDevice } from '../../shared/utils'
import baseStyles from './Base.module.css'
import styles from './TimeInput.module.css'

const DEFAULT_TIME = '00:00'

interface TimeInputProps {
  name: string
  time: string
  onChange: (time: string) => void
  isValid?: boolean
  disabled?: boolean
}

export default React.forwardRef<HTMLInputElement, TimeInputProps>(
  function TimeInput(props, ref) {
    const { isValid = true, disabled = false, onChange, time } = props

    const update = (event: React.ChangeEvent<HTMLInputElement>) => {
      // ios safari clear button fix
      // https://github.com/facebook/react/issues/8938
      const nativeTarget = event.nativeEvent.target as HTMLInputElement
      setTimeout(() => {
        nativeTarget.defaultValue = DEFAULT_TIME
      }, 0)
      onChange(event.target.value || DEFAULT_TIME)
    }

    const clear = () => {
      onChange(DEFAULT_TIME)
    }

    const mask = [
      /[0-2]/,
      time[0] === '2' ? /[0-3]/ : /[0-9]/,
      ':',
      /[0-5]/,
      /[0-9]/
    ]

    const className = classNames(baseStyles.controlInput, styles.time, {
      [baseStyles.controlInputInvalid]: !isValid,
      [baseStyles.controlInputDisabled]: disabled,
      [styles.container]: !isMobileDevice()
    })

    return (
      <div className={styles.outerContainer}>
        {isMobileDevice() ? (
          <input
            ref={ref}
            type='time'
            value={time}
            disabled={disabled}
            onChange={update}
            placeholder={DEFAULT_TIME}
            className={className}
            required
          />
        ) : (
          <div className={className} ref={ref}>
            <InputMask
              mask={mask}
              alwaysShowMask
              maskPlaceholder='0'
              value={time}
              onChange={update}
              disabled={disabled}
              data-testid={props.name}
            />
            <button className={baseStyles.deleteButton} onClick={clear} />
          </div>
        )}
      </div>
    )
  }
)
