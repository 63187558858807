import React, { useEffect } from 'react'
import styles from './Alert.module.css'

interface AlertProps {
  message: string
  close: () => void
}

export default function Alert(props: AlertProps) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [props.message])
  return (
    <div className={styles.alert} onClick={props.close}>
      <div className={styles.container}>
        <div className={styles.message}>{props.message}</div>
      </div>
    </div>
  )
}
