import React from 'react'
import { useHistory } from 'react-router-dom'
import styles from './SearchBarMini.module.css'

export default function SearchBarMini() {
  const history = useHistory()
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    history.replace('/search?query=' + event.target.value)
  }
  return (
    <input
      placeholder='Поиск...'
      className={styles.search}
      onChange={handleChange}
      ref={node => node?.focus()}
    />
  )
}
