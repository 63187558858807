import React, { useEffect, useMemo, useRef } from 'react'
import Button from '../../components/base/Button'
import Chronometer from '../../components/base/Chronometer'
import DateTime from '../../components/base/DateTime'
import Editor from '../../components/base/Editor'
import FileInput from '../../components/base/FileInput'
import { validateEditorState } from '../../components/base/HtmlEditor'
import { MultiSearch, Option } from '../../components/base/Search/'
import Select from '../../components/base/Select'
import TextArea from '../../components/base/TextArea'
import TextField from '../../components/base/TextField'
import { getTimeStampInSecs } from '../../shared/dateUtils'
import {
  DescriptionType,
  GraphicsTask,
  Priority,
  SubFormProps
} from '../../shared/models'
import {
  DateValidators,
  StringValidators,
  validateDate,
  validateString
} from '../../shared/validators'
import {
  getAuditorOptions,
  getFormFields,
  getLocationOptions,
  getPriorityOptions,
  getProgramDesignOptions,
  initAuditors,
  initDatetime,
  initPriority
} from './formUtils'
import useDescription from './useDescription'
import { useForm } from './useForm'
import {
  cleanString,
  getSavedValue,
  saveDateForAutoComplete,
  saveValueForAutoComplete
} from './utils'

interface Values {
  taskTitle: string
  sameCount: string
  issueName: string
  executedGraphicsName: string
  geo: string
  locationOfGraphics: string
  timing: string
  extraTiming: string
  airingDatetime?: Date
  montageDatetime?: Date
  title: string
  subtitle: string
  description: string
  descriptionType: DescriptionType
  offscreenText: string
  programDesign: Option[]
  selectedAuditors: { id: number; value: string }[]
  priority: Priority
  files: File[]
}

function initValues(props: SubFormProps<GraphicsTask>): Values {
  const { taskToRepeat, restoreData, selectedProgram } = props
  const formFields = props.selectedGraphic.form_fields

  function getOldVersionProgramDesign() {
    const programDesign = formFields?.programDesign?.values?.find(design => {
      return +design.id === +taskToRepeat?.programDesign!
    })

    return [
      {
        id: props.taskToRepeat?.programDesign,
        value: programDesign?.name
      }
    ]
  }
  return {
    taskTitle:
      props.taskToRepeat?.taskTitle ?? props.restoreData?.taskTitle ?? '',
    sameCount:
      props.taskToRepeat?.sameCount?.toString() ??
      props.restoreData?.sameCount ??
      formFields?.sameCount?.default_value ??
      '',
    issueName:
      props.taskToRepeat?.issueName ??
      props.restoreData?.issueName ??
      getSavedValue('issueName'),
    executedGraphicsName:
      props.taskToRepeat?.executedGraphicsName ??
      props.restoreData?.executedGraphicsName ??
      '',
    geo: props.taskToRepeat?.geo ?? props.restoreData?.geo ?? '',
    locationOfGraphics:
      props.taskToRepeat?.locationOfGraphics ??
      props.restoreData?.locationOfGraphics ??
      '',
    timing:
      props.taskToRepeat?.timing?.toString() ??
      props.restoreData?.timing ??
      formFields?.timing?.default_value ??
      '',
    extraTiming:
      props.taskToRepeat?.extraTiming?.toString() ??
      props.restoreData?.extraTiming ??
      formFields?.extraTiming?.default_value ??
      '',
    airingDatetime: initDatetime(
      props.taskToRepeat?.airingDatetime,
      props.restoreData?.airingDatetime,
      'airingDatetime'
    ),
    montageDatetime: initDatetime(
      props.taskToRepeat?.montageDatetime,
      props.restoreData?.montageDatetime,
      'montageDatetime'
    ),
    title: props.taskToRepeat?.title ?? props.restoreData?.title ?? '',
    subtitle: props.taskToRepeat?.subtitle ?? props.restoreData?.subtitle ?? '',
    offscreenText:
      props.taskToRepeat?.narration ?? props.restoreData?.offscreenText ?? '',
    programDesign: props.taskToRepeat?.programDesign
      ? Array.isArray(props.taskToRepeat?.programDesign)
        ? getProgramDesignOptions(formFields!).filter(option =>
            props.taskToRepeat?.programDesign?.includes(option.id)
          )
        : getOldVersionProgramDesign()
      : props.restoreData?.programDesign ?? [],
    selectedAuditors: initAuditors(selectedProgram, taskToRepeat, restoreData),
    priority: initPriority(taskToRepeat, restoreData, formFields),
    description: taskToRepeat?.description ?? restoreData?.description ?? '',
    descriptionType:
      taskToRepeat?.descriptionType ?? restoreData?.descriptionType ?? 'html',
    files: []
  }
}

function validateValues(values: Values, props: SubFormProps<GraphicsTask>) {
  const formFields = getFormFields(props)
  return {
    timing: validateString(
      values.timing,
      [
        StringValidators.notEmpty,
        StringValidators.isNumber,
        StringValidators.isInteger,
        StringValidators.isPositive,
        StringValidators.maxValue(86400)
      ],
      'timing' in formFields
    ),
    extraTiming: validateString(
      values.extraTiming,
      [
        StringValidators.isNumber,
        StringValidators.isInteger,
        StringValidators.isNotNegative,
        StringValidators.maxValue(3600)
      ],
      'extraTiming' in formFields && !!values.extraTiming.length
    ),
    sameCount: validateString(
      values.sameCount,
      [
        StringValidators.notEmpty,
        StringValidators.isNumber,
        StringValidators.isInteger,
        StringValidators.isPositive,
        StringValidators.maxValue(100)
      ],
      'sameCount' in formFields
    ),
    airingDatetime: validateDate(values.airingDatetime, [
      DateValidators.notEmpty,
      DateValidators.notPast
    ]),
    montageDatetime: validateDate(values.montageDatetime, [
      ...(values.montageDatetime ? [DateValidators.notEmpty] : []),
      DateValidators.notPast,
      DateValidators.before(
        values.airingDatetime,
        'Не должно быть позже даты эфира'
      )
    ]),
    title: validateString(
      values.title,
      [StringValidators.notEmpty, StringValidators.maxLength(100)],
      'title' in formFields
    ),
    subtitle: validateString(
      values.subtitle,
      [StringValidators.notEmpty, StringValidators.maxLength(50)],
      'subtitle' in formFields && !!values.subtitle.length
    ),
    description: validateEditorState(values.description),
    taskTitle: validateString(
      values.taskTitle,
      [StringValidators.notEmpty, StringValidators.maxLength(50)],
      'taskTitle' in formFields
    ),
    issueName: validateString(
      values.issueName,
      [StringValidators.notEmpty, StringValidators.maxLength(50)],
      'issueName' in formFields
    ),
    executedGraphicsName: validateString(
      values.executedGraphicsName,
      [StringValidators.notEmpty, StringValidators.maxLength(50)],
      'executedGraphicsName' in formFields &&
        !!values.executedGraphicsName.length
    ),
    programDesign:
      'programDesign' in formFields
        ? values.programDesign.length
          ? undefined
          : 'Не должно быть пустым'
        : undefined,
    locationOfGraphics: validateString(
      values.locationOfGraphics,
      [StringValidators.notEmpty],
      'locationOfGraphics' in formFields
    )
  }
}

function prepareValues(
  values: Values,
  props: SubFormProps<GraphicsTask>
): GraphicsTask {
  const formFields = getFormFields(props)
  const dataToSend: GraphicsTask = {
    formType: 'graphic',
    projectId: props.selectedProgram.id.toString(),
    graphicId: props.selectedGraphic.id.toString(),
    airingDatetime: getTimeStampInSecs(values.airingDatetime!),
    descriptionType: values.descriptionType,
    description: values.description,
    auditors: values.selectedAuditors.map(auditor => auditor.id)
  }
  if (values.montageDatetime)
    dataToSend.montageDatetime = getTimeStampInSecs(values.montageDatetime)
  if (values.offscreenText)
    dataToSend.narration = cleanString(values.offscreenText)
  if ('taskTitle' in formFields)
    dataToSend.taskTitle = cleanString(values.taskTitle)
  if ('issueName' in formFields)
    dataToSend.issueName = cleanString(values.issueName)
  if ('title' in formFields) dataToSend.title = cleanString(values.title)
  if ('subtitle' in formFields && values.subtitle)
    dataToSend.subtitle = cleanString(values.subtitle)
  if ('executedGraphicsName' in formFields && values.executedGraphicsName)
    dataToSend.executedGraphicsName = cleanString(values.executedGraphicsName)
  if ('geo' in formFields && values.geo) dataToSend.geo = values.geo
  if ('locationOfGraphics' in formFields)
    dataToSend.locationOfGraphics = values.locationOfGraphics
  if ('sameCount' in formFields) dataToSend.sameCount = +values.sameCount
  if ('timing' in formFields) {
    dataToSend.timing = +values.timing
    if ('extraTiming' in formFields && values.extraTiming)
      dataToSend.extraTiming = +values.extraTiming
  }
  if ('programDesign' in formFields)
    dataToSend.programDesign = values.programDesign.map(design => design.id)
  if ('priority' in formFields) dataToSend.priority = values.priority

  if (props.additionalDescr) dataToSend.additionalDescr = props.additionalDescr
  if (props.formName) dataToSend.formName = props.formName
  if (props.repeatTaskId) dataToSend.repeatTaskId = props.repeatTaskId

  return dataToSend
}

export default function GraphicsForm(props: SubFormProps<GraphicsTask>) {
  const {
    values,
    errors,
    setValue,
    setMultipleValues,
    isValid,
    allValid,
    handleSubmit,
    isLoading
  } = useForm(
    () => initValues(props),
    values => validateValues(values, props),
    values => props.onSubmit(prepareValues(values, props), values.files),
    props.selectedProgram.id.toString(),
    props.selectedGraphic.id.toString(),
    props.graphicsKindId
  )

  const descriptionProps = useDescription(values, setValue, setMultipleValues)

  const sameCountRef = useRef<HTMLDivElement>(null)
  const taskTitleRef = useRef<HTMLDivElement>(null)
  const issueNameRef = useRef<HTMLDivElement>(null)
  const executedGraphicsNameRef = useRef<HTMLDivElement>(null)
  const timingRef = useRef<HTMLDivElement>(null)
  const extraTimingRef = useRef<HTMLDivElement>(null)
  const airingDatetimeRef = useRef<HTMLDivElement>(null)
  const montageDatetimeRef = useRef<HTMLDivElement>(null)
  const titleRef = useRef<HTMLDivElement>(null)
  const subtitleRef = useRef<HTMLDivElement>(null)
  const descriptionRef = useRef<HTMLDivElement>(null)
  const programDesignRef = useRef<HTMLDivElement>(null)
  const locationRef = useRef<HTMLDivElement>(null)

  const refs: Record<
    string,
    React.RefObject<HTMLDivElement | HTMLInputElement>
  > = {
    sameCount: sameCountRef,
    taskTitle: taskTitleRef,
    issueName: issueNameRef,
    executedGraphicsName: executedGraphicsNameRef,
    locationOfGraphics: locationRef,
    timing: timingRef,
    extraTiming: extraTimingRef,
    airingDatetime: airingDatetimeRef,
    montageDatetime: montageDatetimeRef,
    title: titleRef,
    subtitle: subtitleRef,
    description: descriptionRef,
    programDesign: programDesignRef
  }

  const submitHandler = (event: React.FormEvent) => {
    if (allValid) {
      saveValueForAutoComplete('issueName', values.issueName)
      saveValueForAutoComplete('taskTitle', values.taskTitle)
      saveDateForAutoComplete('airingDatetime', values.airingDatetime!)
      if (values.montageDatetime) {
        saveDateForAutoComplete('montageDatetime', values.montageDatetime)
      }
    } else {
      const invalidEntry = Object.entries(refs).find(
        ([key]) => !!errors[key as keyof Values]
      )
      if (invalidEntry) {
        const [, invalidRef] = invalidEntry
        const firstInvalidElement = invalidRef.current!
        setTimeout(
          () => firstInvalidElement.scrollIntoView({ behavior: 'smooth' }),
          100
        )
      }
    }
    return handleSubmit(event)
  }

  const formFields = getFormFields(props)
  const auditorOptions = getAuditorOptions(props.selectedProgram)
  const priorityOptions = getPriorityOptions(formFields)
  const programDesignOptions = getProgramDesignOptions(formFields)
  const locationOptions = getLocationOptions(formFields)

  useEffect(() => {
    if (
      props.prevGraphicsType &&
      props.prevGraphicsType.current &&
      parseInt(props.prevGraphicsType.current) !== +props.selectedGraphic.id
    ) {
      setMultipleValues({
        timing: formFields?.timing?.default_value ?? '',
        extraTiming: formFields?.extraTiming?.default_value ?? ''
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedGraphic.id])

  const defaultMontageDatetime = useMemo(() => {
    if (props.selectedGraphic.form_fields?.airingDatetime) {
      const defaultTime = new Date(
        +props.selectedGraphic.form_fields?.montageDatetime?.default_value! *
          1000
      )
      setValue('montageDatetime', defaultTime)
      return defaultTime
    }
    return undefined
  }, [
    props.selectedGraphic.form_fields?.airingDatetime,
    props.selectedGraphic.form_fields?.montageDatetime?.default_value,
    setValue
  ])

  const defaultAiringDatetime = useMemo(() => {
    if (props.selectedGraphic.form_fields?.airingDatetime) {
      const defaultTime = new Date(
        +props.selectedGraphic.form_fields?.airingDatetime?.default_value! *
          1000
      )
      setValue('airingDatetime', defaultTime)
      return defaultTime
    }
    return undefined
  }, [props.selectedGraphic.form_fields?.airingDatetime, setValue])

  return (
    <form onSubmit={submitHandler}>
      {'sameCount' in formFields && (
        <TextField
          type='text'
          value={values.sameCount}
          name='sameCount'
          isValid={isValid('sameCount')}
          validationMessage={errors.sameCount}
          label='Количество'
          inputMode='decimal'
          placeholder='1'
          onChange={event => setValue('sameCount', event.target.value)}
          ref={refs.sameCount}
          required
        />
      )}
      {'taskTitle' in formFields && (
        <TextField
          type='text'
          name='taskTitle'
          isValid={isValid('taskTitle')}
          validationMessage={errors.taskTitle}
          label='Название заявки'
          value={values.taskTitle}
          onChange={event => setValue('taskTitle', event.target.value)}
          ref={refs.taskTitle}
          required
          hint='Это название будет заголовком / темой письма'
          autoComplete
        />
      )}
      {'issueName' in formFields && (
        <TextField
          type='text'
          name='issueName'
          isValid={isValid('issueName')}
          validationMessage={errors.issueName}
          label='Тема выпуска'
          value={values.issueName}
          placeholder='День народного единства'
          onChange={event => setValue('issueName', event.target.value)}
          ref={refs.issueName}
          required
          autoComplete
        />
      )}
      {'executedGraphicsName' in formFields && (
        <TextField
          type='text'
          name='executedGraphicsName'
          label='Название готовой графики'
          value={values.executedGraphicsName}
          placeholder='FLAT_SLAIDY_PAR'
          onChange={event =>
            setValue('executedGraphicsName', event.target.value)
          }
          ref={refs.executedGraphicsName}
          isValid={isValid('executedGraphicsName')}
          validationMessage={errors.executedGraphicsName}
        />
      )}
      {'geo' in formFields && (
        <TextField
          type='text'
          name='geo'
          label='Гео'
          value={values.geo}
          onChange={event => setValue('geo', event.target.value)}
        />
      )}
      {'locationOfGraphics' in formFields && (
        <Select
          label='Расположение графики'
          value={values.locationOfGraphics}
          options={locationOptions}
          onChange={event => setValue('locationOfGraphics', event.target.value)}
          ref={refs.locationOfGraphics}
          required
          isValid={isValid('locationOfGraphics')}
          validationMessage={errors.locationOfGraphics}
        />
      )}
      {'timing' in formFields && (
        <TextField
          type='text'
          value={values.timing}
          name='chronometry'
          isValid={isValid('timing')}
          validationMessage={errors.timing}
          label='Хронометраж (сек)'
          inputMode='decimal'
          placeholder='30'
          onChange={event => setValue('timing', event.target.value)}
          ref={refs.timing}
          required
        />
      )}
      {'extraTiming' in formFields && 'timing' in formFields && (
        <TextField
          type='text'
          value={values.extraTiming}
          name='extraTiming'
          isValid={isValid('extraTiming')}
          validationMessage={errors.extraTiming}
          label='Захлёст (сек)'
          inputMode='decimal'
          onChange={event => setValue('extraTiming', event.target.value)}
          ref={refs.extraTiming}
        />
      )}
      <DateTime
        label='Дата и время эфира'
        name='airing'
        ref={refs.airingDatetime}
        value={values.airingDatetime || defaultAiringDatetime}
        setValue={value => setValue('airingDatetime', value)}
        required
        isValid={isValid('airingDatetime')}
        validationMessage={errors.airingDatetime}
      />
      <DateTime
        label='Дата и время монтажа'
        name='montage'
        ref={refs.montageDatetime}
        value={values.montageDatetime || defaultMontageDatetime}
        setValue={value => setValue('montageDatetime', value)}
        endDate={values.airingDatetime}
        isValid={isValid('montageDatetime')}
        validationMessage={errors.montageDatetime}
      />
      {'title' in formFields && (
        <TextField
          type='text'
          name='title'
          isValid={isValid('title')}
          validationMessage={errors.title}
          label='Заголовок'
          value={values.title}
          placeholder='Ограничение движения'
          onChange={event => setValue('title', event.target.value)}
          ref={refs.title}
          required
        />
      )}
      {'subtitle' in formFields && (
        <TextField
          type='text'
          name='subtitle'
          label='Подзаголовок'
          value={values.subtitle}
          placeholder='4 ноября с 16:00 до 20:00'
          onChange={event => setValue('subtitle', event.target.value)}
          ref={refs.subtitle}
          isValid={isValid('subtitle')}
          validationMessage={errors.subtitle}
        />
      )}
      {'programDesign' in formFields && (
        <MultiSearch
          label='Оформление'
          placeholder='Выберите оформление'
          selected={values.programDesign}
          updateSelected={values => {
            setValue('programDesign', values)
          }}
          options={programDesignOptions}
          ref={refs.programDesign}
          isValid={isValid('programDesign')}
          validationMessage={errors.programDesign}
          required
        />
      )}
      <Editor
        {...descriptionProps}
        label='Описание заказа'
        placeholder='Все подписи и названия указать в том виде, в котором они должны быть на графике'
        required
        isValid={isValid('description')}
        validationMessage={errors.description}
        forwardedRef={refs.description}
      />
      <TextArea
        name='offscreenText'
        label='Закадровый текст'
        onChange={event => setValue('offscreenText', event.target.value)}
        value={values.offscreenText}
      />
      <Chronometer text={values.offscreenText} />
      {'priority' in formFields && (
        <Select
          value={values.priority}
          name='priority'
          label='Приоритет'
          onChange={event => setValue('priority', +event.target.value)}
          options={priorityOptions}
        />
      )}
      {!!auditorOptions.length && (
        <MultiSearch
          label=' '
          options={auditorOptions}
          selected={values.selectedAuditors}
          updateSelected={value => setValue('selectedAuditors', value)}
          placeholder='Добавить наблюдателей'
        />
      )}
      <FileInput
        files={values.files}
        handleAcceptedFiles={value => setValue('files', value)}
        showAlert={props.setAlertMessage}
      />
      <Button
        name='Отправить'
        type='submit'
        status={isLoading ? 'loading' : 'enabled'}
      />
    </form>
  )
}
