import classNames from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'
import { formatDateRelative, getDateFrom } from '../../../shared/dateUtils'
import { TaskData } from '../../../shared/models'
import PriorityInfo from '../List/PriorityInfo'
import taskStyles from '../List/Task.module.css'
import styles from './TaskTable.module.css'

interface TaskRowProps {
  task: TaskData
}

export const TaskRow = React.forwardRef<HTMLTableRowElement, TaskRowProps>(
  function ({ task }, ref) {
    return (
      <tr ref={ref} className={styles.row}>
        <td className={styles.cell}>
          <div className={styles.taskTitleContainer}>
            <Link
              to={`/tasks/${task.id}`}
              key={task.id}
              className={styles.taskTitle}
            >
              {task.title}
              <div className={taskStyles.comments} style={{ marginLeft: 10 }}>
                {task.newCommentsCount ? `+${task.newCommentsCount}` : null}
              </div>
            </Link>
            {typeof task.priority === 'number' && (
              <PriorityInfo
                priority={task.priority}
                style={{ marginLeft: 15 }}
                hideTitle
              />
            )}
          </div>
        </td>
        <td className={styles.cell}>
          <div
            className={classNames(
              taskStyles[`status--${task.status.code}`],
              styles.statusBadge
            )}
            style={{ margin: 0 }}
          >
            {task.status.name}
          </div>
        </td>
        <td className={styles.cell}>{task.clientProjectName || '—'}</td>
        <td className={styles.cell}>{task.clientTemplate}</td>
        <td className={styles.cell}>{task.responsibleUser?.formatted_name}</td>
        <td className={styles.cell}>
          {task.deadline &&
            formatDateRelative(getDateFrom(task.deadline), true)}
        </td>
        <td className={styles.dateCell}>
          {task.changedDatetime &&
            formatDateRelative(getDateFrom(task.changedDatetime), true)}
        </td>
      </tr>
    )
  }
)
