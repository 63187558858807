import React, { useCallback, useContext, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthContext } from '../../../app/authContext'
import Button from '../../../components/base/Button'
import Title from '../../../components/base/Title'
import useCallbackOnVisible from '../../../shared/hooks/useCallbackOnVisible'
import useClearAlertOnUnmount from '../../../shared/hooks/useClearAlertOnUnmount'
import { taskCountersUsers } from '../constants'
import Filters from '../Filters/Filters'
import useChannels, { MessageData, SubscriptionParams } from '../useChannels'
import { useTaskFilters } from '../useFilters'
import { useTasks } from '../useTasks'
import useTasksCounters from '../useTasksCounters'
import { updateTasksCounters } from '../utils'
import { ColumnButton } from './ColumnButton'
import { SkeletonRows, TableSkeleton } from './TableSkeleton'
import { TaskRow } from './TaskRow'
import TasksCounters from './TasksCounters'
import styles from './TaskTable.module.css'

const sortTypes: [string, string, boolean][] = [
  ['Название', 'title', true],
  ['Статус', 'status', true],
  ['Проект', 'project', false],
  ['Шаблон', 'clientTemplate', false],
  ['Исполнитель', 'responsibleUser', false],
  ['Крайний срок', 'deadline', false],
  ['Время изменения', 'changedDatetime', true]
]

export default function TaskTable() {
  const { userType, userId } = useContext(AuthContext)
  const history = useHistory()

  const { filters, filter, setFilter, sort, setSort, order, setOrder } =
    useTaskFilters()

  const {
    tasks,
    loadNext,
    refetch,
    allLoaded,
    isFetchingNextPage,
    isLoading: tasksLoading,
    isSuccess: tasksReady
  } = useTasks(filter, sort, order)

  const { tasksCountersData, tasksCountersState, setTasksCountersState } =
    useTasksCounters()

  const callbackRef = useCallbackOnVisible(loadNext)

  useClearAlertOnUnmount()

  const handleChannelMessage = useCallback(
    (msgData: MessageData) => {
      if (['task_status_change', 'comment'].includes(msgData.command)) {
        refetch()
      }
      if (msgData.params.s1) {
        Object.keys(msgData.params.s1).forEach(key => {
          updateTasksCounters(setTasksCountersState, msgData, key)
        })
      }
    },
    [refetch, setTasksCountersState]
  )

  const subscriptionParams = useMemo<SubscriptionParams | undefined>(
    () =>
      tasks.length
        ? { type: 'tasks_comments', taskId: tasks.map(({ id }) => id) }
        : undefined,
    [tasks]
  )

  const isTasksCountersShowing =
    tasksCountersData && taskCountersUsers.includes(userId)

  useChannels(handleChannelMessage, subscriptionParams)

  return (
    <div className={styles.pageContainer}>
      <div className={styles.tableTitleContainer}>
        <Title>
          {userType === 'client' ? 'Список заказов' : 'Список задач'}
          {isTasksCountersShowing && (
            <TasksCounters tasksCountersState={tasksCountersState} />
          )}
        </Title>
        <Button
          name={userType === 'client' ? 'Сделать заказ' : 'Поставить задачу'}
          onClick={() => history.push('/order')}
          className={styles.createTaskButton}
        />
      </div>

      <Filters filter={filter} setFilter={setFilter} filters={filters} />
      {tasksLoading ? (
        <TableSkeleton />
      ) : (
        <table className={styles.table}>
          <thead>
            <tr className={styles.header}>
              {sortTypes.map(([name, id, sortable]) => (
                <th key={id} className={styles.headerTitle}>
                  <ColumnButton
                    rowId={id}
                    rowName={name}
                    currentSortId={sort}
                    currentOrder={order}
                    updateOrder={setOrder}
                    updateSort={setSort}
                    sortDisabled={!sortable}
                  />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tasks.map((task, idx) => (
              <TaskRow
                key={task.id}
                task={task}
                ref={
                  !allLoaded && idx === tasks.length - 1 ? callbackRef : null
                }
              />
            ))}
          </tbody>
        </table>
      )}
      {isFetchingNextPage && <SkeletonRows />}
      {tasksReady && !tasks.length && (
        <h3 className={styles.noTasks}>Нет задач</h3>
      )}
    </div>
  )
}
