import { TasksCountersObject } from "./Table/types"
import { MessageData } from "./useChannels"

export function updateTasksCounters(
    setState: React.Dispatch<React.SetStateAction<TasksCountersObject>>,
    msgData: MessageData,
    code: string
  ) {
    if (
      msgData.params.s1[code] &&
      ['emg_tasks_org_do', 'emg_tasks_org_queue'].includes(code)
    ) {
      setState(prev => {
        return {
          ...prev,
          [code]: msgData.params.s1[code]
        }
      })
    }
  }
