import { useCallback, useEffect, useRef, useState } from 'react'
import { searchTasks } from '../../shared/apiService'
import { SearchTaskResult } from '../../shared/models'

export default function useSearch() {
  const [query, setQuery] = useState('')
  const [results, setResults] = useState<SearchTaskResult[]>([])
  const [loading, setLoading] = useState(false)

  const timeout = useRef<number>()

  useEffect(() => {
    window.clearTimeout(timeout.current)

    if (query) {
      timeout.current = window.setTimeout(async () => {
        setLoading(true)
        const searchResults = await searchTasks(query)
        setResults(searchResults)
        setLoading(false)
      }, 1000)
    } else {
      setResults([])
    }
  }, [query])

  const clearQuery = useCallback(() => {
    window.clearTimeout(timeout.current)
    setLoading(false)
    setQuery('')
    setResults([])
  }, [])

  return { query, setQuery, results, loading, clearQuery }
}
