import React from 'react'
import styles from '../Base.module.css'

interface Props {
  validationMessage?: string
}

export default function Error(props: Props) {
  return (
    <div role='alert' className={styles.validationMessage}>
      {props.validationMessage}
    </div>
  )
}
