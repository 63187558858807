import React, { useState } from 'react'
import moreIcon from '../../../icons/more.svg'
import { TaskFilter } from '../../../shared/models'
import Dropdown from './Dropdown'
import FilterButton from './FilterButton'
import styles from './Filters.module.css'

const MAX_FILTERS_IN_LINE = 7

interface Props {
  filter: number | undefined
  setFilter: (filter: number) => void
  filters: TaskFilter[]
}

export default function Filters({ filter, setFilter, filters }: Props) {
  const [dropdownVisible, setDropdownVisible] = useState(false)

  const handleFilterChange = (filterId: number) => {
    setDropdownVisible(false)
    setFilter(filterId)
  }

  const handleToggleDropdown = () => {
    setDropdownVisible(!dropdownVisible)
  }

  let mainFilterItems = filters.slice()
  let moreFilterItems: TaskFilter[] = []
  const shouldCollapseFilters = filters.length > MAX_FILTERS_IN_LINE
  if (shouldCollapseFilters) {
    mainFilterItems = filters.slice(0, MAX_FILTERS_IN_LINE)
    moreFilterItems = filters.slice(MAX_FILTERS_IN_LINE)
  }

  return (
    <nav className={styles.container}>
      {filters.length ? (
        mainFilterItems.map(filterItem => (
          <FilterButton
            key={filterItem.id}
            filter={filterItem}
            handleFilterChange={handleFilterChange}
            isCurrent={filter === filterItem.id}
          />
        ))
      ) : (
        <div className={styles.filterSkeleton} />
      )}
      {shouldCollapseFilters && (
        <div className={styles.moreFiltersContainer}>
          <button onClick={handleToggleDropdown} className={styles.moreButton}>
            Еще
            <img src={moreIcon} alt='Еще' className={styles.moreIcon} />
          </button>
          {dropdownVisible && (
            <Dropdown
              filters={moreFilterItems}
              currentFilter={filter}
              handleFilterChange={handleFilterChange}
            />
          )}
        </div>
      )}
    </nav>
  )
}
