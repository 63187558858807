import React, { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import ImagePreview from '../../components/ImagePreview'
import { AttachedFiles, Description } from '../../shared/models'
import { startFileDownload } from '../../shared/utils'
import styles from './DescriptionHtml.module.css'
import useLoadFile from './useLoadFile'

function removeBlockquote(str: string) {
  const r = new RegExp(String.raw`<blockquote[\0-\uFFFF]+</blockquote>`, 'g')
  return str.replace(r, '')
}

function removeEmptyDivs(str: string) {
  const r = new RegExp(String.raw`<div>\s*</div>`, 'g')
  return str.replace(r, '')
}

function substituteFileLink(str: string, attachedFiles: AttachedFiles) {
  const regex = new RegExp(/\[DISK FILE ID=(\d+)\]/)
  let copyStr = str.slice()

  while (regex.test(copyStr)) {
    const [, fileId] = copyStr.match(regex)!
    const attachedFile = attachedFiles[+fileId]

    // if (for some reason) DISK FILE ID is not matched with anything
    const replacement = attachedFile
      ? `<span data-fileid="${attachedFile.fileId}" data-filename="${attachedFile.fileName}"></span>`
      : ''
    copyStr = copyStr.replace(regex, replacement)
  }

  return copyStr
}

function cleanupDescriptionHtml(
  str: string,
  attachedFiles: AttachedFiles,
  clearBlockquote = true
) {
  return substituteFileLink(
    removeEmptyDivs(clearBlockquote ? removeBlockquote(str) : str),
    attachedFiles
  )
}

interface FileContainerInfo {
  container: HTMLElement
  fileId: string
  fileName: string
  preview?: string
}

function FileLink({ info }: { info: FileContainerInfo }) {
  const [url, setUrl] = useState('')
  const [previewVisible, setPreviewVisible] = useState(false)

  const handleFileLoad = useLoadFile(+info.fileId)

  const showFile = () => {
    setPreviewVisible(true)
    handleFileLoad().then(url => {
      if (url) {
        setUrl(url)
      } else {
        setPreviewVisible(false)
      }
    })
  }

  const downloadFile = () => {
    handleFileLoad().then(fileUrl => {
      if (fileUrl) {
        startFileDownload(info.fileName, fileUrl)
      }
    })
  }

  return createPortal(
    info.preview ? (
      <>
        <img
          src={info.preview}
          alt={info.fileName}
          className={styles.imgLink}
          onClick={showFile}
        />
        {previewVisible && (
          <ImagePreview
            imageUrl={url}
            fileName={info.fileName}
            close={() => setPreviewVisible(false)}
          />
        )}
      </>
    ) : (
      <button onClick={downloadFile} className={styles.fileLink}>
        {info.fileName}
      </button>
    ),
    info.container
  )
}

export default function DescriptionHtml({
  description,
  className,
  clearBlockquote
}: {
  description: Description
  className?: string
  clearBlockquote?: boolean
}) {
  const containerRef = useRef<HTMLDivElement>(null!)
  const [fileContainerInfos, setFileContainerInfos] = useState<
    FileContainerInfo[]
  >([])

  useEffect(() => {
    const spans = containerRef.current.querySelectorAll('span[data-fileid]')
    setFileContainerInfos(
      Array.from(spans as NodeListOf<HTMLElement>, container => {
        const fileId = container.getAttribute('data-fileid')!
        const fileName = container.getAttribute('data-filename')!
        const preview = description.attachedFiles[fileId].preview
        return {
          container,
          fileId,
          fileName,
          ...(preview ? { preview } : {})
        }
      })
    )
  }, [description.attachedFiles])

  return (
    <>
      <div
        ref={containerRef}
        className={className ?? styles.description}
        dangerouslySetInnerHTML={{
          __html: cleanupDescriptionHtml(
            description.descriptionText,
            description.attachedFiles,
            clearBlockquote
          )
        }}
      />
      {fileContainerInfos.map(info => (
        <FileLink key={info.fileId} info={info} />
      ))}
    </>
  )
}
