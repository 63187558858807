import styles from './CommentSkeleton.module.css'

function Comment() {
  return (
    <div className={styles.container}>
      <div className={styles.avatar} />
      <div className={styles.response}>
        <div className={styles.date} />
        <div className={styles.text} />
      </div>
    </div>
  )
}

export default function CommentSkeleton() {
  return (
    <div style={{ marginTop: 40 }}>
      {Array(5)
        .fill(null)
        .map((_, idx) => (
          <Comment key={idx} />
        ))}
    </div>
  )
}
