export const DateValidators = {
  notEmpty(date: Date | undefined) {
    if (!date) {
      return 'Дата не должна быть пустой'
    }
  },
  notPast(date: Date | undefined) {
    if (date && date < new Date()) {
      return 'Дата и время должны быть актуальными'
    }
  },
  before(after: Date | undefined, message: string) {
    return function (before: Date | undefined) {
      if (after && before && before > after) {
        return message
      }
    }
  }
}

type DateValidator = (datetime: Date | undefined) => string | undefined

function validate<T>(
  value: T,
  validators: ((value: T) => string | undefined)[]
) {
  for (const validator of validators) {
    const message = validator(value)
    if (message) return message
  }
}

export function validateDate(
  date: Date | undefined,
  validators: DateValidator[],
  shouldValidate = true
) {
  if (shouldValidate) {
    return validate(date, validators)
  }
}

export const StringValidators = {
  notEmpty(str: string) {
    if (!/[^\s]/.test(str)) {
      return 'Не должно быть пустым'
    }
  },
  maxLength(max: number) {
    return function (str: string) {
      if (str.length > max) {
        return `Не должно быть длиннее ${max} символов`
      }
    }
  },
  isNumber(str: string) {
    if (!/^-?\d+\.?\d*$/.test(str)) {
      return 'Должно быть числом'
    }
  },
  isInteger(str: string) {
    if (!Number.isInteger(+str)) {
      return 'Должно быть целым'
    }
  },
  isNotNegative(str: string) {
    if (+str < 0) {
      return 'Не должно быть отрицательным'
    }
  },
  isPositive(str: string) {
    if (+str < 1) {
      return 'Должно быть больше 0'
    }
  },
  maxValue(max: number) {
    return function (str: string) {
      if (+str > max) {
        return `Не должно быть больше ${max}`
      }
    }
  }
}

type StringValidator = (str: string) => string | undefined

export function validateString(
  str: string,
  validators: StringValidator[],
  shouldValidate = true
) {
  if (shouldValidate) {
    return validate(str, validators)
  }
}

export function isAllValid(
  validationMessages: Record<string, string | undefined>
) {
  const messages = { ...validationMessages }
  Object.keys(messages).forEach(
    key => messages[key] === undefined && delete messages[key]
  )
  return Object.keys(messages).length === 0
}
