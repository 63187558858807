import React from 'react'
import Button from '../../components/base/Button'
import DateTime from '../../components/base/DateTime'
import Editor from '../../components/base/Editor'
import FileInput from '../../components/base/FileInput'
import { validateEditorState } from '../../components/base/HtmlEditor'
import { MultiSearch } from '../../components/base/Search/'
import Select from '../../components/base/Select'
import TextField from '../../components/base/TextField'
import { getTimeStampInSecs } from '../../shared/dateUtils'
import {
  DescriptionType,
  Priority,
  SubFormProps,
  VizrtTask
} from '../../shared/models'
import {
  DateValidators,
  StringValidators,
  validateDate,
  validateString
} from '../../shared/validators'
import {
  getAuditorOptions,
  getFormFields,
  getPriorityOptions,
  initAuditors,
  initDatetime,
  initPriority
} from './formUtils'
import useDescription from './useDescription'
import { useForm } from './useForm'

interface Values {
  taskTitle: string
  tractDatetime?: Date
  releaseDatetime?: Date
  whereIssued: string
  selectedAuditors: { id: number; value: string }[]
  priority: Priority
  description: string
  descriptionType: DescriptionType
  files: File[]
}

function initValues(props: SubFormProps<VizrtTask>): Values {
  const { taskToRepeat, restoreData, selectedProgram } = props
  const formFields = props.selectedGraphic.form_fields
  return {
    taskTitle: taskToRepeat?.taskTitle ?? restoreData?.taskTitle ?? '',
    tractDatetime: initDatetime(
      taskToRepeat?.tractDatetime,
      restoreData?.tractDatetime
    ),
    releaseDatetime: initDatetime(
      taskToRepeat?.releaseDatetime,
      restoreData?.releaseDatetime
    ),
    whereIssued: taskToRepeat?.whereIssued ?? restoreData?.whereIssued ?? '',
    selectedAuditors: initAuditors(selectedProgram, taskToRepeat, restoreData),
    priority: initPriority(taskToRepeat, restoreData, formFields),
    description: taskToRepeat?.description ?? restoreData?.description ?? '',
    descriptionType:
      taskToRepeat?.descriptionType ?? restoreData?.descriptionType ?? 'html',
    files: []
  }
}

function validateValues(values: Values) {
  return {
    taskTitle: validateString(values.taskTitle, [
      StringValidators.notEmpty,
      StringValidators.maxLength(50)
    ]),
    whereIssued: validateString(values.whereIssued, [
      StringValidators.notEmpty,
      StringValidators.maxLength(50)
    ]),
    description: validateEditorState(values.description),
    tractDatetime: validateDate(values.tractDatetime, [
      DateValidators.notEmpty,
      DateValidators.notPast,
      DateValidators.before(
        values.releaseDatetime,
        'Не должно быть позже даты сдачи'
      )
    ]),
    releaseDatetime: validateDate(values.releaseDatetime, [
      DateValidators.notEmpty,
      DateValidators.notPast
    ])
  }
}

function prepareValues(values: Values, props: SubFormProps<VizrtTask>) {
  const formFields = getFormFields(props)
  const data: VizrtTask = {
    formType: 'vizrt-develop',
    projectId: props.selectedProgram.id.toString(),
    graphicId: props.selectedGraphic.id.toString(),
    taskTitle: values.taskTitle,
    whereIssued: values.whereIssued,
    description: values.description,
    descriptionType: values.descriptionType,
    tractDatetime: getTimeStampInSecs(values.tractDatetime!),
    releaseDatetime: getTimeStampInSecs(values.releaseDatetime!),
    auditors: values.selectedAuditors.map(({ id }) => id)
  }
  if (props.formName) data.formName = props.formName
  if (props.additionalDescr) data.additionalDescr = props.additionalDescr
  if ('priority' in formFields) data.priority = values.priority
  if (props.repeatTaskId) data.repeatTaskId = props.repeatTaskId

  return data
}

export default function VizrtForm(props: SubFormProps<VizrtTask>) {
  const {
    values,
    setValue,
    setMultipleValues,
    errors,
    isValid,
    isLoading,
    handleSubmit
  } = useForm(
    () => initValues(props),
    validateValues,
    values => props.onSubmit(prepareValues(values, props), values.files),
    props.selectedProgram.id.toString(),
    props.selectedGraphic.id.toString(),
    props.graphicsKindId
  )

  const descriptionProps = useDescription(values, setValue, setMultipleValues)

  const formFields = getFormFields(props)
  const auditorOptions = getAuditorOptions(props.selectedProgram)
  const priorityOptions = getPriorityOptions(formFields)

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        type='text'
        value={values.taskTitle}
        name='taskTitle'
        label='Название заявки'
        placeholder='Новая шапка для ПИС'
        onChange={event => setValue('taskTitle', event.target.value)}
        required
        isValid={isValid('taskTitle')}
        validationMessage={errors.taskTitle}
      />
      <DateTime
        label='Планируемое время тракта'
        name='tract'
        value={values.tractDatetime}
        setValue={value => setValue('tractDatetime', value)}
        endDate={values.releaseDatetime}
        required
        isValid={isValid('tractDatetime')}
        validationMessage={errors.tractDatetime}
      />
      <DateTime
        label='Дата сдачи'
        name='release'
        value={values.releaseDatetime}
        setValue={value => setValue('releaseDatetime', value)}
        required
        isValid={isValid('releaseDatetime')}
        validationMessage={errors.releaseDatetime}
      />
      <TextField
        type='text'
        value={values.whereIssued}
        name='whereIssued'
        label='Где будет выдаваться'
        placeholder='АСБ, АПБ'
        hint='АСБ – основные титры, шторки и отбивки, доступна запись графики.
        АПБ – основной интерфейс канала, окна, графика со сдвигом и сжатием проходного сигнала, вторичные события.
        Графика может выдаваться во время записных блоков'
        onChange={event => setValue('whereIssued', event.target.value)}
        required
        isValid={isValid('whereIssued')}
        validationMessage={errors.whereIssued}
      />
      <Editor
        {...descriptionProps}
        label='Описание'
        placeholder='Не забудьте указать все необходимое, иначе разработка будет длиться дольше'
        required
        isValid={isValid('description')}
        validationMessage={errors.description}
      />
      {'priority' in formFields && (
        <Select
          value={values.priority}
          name='priority'
          label='Приоритет'
          onChange={event => setValue('priority', +event.target.value)}
          options={priorityOptions}
        />
      )}
      {!!auditorOptions.length && (
        <MultiSearch
          label=' '
          options={auditorOptions}
          selected={values.selectedAuditors}
          updateSelected={value => setValue('selectedAuditors', value)}
          placeholder='Добавить наблюдателей за заказом'
        />
      )}
      <FileInput
        files={values.files}
        handleAcceptedFiles={value => setValue('files', value)}
        showAlert={props.setAlertMessage}
      />
      <Button
        name='Отправить'
        type='submit'
        status={isLoading ? 'loading' : 'enabled'}
      />
    </form>
  )
}
