import React from 'react'
import arrowSort from '../../../icons/arrow_sort.svg'
import arrowSortBlue from '../../../icons/arrow_sort_blue.svg'
import { Order, Sort } from '../../../shared/models'
import styles from './TaskTable.module.css'

interface ColumnButtonProps {
  rowId: string
  rowName: string
  currentSortId: string
  currentOrder: Order
  updateOrder: (order: Order) => void
  updateSort: (sort: Sort) => void
  sortDisabled?: boolean
}

export function ColumnButton({
  rowId,
  rowName,
  currentSortId,
  currentOrder,
  updateOrder,
  updateSort,
  sortDisabled
}: ColumnButtonProps) {
  const isCurrent = currentSortId === rowId

  const handleClick = () => {
    if (isCurrent) {
      updateOrder(currentOrder === 'asc' ? 'desc' : 'asc')
    } else {
      updateSort(rowId as Sort)
    }
  }

  return (
    <button
      className={isCurrent ? styles.currentSort : styles.sortButton}
      onClick={handleClick}
      disabled={sortDisabled}
    >
      {rowName}
      {!sortDisabled && (
        <img
          style={{
            marginLeft: 5,
            transform: `rotate(${currentOrder === 'asc' ? 180 : 0}deg)`
          }}
          src={isCurrent ? arrowSortBlue : arrowSort}
          alt='Sort arrow'
        />
      )}
    </button>
  )
}
