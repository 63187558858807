import classNames from 'classnames'
import React, { useRef, useState } from 'react'
import { isMobileDevice } from '../../../shared/utils'
import baseStyles from '../Base.module.css'
import InputContainer from '../BaseInput/InputContainer'
import Dropdown from './Dropdown'
import styles from './Search.module.css'
import { BaseSearchProps, Option } from './types'
import { useAvailableOptions } from './useAvailableOptions'

interface MultiSearchProps extends BaseSearchProps {
  selected: Option[]
  updateSelected: (selected: Option[]) => void
}

function MultiSearch(
  props: MultiSearchProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const { options, selected, updateSelected, placeholder, ...baseProps } = props
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const [query, setQuery] = useState('')

  const handleContainerClick = () => {
    inputRef.current.focus()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
  }

  const handleInputFocus = () => {
    if (!dropdownVisible) {
      setDropdownVisible(true)
      if (isMobileDevice()) {
        setTimeout(() => {
          containerRef.current.scrollIntoView({ block: 'start' })
        }, 100)
      }
    }
  }

  const handleInputBlur = (event: React.FocusEvent) => {
    const { relatedTarget } = event
    const container = containerRef.current

    if (relatedTarget && (relatedTarget as Node).parentElement === container) {
      inputRef.current.focus()
    } else {
      setDropdownVisible(false)
    }
  }

  const handleInputKeydown = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      setDropdownVisible(false)
      inputRef.current.blur()
    }
  }

  const addToSelected = (option: Option) => {
    updateSelected([...selected, option])
    setQuery('')
  }

  const deleteFromSelected = (option: Option) => {
    const index = selected.findIndex(selected => selected.id === option.id)
    const selectedUpdated = selected.slice()
    selectedUpdated.splice(index, 1)
    updateSelected(selectedUpdated)
  }

  const { availableOptions, isLoadingOptions } = useAvailableOptions(
    query,
    options,
    selected
  )

  const containerRef = useRef<HTMLDivElement>(null!)
  const inputRef = useRef<HTMLInputElement>(null!)

  return (
    <InputContainer {...baseProps} forwardedRef={ref}>
      <div
        tabIndex={-1}
        ref={containerRef}
        className={classNames(baseStyles.controlInput, styles.search)}
        onClick={handleContainerClick}
      >
        {selected.map(item => (
          <Chip
            name={item.value}
            key={item.id}
            handleDelete={event => {
              deleteFromSelected(item)
              if (!dropdownVisible) event.stopPropagation()
            }}
          />
        ))}

        <input
          value={query}
          ref={inputRef}
          placeholder={placeholder}
          id={props.id}
          className={classNames(
            baseStyles.controlInput,
            styles.queryInputMulti
          )}
          onChange={handleChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onKeyDown={handleInputKeydown}
        />

        {dropdownVisible && (
          <Dropdown
            options={availableOptions}
            isLoadingOptions={isLoadingOptions}
            handleClick={addToSelected}
          />
        )}
      </div>
    </InputContainer>
  )
}

function Chip(props: {
  name: string
  handleDelete: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
}) {
  return (
    <div tabIndex={-1} className={styles.selected}>
      <div className={styles.selectedText}>{props.name}</div>

      <button
        type='button'
        tabIndex={-1}
        className={styles.delete}
        onClick={props.handleDelete}
      />
    </div>
  )
}

export default React.forwardRef(MultiSearch)
