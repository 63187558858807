import * as Sentry from '@sentry/browser'
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
import {
  ClientProject,
  Comment,
  Department,
  FullTemplate,
  GetTasksOptions,
  GraphicGroup,
  IFormData,
  IProject,
  Notification,
  ParentTask,
  Priority,
  SearchTaskResult,
  Task,
  TaskData,
  TaskFilters,
  TaskInfo,
  TasksCounters,
  TasksResult,
  TaskStatus,
  Template,
  UserInfo
} from './models'

function setUserContext(username: string, id: string) {
  Sentry.configureScope(scope => {
    scope.setUser({ username, id })
  })
}

export function logout() {
  clearTokens()
  setUserContext('', '')
}

function getApiUrl(url: string) {
  return process.env.REACT_APP_API_SERVER + url
}

type ValidationMessage = { param: string; description: string }

interface ErrorResponse {
  status: 'error'
  message: string
  errors?: ValidationMessage[]
}

export function getProjects() {
  return makeApiRequest<IProject[]>(getApiUrl('/customer/projects'))
}

export function getFormData({
  projectId,
  graphicId
}: {
  projectId: string
  graphicId: string
}) {
  return makeApiRequest<IFormData>(
    getApiUrl(`/customer/projects/${projectId}/graphics/${graphicId}`)
  )
}

export async function createClientTask(dataToSend: Task, files?: File[]) {
  const formData = new window.FormData()
  Object.entries(dataToSend).forEach(([key, value]) => {
    if (key === 'auditors') {
      value.forEach((auditorId: number) =>
        formData.append('auditors[]', auditorId.toString())
      )
    } else {
      formData.append(key, value)
    }
  })
  if (files) files.forEach(file => formData.append('taskFiles[]', file))
  return makeApiRequest<TaskInfo>(
    getApiUrl('/customer/tasks'),
    'POST',
    formData
  )
}

let abortController = new AbortController()

function getRequestObject(
  url: string,
  token: string,
  method?: string,
  body?: FormData | Record<string, any>
) {
  const options = {
    method: method ?? 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      ...(!(body instanceof FormData) && { 'Content-Type': 'application/json' })
    },
    body: body instanceof FormData ? body : JSON.stringify(body),
    signal: abortController.signal
  }
  return new Request(url, options)
}

async function getFreshToken() {
  // try to refresh token
  try {
    const token = await refreshToken()
    return token
  } catch (error) {
    clearTokens()
  }
}

async function parseError(res: Response) {
  let message = ''
  let errors: ValidationMessage[] | undefined = undefined

  if (res.status === 500) {
    message = 'Ошибка сервера'
  } else {
    const errInfo = (await res.json()) as ErrorResponse
    message = errInfo.message
    if (errInfo.errors) {
      errors = errInfo.errors
      errInfo.errors.forEach(({ description }) => {
        message += `\n${description}`
      })
    }
  }
  return new ApiError(message, errors)
}

class AuthError extends Error {
  constructor() {
    super('Ошибка авторизации')
    this.name = 'AuthError'
  }
}

class ApiError extends Error {
  errors?: ValidationMessage[]
  constructor(message: string, errors?: ValidationMessage[]) {
    super(message)
    this.name = 'ApiError'
    this.errors = errors
  }
}

async function parseJsonResponse<T>(response: Response) {
  if (response.ok) {
    return (await response.json()) as T
  } else {
    throw await parseError(response)
  }
}

async function parseEmptyResponse(response: Response) {
  if (!response.ok) {
    throw await parseError(response)
  }
}

async function parseFileResponse(response: Response) {
  if (response.ok) {
    const blob = await response.blob()
    return blob
  } else {
    throw await parseError(response)
  }
}

async function makeApiRequest<T>(
  url: string,
  method?: string,
  body?: FormData | Record<string, any>,
  parseResponse: (resp: Response) => Promise<T> = parseJsonResponse
) {
  let token = localStorage.getItem('token')

  const makeRequest = (token: string) =>
    fetch(getRequestObject(url, token, method, body))

  try {
    if (!token) {
      const newToken = await getFreshToken()
      if (newToken) token = newToken
      else throw new AuthError()
    }

    const response = await makeRequest(token!)
    if (response.status !== 401) return parseResponse(response)

    const newToken = await getFreshToken()
    if (!newToken) throw new AuthError()

    // try again
    return parseResponse(await makeRequest(newToken))
  } catch (error) {
    if (!['AbortError', 'AuthError'].includes((error as any).name)) {
      Sentry.captureException(error)
    }
    throw error
  }
}

export function getCurrentUserInfo() {
  return makeApiRequest<UserInfo>(getApiUrl('/user')).then(userInfo => {
    setUserContext(userInfo.formatted_name, userInfo.id.toString())
    return userInfo
  })
}

export function getUserFilters() {
  return makeApiRequest<TaskFilters>(getApiUrl('/filters'))
}

export function getTasks(options: GetTasksOptions) {
  const tasksUrl = Object.entries(options).reduce((acc, entry, idx) => {
    const [paramName, paramValue] = entry
    if (paramValue === undefined) return acc
    return acc + `${idx === 0 ? '?' : '&'}${paramName}=${paramValue}`
  }, getApiUrl('/tasks'))

  return makeApiRequest<TasksResult>(tasksUrl)
}

export function searchTasks(query: string) {
  const url = `/tasks/search?query=${query}`
  return makeApiRequest<SearchTaskResult[]>(getApiUrl(url))
}

export function getTask(taskId: number) {
  const taskUrl = `/tasks/${taskId}`
  return makeApiRequest<Required<TaskData>>(getApiUrl(taskUrl))
}

export async function getComments(taskId: number) {
  const commentsUrl = `/tasks/${taskId}/comments`
  return makeApiRequest<Comment[]>(getApiUrl(commentsUrl))
}

export async function getComment(taskId: number, commentId: number) {
  const commentsUrl = `/tasks/${taskId}/comments/${commentId}`
  return makeApiRequest<Comment>(getApiUrl(commentsUrl))
}

export async function addComment(
  commentText: string,
  textType: 'text' | 'html',
  taskId: number,
  files: File[] = [],
  internal: boolean
) {
  const commentUrl = `/tasks/${taskId}/comments`
  const formData = new window.FormData()
  formData.append('description', commentText)
  formData.append('descriptionType', textType)
  if (internal) {
    formData.append('internal', 'true')
  }
  files.forEach(file => formData.append('attachedFiles[]', file))
  return makeApiRequest<Comment>(getApiUrl(commentUrl), 'POST', formData)
}

interface TokenInfo {
  access_token: string
  token_type: string
  expires_in: number
  scope: string
  refresh_token: string
}

async function getToken(params: Record<string, string>) {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic b2F1dGgyX2VtZzo1YmZmY2FmYQ==`
    },
    body: getUrlEncodedString(params)
  }
  const response = await fetch(process.env.REACT_APP_TOKEN_URL!, options)
  if (response.ok) {
    const tokenInfo = (await response.json()) as TokenInfo
    localStorage.setItem('token', tokenInfo.access_token)
    localStorage.setItem('refreshToken', tokenInfo.refresh_token)
    return tokenInfo.access_token
  } else {
    throw await parseError(response)
  }
}

export function getNewToken(username: string, password: string) {
  const params = {
    username,
    password,
    grant_type: 'password',
    app_id: 'emg_bitrix_light_app'
  }
  return getToken(params)
}

async function refreshToken() {
  const refreshToken = localStorage.getItem('refreshToken')
  if (refreshToken) {
    const params = {
      grant_type: 'refresh_token',
      refresh_token: refreshToken
    }
    return getToken(params)
  } else {
    throw new AuthError()
  }
}

function clearTokens() {
  localStorage.removeItem('token')
  localStorage.removeItem('refreshToken')
}

function getUrlEncodedString(params: Record<string, string>) {
  return Object.entries(params)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join('&')
}

export async function markCommentsAsRead(taskId: number) {
  const url = `/tasks/${taskId}/comments/read`
  return makeApiRequest(getApiUrl(url), 'PUT', undefined, parseEmptyResponse)
}

export async function changeTaskAuditors(taskId: number, auditors: number[]) {
  const url = `/tasks/${taskId}`
  return makeApiRequest(
    getApiUrl(url),
    'PATCH',
    { auditors },
    parseEmptyResponse
  )
}

export async function changeResponsibleUser(
  taskId: number,
  responsibleId?: number
) {
  const url = `/tasks/${taskId}`
  return makeApiRequest(
    getApiUrl(url),
    'PATCH',
    { responsibleId: responsibleId ?? 0 },
    parseEmptyResponse
  )
}

export async function changeTemplate(taskId: number, templateId?: number) {
  const url = `/tasks/${taskId}`
  return makeApiRequest(
    getApiUrl(url),
    'PATCH',
    { templateId: templateId ?? 0 },
    parseEmptyResponse
  )
}

export async function gotoOldVersionPage(url: string) {
  const token = await getFreshToken()
  if (token) {
    const resp = await fetch(url, {
      method: 'POST',
      body: getUrlEncodedString({
        access_token: token,
        app_id: 'emg_bitrix_light_app'
      }),
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    if (resp.ok) {
      window.location.assign(url)
    }
  }
}

export async function getAuditorsList(projectId?: number) {
  let url = '/directory/auditorUsers'
  if (projectId) url += `?projectId=${projectId}`
  return makeApiRequest<UserInfo[]>(getApiUrl(url))
}

export async function getResponsibleUsersList() {
  return makeApiRequest<UserInfo[]>(getApiUrl('/directory/responsibleUsers'))
}

export async function getTemplates(name?: string) {
  let url = '/directory/templates'
  if (name) url += `?findByName=${name}`
  return makeApiRequest<Template[]>(getApiUrl(url))
}

export async function getTemplate(id: number) {
  return makeApiRequest<FullTemplate>(getApiUrl(`/directory/templates/${id}`))
}

export async function getResponsibleUsers() {
  return makeApiRequest<UserInfo[]>(getApiUrl('/directory/responsibleUsers'))
}

export async function getClientProjects() {
  return makeApiRequest<ClientProject[]>(getApiUrl('/directory/clientProjects'))
}

export async function getParentTasks(name?: string) {
  let url = '/directory/parentTasks'
  if (name) url += `?findByName=${name}`
  return makeApiRequest<ParentTask[]>(getApiUrl(url))
}

export async function getDepartments() {
  return makeApiRequest<Department[]>(getApiUrl('/directory/departments'))
}

export async function getGraphicGroups() {
  return makeApiRequest<GraphicGroup[]>(
    getApiUrl('/directory/graphicGroupsWithTypes')
  ).then(groups => {
    return groups.reduce<Record<number, GraphicGroup>>((acc, group) => {
      acc[group.id] = group
      return acc
    }, {})
  })
}

type CreateTaskParams = {
  title: string
  templateId?: number
  responsibleId?: number
  description?: string
  descriptionType?: 'text' | 'html'
  taskFiles: File[]
  clientProjectId?: number
  graphicGroupId?: number
  graphicTypeId?: number
  parentTaskId?: number
  useTiming?: boolean
  timing?: number
  useExtraTiming?: boolean
  extraTiming?: number
  deadline?: number
  departments?: number[]
  auditors?: number[]
  usePriority?: boolean
  priority?: Priority
}

export async function createTask(params: CreateTaskParams) {
  const formData = new window.FormData()
  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined && typeof value !== 'object') {
      formData.append(key, value.toString())
    }
  })
  params.departments?.forEach(departmentId =>
    formData.append('departments[]', departmentId.toString())
  )
  params.auditors?.forEach(auditorId =>
    formData.append('auditors[]', auditorId.toString())
  )
  params.taskFiles.forEach(file => formData.append('taskFiles[]', file))

  return makeApiRequest<TaskInfo>(getApiUrl('/tasks'), 'POST', formData)
}

type TaskStatusUpdateResultBase = {
  message?: string
  fail_reasons?: { name: string; message: string }[]
}

type TaskStatusSuccess = TaskStatusUpdateResultBase & {
  status: 'success'
  result: TaskStatus
}

export type TaskWorkItem = {
  id: number
  name: string
  value: number
}

type TaskStatusConfirmWorks = TaskStatusUpdateResultBase & {
  status: 'confirm_works'
  result: TaskWorkItem[]
}

type LinkFields = {
  url: string
  title?: string
}

type TaskStatusRedirect = TaskStatusUpdateResultBase & {
  status: 'redirect_to_bxpro'
  result: LinkFields
}

type TaskStatusUpdateResult =
  | TaskStatusSuccess
  | TaskStatusConfirmWorks
  | TaskStatusRedirect

export async function progressTask(taskId: number) {
  const url = `/tasks/${taskId}/progress`
  return makeApiRequest<TaskStatusUpdateResult>(getApiUrl(url), 'PUT')
}

export async function pauseTask(taskId: number) {
  const url = `/tasks/${taskId}/pause`
  return makeApiRequest<TaskStatusUpdateResult>(getApiUrl(url), 'PUT')
}

export async function cancelTask(taskId: number) {
  const url = `/tasks/${taskId}/cancel`
  return makeApiRequest<TaskStatusUpdateResult>(getApiUrl(url), 'PUT')
}

export async function finishTask(taskId: number, withDefaultWorks = false) {
  const url = `/tasks/${taskId}/finish`
  const body = withDefaultWorks ? { with_save_works: true } : undefined
  return makeApiRequest<TaskStatusUpdateResult>(getApiUrl(url), 'PUT', body)
}

type HasDefaultWork = { status: 'success'; result: TaskWorkItem[] }
type NoDefaultWork = { status: 'redirect_to_bxpro'; result: LinkFields }

type TaskDefaultWorkResult = HasDefaultWork | NoDefaultWork

export async function getTaskWorks(taskId: number, workType = 'default_works') {
  const url = `/tasks/${taskId}/works?type=${workType}`
  return makeApiRequest<TaskDefaultWorkResult>(getApiUrl(url))
}

export async function saveTaskWorks(taskId: number) {
  const url = `/tasks/${taskId}/works`
  return makeApiRequest(
    getApiUrl(url),
    'POST',
    { type: 'default_works' },
    parseEmptyResponse
  )
}

export async function getFile(fileId: number) {
  const url = `/file/${fileId}?action=show`
  return makeApiRequest(getApiUrl(url), 'GET', undefined, parseFileResponse)
}

export function pullChannel() {
  return makeApiRequest<{ PATH_WS: string; CHANNEL_ID: string }>(
    getApiUrl('/pull/channel')
  ).then(channelData => ({
    channelId: channelData.CHANNEL_ID,
    url: channelData.PATH_WS
  }))
}

export function getMissedMessages(channelId: string) {
  return makeApiRequest<{ MESSAGE: any; ERROR: string }>(
    getApiUrl('/pull/channel'),
    'PUT',
    {
      CHANNEL_ID: channelId,
      LAST_ID: 0
    }
  )
}

export type ChannelTags = 'comments' | 'task_update' | 'user_counter'

export function pullWatchChannel(tags: ChannelTags[], taskId?: number[]) {
  return makeApiRequest(getApiUrl('/pull/watch'), 'POST', {
    taskId,
    tags
  })
}

export function pullCommentActivity(taskId: number) {
  return makeApiRequest(
    getApiUrl('/pull/comment_writing_activity/' + taskId),
    'PUT',
    undefined,
    parseEmptyResponse
  )
}

export async function getUnreadNotificationCount() {
  return makeApiRequest<{ count: number }>(getApiUrl('/notify/counter'))
}

export async function getNotifications(pageNum: number) {
  return makeApiRequest<Notification[]>(
    getApiUrl(`/notify/list?pageNum=${pageNum}`)
  )
}

export async function getUnreadNotifications() {
  return makeApiRequest<Notification[]>(getApiUrl(`/notify/unread/list`))
}

export async function markNotificationAsRead(id: string) {
  return makeApiRequest<boolean>(getApiUrl(`/notify/read/${id}`), 'put')
}

export async function markAllNotificationsAsRead(id: string) {
  return makeApiRequest<boolean>(getApiUrl(`/notify/read/${id}/all`), 'put')
}

export async function getTasksCounters() {
  return makeApiRequest<TasksCounters>(getApiUrl(`/tasks/counters`))
}
