import LogRocket from 'logrocket'
import React, { useCallback, useEffect, useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter as Router } from 'react-router-dom'
import Spinner from '../components/base/Spinner'
import AuthForm from '../pages/Login/AuthForm'
import { getCurrentUserInfo, logout } from '../shared/apiService'
import { UserInfo } from '../shared/models'
import styles from './App.module.css'
import { AuthContext } from './authContext'
import AuthenticatedRoutes from './AuthenticatedRoutes'
import Header from './Header'
import './style.css'

const QUERY_RETRY_COUNT = 3
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (count, err) => {
        if (
          err instanceof Error &&
          (err.name === 'AuthError' || err.name === 'ApiError')
        )
          return false
        return count < QUERY_RETRY_COUNT
      }
    }
  }
})

type Status = 'loading' | 'authenticated' | 'unauthenticated'

function giveUserToLogRocket(userName: string, email: string, id: string) {
  LogRocket.identify(id, {
    name: userName,
    email: email,
    id
  })
}

export default function App() {
  const [status, setStatus] = useState<Status>('loading')
  const [userId, setUserId] = useState('')
  const [userName, setUserName] = useState('')
  const [userType, setUserType] = useState('')
  const [catalogUrl, setCatalogUrl] = useState('')
  const [logo, setLogo] = useState('none')
  const [alertMessage, setAlertMessage] = useState('')
  const authenticate = (userInfo?: UserInfo) => {
    if (userInfo) {
      setStatus('authenticated')
      setUserId(userInfo.id.toString())
      setUserName(userInfo.formatted_name)
      setUserType(userInfo.user_type)
      giveUserToLogRocket(
        userInfo.formatted_name,
        userInfo.emails![0],
        userInfo.id.toString()
      )
      if (userInfo.catalogUrl) setCatalogUrl(userInfo.catalogUrl)
      if (userInfo.view_form_type) {
        switch (userInfo.view_form_type) {
          case 'bx-light_need_map':
            setLogo('map')
            break
          case 'bx-light_with_logo':
            setLogo('emg')
            break
          default:
            setLogo('none')
            break
        }
      }
    } else {
      setStatus('unauthenticated')
    }
  }

  useEffect(() => {
    getCurrentUserInfo()
      .then(userInfo => {
        authenticate(userInfo)
      })
      .catch(() => {
        authenticate()
      })
  }, [])

  const handleLogout = useCallback(() => {
    setStatus('loading')
    sessionStorage.clear()
    logout()
    setStatus('unauthenticated')
    setUserId('')
    setUserName('')
    setUserType('')
    setCatalogUrl('')
    setLogo('none')
  }, [])

  const handleError = useCallback(
    (err: Error) => {
      if (err.name === 'AbortError') {
        return
      } else if (err.name === 'AuthError') {
        handleLogout()
        setAlertMessage(err.message)
      } else {
        setAlertMessage(err.message)
      }
    },
    [handleLogout]
  )

  const setAlert = useCallback(
    (message: string) => setAlertMessage(message),
    []
  )

  const elementToPresent = (() => {
    switch (status) {
      case 'loading':
        return <Spinner className={styles.spinner} />
      case 'unauthenticated':
        return <AuthForm authenticate={authenticate} />
      case 'authenticated':
        return <AuthenticatedRoutes />
    }
  })()

  return (
    <QueryClientProvider client={queryClient}>
      <AuthContext.Provider
        value={{
          authenticated: status === 'authenticated',
          userId,
          userName,
          userType,
          catalogUrl,
          logout: handleLogout,
          setAlert,
          handleError,
          alertMessage,
          setAlertMessage,
          logo
        }}
      >
        <Router basename={process.env.REACT_APP_BASE_URL}>
          <section>
            <Header />
            <main
              className={
                alertMessage
                  ? styles.mainContainerAlertOn
                  : styles.mainContainer
              }
            >
              {elementToPresent}
            </main>
          </section>
        </Router>
        <ReactQueryDevtools initialIsOpen={false} />
      </AuthContext.Provider>
    </QueryClientProvider>
  )
}
