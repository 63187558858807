import React, { useContext, useState } from 'react'
import { AuthContext } from '../../app/authContext'
import Button from '../../components/base/Button'
import Details from '../../components/base/Details'
import { formatDateRelative, getDateFrom } from '../../shared/dateUtils'
import { TaskData, UserInfo } from '../../shared/models'
import { priorities } from '../../shared/utils'
import PriorityInfo from '../Tasks/List/PriorityInfo'
import AuditorsBlock from './AuditorsBlock'
import ResponsibleUserBlock from './ResponsibleUserBlock'
import style from './TaskPage.module.css'
import TemplateBlock from './TemplateBlock'

function InfoRow({ title, value }: { title: string; value: React.ReactNode }) {
  return (
    <div className={style.rowDetails}>
      <div className={style.infoTitle}>{title}:</div>
      <div className={style.infoDescription}>{value}</div>
    </div>
  )
}

export default function DetailsBlock({
  task,
  showWorkModal,
  auditorsLoading,
  availableAuditors,
  responsibleUsers
}: {
  task: Required<TaskData>
  showWorkModal: () => void
  auditorsLoading: boolean
  availableAuditors: UserInfo[]
  responsibleUsers: UserInfo[]
}) {
  const [infoVisible, setInfoVisible] = useState(false)
  const { userType } = useContext(AuthContext)

  const priority = (
    <span>
      Приоритет: <b>{task.priority ? priorities[task.priority] : '–'}</b>
    </span>
  )
  const responsible = (
    <span>
      Исполнитель:{' '}
      <b>{task.responsibleUser ? task.responsibleUser.formatted_name : '–'}</b>
    </span>
  )
  const title = (
    <span>
      Информация
      {!infoVisible && userType !== 'client' && (
        <span className={style.taskDetails}>
          ({priority}, {responsible})
        </span>
      )}
    </span>
  )

  return (
    <Details
      title={title}
      detailsVisible={infoVisible}
      setVisible={setInfoVisible}
    >
      {typeof task.priority === 'number' && (
        <InfoRow
          title='Приоритет'
          value={<PriorityInfo priority={task.priority} />}
        />
      )}
      {task.clientUser && (
        <InfoRow title='Заказчик' value={task.clientUser.formatted_name} />
      )}
      {task.createdBy && (
        <InfoRow
          title='Зарегистрировал'
          value={task.createdBy.formatted_name}
        />
      )}
      {userType !== 'client' && task.responsibleUser && (
        <InfoRow
          title='Исполнитель'
          value={task.responsibleUser.formatted_name}
        />
      )}
      {task.deadline && (
        <InfoRow
          title='Крайний срок'
          value={formatDateRelative(getDateFrom(task.deadline))}
        />
      )}
      <InfoRow title='Хронометраж' value={task.timing} />

      {task.canChangeTemplate && <TemplateBlock task={task} />}
      {task.canChangeResponsible && (
        <ResponsibleUserBlock task={task} responsibleUsers={responsibleUsers} />
      )}
      {task.canChangeAuditors && (
        <AuditorsBlock
          task={task}
          auditorsLoading={auditorsLoading}
          availableAuditors={availableAuditors}
        />
      )}

      {task.canSaveEmployeeWorks && (
        <Button name='Зарегистрировать работы' onClick={showWorkModal} />
      )}
    </Details>
  )
}
