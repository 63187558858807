import classNames from 'classnames'
import React, { useCallback, useRef } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import styles from './Base.module.css'
import './HtmlEditor.css'
import editorStyles from './HtmlEditor.module.css'

const CustomToolbar = () => (
  <div id='toolbar' className={editorStyles.toolbar}>
    <button className='ql-bold' />
    <button className='ql-italic' />
    <button className='ql-underline' />

    <button className='ql-script' value='sub' />
    <button className='ql-script' value='super' />

    <select className='ql-color' />
    <button className='ql-link' />

    <button className='ql-list' value='ordered' />
    <button className='ql-list' value='bullet' />

    <select className='ql-symbols'>
      <option value='≈' />
      <option value='„' />
      <option value='“' />
      <option value='«' />
      <option value='»' />
      <option value='°C' />
      <option value='₽' />
      <option value='—' />
      <option value='–' />
      <option value='−' />
    </select>

    <button className='ql-clean' />
  </div>
)

const modules = {
  toolbar: {
    container: '#toolbar',
    handlers: {
      symbols: function (this: any, value: string) {
        if (value) {
          const cursorPosition = this.quill.getSelection().index
          this.quill.insertText(cursorPosition, value)
          this.quill.setSelection(cursorPosition + value.length)
        }
      }
    }
  }
}

const formats = [
  'bold',
  'italic',
  'underline',
  'list',
  'bullet',
  'link',
  'color',
  'script'
]

const theme = 'snow'

export function hasText(value: string) {
  return value.length > 0 && value !== '<p><br></p>'
}

export function validateEditorState(value: string) {
  return hasText(value) ? undefined : 'Не должно быть пустым'
}

interface HtmlEditorProps {
  /** props triggering renders */
  setValue: (value: string) => void
  label?: string
  placeholder?: string
  required?: boolean
  isValid?: boolean
  onFocus?: () => void
  disabled?: boolean
  /** props not triggering renders */
  initialValue?: string
  validationMessage?: string
}

type Ref = React.ForwardedRef<HTMLDivElement>

function HtmlEditor(props: HtmlEditorProps, ref: Ref) {
  const editorRef = useRef<ReactQuill>(null!)

  const handleClick = (event: React.MouseEvent) => {
    if (event.target) {
      const tooltip = (event.target as HTMLElement).closest('.ql-tooltip')
      if (tooltip) {
        return event.stopPropagation()
      }
    }
    editorRef.current.focus()
  }

  const { setValue } = props
  const handleChange = useCallback(
    (value: string) => setValue(value),
    [setValue]
  )

  const inputClassName = classNames(styles.controlInput, editorStyles.wrapper, {
    [styles.controlInputInvalid]: props.isValid === false
  })

  return (
    <div className={styles.control} ref={ref}>
      {props.label && (
        <label className={styles.label}>
          {props.label}
          {props.required && <sup className={styles.requiredMark}>*</sup>}
        </label>
      )}
      <div className={styles.inputContainer} onClick={handleClick}>
        <CustomToolbar />
        <ReactQuill
          ref={editorRef}
          theme={theme}
          defaultValue={props.initialValue}
          onChange={handleChange}
          placeholder={props.placeholder}
          className={inputClassName}
          modules={modules}
          formats={formats}
          onFocus={props.onFocus}
          readOnly={props.disabled}
        />
        {props.isValid === false && (
          <div className={styles.validationMessage}>
            {props.validationMessage}
          </div>
        )}
      </div>
    </div>
  )
}

export default React.memo(React.forwardRef(HtmlEditor), areEqual)

type DirtyProps = Exclude<
  keyof HtmlEditorProps,
  'initialValue' | 'validationMessage'
>

function areEqual(prevProps: HtmlEditorProps, nextProps: HtmlEditorProps) {
  const dirtyProps: DirtyProps[] = [
    'setValue',
    'label',
    'placeholder',
    'required',
    'isValid',
    'onFocus',
    'disabled'
  ]
  return dirtyProps.every(key => prevProps[key] === nextProps[key])
}
